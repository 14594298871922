import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import createPalette from '@material-ui/core/styles/createPalette';

const defaultTheme = createMuiTheme({
	elevation: {
		none: 0,
		primary: 1,
		secondary: 2
	},
	typography: {
		useNextVariants: true,
		"fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
		"fontSize": 12,
		"fontWeightLight": 300,
		"fontWeightRegular": 400,
		"fontWeightMedium": 500
	},
	fontFamily: 'Eurostile',
	title: {
		fontSize: 14,
		fontSizeXS: 12
	},
	subtitle: {
		fontSize: 12,
		fontSizeXS: 12
	},
	text: {
		fontSize: 12,
		fontSizeXS: 12
	},
	button: {
		fontSize: 12,
		fontSizeXS: 12
	},
	palette: createPalette({
		common: {
			black: '#000',
			white: '#fff'
		},
		type: 'light',
		primary: {
			light: '#23cde5',
			main: '#0068a3',
			dark: '#005c90',
			contrastText: '#fff'
		},
		secondary: {
			light: '#009EFA',
			main: '#FF0000',
			dark: '#009EFA',
			contrastText: '#fff'
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
			contrastText: '#fff',
		},
		grey: {
			50: '#fafafa',
			100: '#f5f5f5',
			200: '#eeeeee',
			300: '#e0e0e0',
			400: '#bdbdbd',
			500: '#9e9e9e',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
			A100: '#d5d5d5',
			A200: '#aaaaaa',
			A400: '#303030',
			A700: '#616161',
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)',
			Containerider: 'rgba(0, 0, 0, 0.12)'
		},
		background: {
			paper: '#fff',
			drawer: '#303f9f',
			default: '#fafafa'
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.08)',
			hoverOpacity: 0.08,
			selected: 'rgba(0, 0, 0, 0.14)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)'
		},
	}),
	spacing: factor => `${0.25 * factor}rem`,
	appBar: {
		height: 57,
		color: blue,
	},
	drawer: {
		width: 230,
		color: blue,
	},
	raisedButton: {
		primaryColor: grey,
	},
});
export default defaultTheme;
