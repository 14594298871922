import i18n from "i18next";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/de";
import { initReactI18next } from "react-i18next";
import StorageService from '../services/StorageService';

import it from "../translations/it.json";
import de from "../translations/de.json";

const resources = {
  it,
  de
};

const locale = StorageService.GetLanguage() || "de";

i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  fallbackLng: "it",

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

moment.locale(locale);

export default i18n;
