import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";

import CatalogHeaderColumn from "./CatalogHeaderColumn";
import CatalogContentColumn from "./CatalogContentColumn";
import CatalogUnitColumn from "./CatalogUnitColumn";
import CatalogDrawColumn from "./CatalogDrawColumn";
import { formatNpkContent } from "../../utils/NpkUtils";
import ObjectExt from "../../utils/ObjectExt";

const styles = theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  badge: {
    cursor: "pointer"
  },
  expander: {
    cursor: "pointer"
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  progress: {
    margin: theme.spacing(4),
    padding: theme.spacing(4)
  }
});

class CatalogItem extends React.Component {
  static propTypes = {
    section: PropTypes.object,
    textMode: PropTypes.string,
    activeBadge: PropTypes.object,
    classes: PropTypes.object,
    filterByComments: PropTypes.bool,
    onBadgeClick: PropTypes.func,
    onExpandedToggle: PropTypes.func
  };

  static defaultProps = {
    section: {}
  };

  state = {
    filterByComments: false,
    commentNumber: 0
  };

  elementRef = React.createRef();

  onToggle = () => {
    const { section, onExpandedToggle } = this.props;

    if (!onExpandedToggle) return;

    onExpandedToggle({
      id: section.positionID,
      expanded: !section.expanded
    });
  };

  render() {
    const {
      section,
      textMode,
      activeBadge,
      classes,
      userId,
      audit,
      filterByComments
    } = this.props;

    const { expanded } = section;
    const nested = section.subNodes && section.subNodes.length > 0;
    const renderChildren = nested && expanded;
    const sectionContent = formatNpkContent(section, textMode);

    if (
      !ObjectExt.IsNullOrUndefined(filterByComments) &&
      filterByComments &&
      !section.hasComments
    )
      return null;

    return (
      <>
        <TableRow ref={this.elementRef}>
          <CatalogHeaderColumn
            section={section}
            expandable={nested}
            expanded={expanded}
            activeBadge={activeBadge}
            toggleHandler={this.onToggle}
            onBadgeClick={this.props.onBadgeClick}
          />

          <CatalogContentColumn
            content={sectionContent}
            expandable={nested}
            toggleHandler={this.onToggle}
            section={section}
            userId={userId}
            audit={audit}
            onBadgeClick={this.props.onBadgeClick}
          />

          <CatalogUnitColumn
            section={section}
            userId={userId}
            audit={audit}
            onBadgeClick={this.props.onBadgeClick}
          />

          <CatalogDrawColumn
            section={section}
            userId={userId}
            audit={audit}
            onBadgeClick={this.props.onBadgeClick}
          />
        </TableRow>
        {renderChildren ? this.renderChildren() : null}
      </>
    );
  }
  renderChildren() {
    const {
      userId,
      audit,
      section,
      filterByComments,
      textMode,
      activeBadge,
      onBadgeClick,
      onExpandedToggle
    } = this.props;

    const { expanded } = section;
    const nested = section.subNodes && section.subNodes.length > 0;
    const renderChildren = nested && expanded;

    if (!renderChildren) return null;

    return (
      <Fragment>
        {section.subNodes.map((child, i) => {
          return (
            <CatalogItem
              filterByComments={filterByComments}
              key={child.number}
              section={child}
              userId={userId}
              audit={audit}
              activeBadge={activeBadge}
              textMode={textMode}
              onBadgeClick={onBadgeClick}
              onExpandedToggle={onExpandedToggle}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default withTranslation("i18n")(withStyles(styles)(CatalogItem));
