import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import EmailTemplateDataService from "./EmailTemplateDataService";
import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import BaseTable from "../Commons/BaseTable";
import EmailTemplateDetailsPage from "./EmailTemplateDetailsPage";
import {ConfirmDialog, ConfirmDialogService} from '../Commons/ConfirmDialog';
import ToastService from '../../services/ToastService';
import {ConfirmSaveDialog, ConfirmSaveDialogService} from "../Commons/ConfimSaveDialog";

class EmailTemplatePage extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        history: PropTypes.object
    };

    initialState = {
        isLoaded: false,
        selectedRows: [],
        emailTemplates: [],
        selectedRow: {
            id: 0,
            name: '',
            subject: '',
            body: ''
        },
        openDialog: false
    };


    state = this.initialState;


    componentDidMount() {
        EmailTemplateDataService.getAllEmailTemplates(response => {
            this.setState({
                isLoaded: true,
                emailTemplates: response.data.result
            })
        }, error => {
            ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
        })
    }

    // confirmDeleteContent = () =>{
    //   const { t } = this.props;
    //   return `${t(i18n.ConfirmDialogDeleteContent)} ${this.state.selectedRows.length} ${t(i18n.Elements)}?`
    // };

    render() {
        const {t} = this.props;
        const {emailTemplates, selectedRows, selectedRow,isLoaded} = this.state;

        const headers = [
            t(i18n.Name),
            t(i18n.Subject),
        ];

        return (
            <>
                <EmailTemplateDetailsPage
                    onClose={this.onDialogClose}
                    selected={selectedRow}
                    open={this.state.openDialog}
                    onUpdate={this.onDetailsSave}>
                </EmailTemplateDetailsPage>

                <ConfirmSaveDialog
                    title={t(i18n.ConfirmDialogTitle)}/>

                <ConfirmDialog
                    title={t(i18n.ConfirmDialogTitle)}
                    elements={selectedRows.length}
                />

                <div style={{padding: '5px'}}>

                    <BaseTable
                        title={t(i18n.AvailableEmailTemplates)}
                        subTitle={t(i18n.AvailableEmailTemplatesMore)}
                        rowsData={emailTemplates}
                        renderHeaders={headers}
                        renderRow={this.renderRowData}
                        selectedRows={selectedRows}
                        onSelectRow={this.onSelectedRow}
                        onAddRow={this.onCreateButton}
                        onEditRow={this.onEditRow}
                        onDeleteRow={this.onDeleteRow}
                        onSelectAllRows={this.onSelectAllRows}
                        filterProps={this.filterProps}
                        showLoader={!isLoaded}>
                    </BaseTable>
                </div>
            </>
        );
    }

    renderRowData(data) {
        return [
            data.name,
            data.subject
        ]
    };

    filterProps = [
        'name',
        'subject',
    ];


    onCreateButton = () => {
        this.setState({
            selectedRow: this.initialState.selectedRow,
            openDialog: true
        });
    };

    onEditRow = (row) => {
        this.setState({
            selectedRow: row,
            openDialog: true
        });
    };

    onDetailsSave = (selected) => {
        const {id} = selected;

        if (id == 0) {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                EmailTemplateDataService.addEmailTemplate(selected,
                    (response) => {
                        this.setState({emailTemplates: [...this.state.emailTemplates, response.data.result]})
                    }, (error) => {
                        //TODO show toast message to the user
                    });
                this.setState({openDialog: false})
            }
        } else {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                EmailTemplateDataService.editEmailTemplate(selected, (response) => {
                        const data = response.data.result;
                        const newEmailTemplates = this.state.emailTemplates.slice();
                        const index = _.findIndex(newEmailTemplates, (emailTemplate) => {
                            return emailTemplate.id == data.id;
                        });
                        newEmailTemplates[index] = data;
                        this.setState({emailTemplates: newEmailTemplates})
                    },
                    (error) => {
                        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                    });
                this.setState({openDialog: false})
            }
        }
        ConfirmSaveDialogService.onClose = ()=>{
        }
    };

    onDeleteRow = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            ConfirmDialogService.open();
            this.state.selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            this.setState({
                selectedRows: [tableRow]
            }, () => {
                ids.push(tableRow.id);
                ConfirmDialogService.open();
            });
        }

        ConfirmDialogService.onAccept = () => {

            EmailTemplateDataService.deleteEmailTemplate(ids, (response) => {

                if (!response.data.isSuccess) {
                    ToastService.ShowToast(response.data.message);
                    return;
                }

                const newEmailTemplates = this.state.emailTemplates.slice();
                const templatesDeleted = response.data.result;
                _.forEach(templatesDeleted, template => {
                    _.remove(newEmailTemplates, q => q.id === template.id);
                });

                this.setState({
                    emailTemplates: newEmailTemplates,
                    selectedRows: this.initialState.selectedRows,
                });

            }, error => {
                ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
            });
        };

        ConfirmDialogService.onClose = () => {
            this.setState({
                selectedRows: this.initialState.selectedRows
            });
        }
    };

    onSelectAllRows = () => {
        if (this.state.selectedRows.length === this.state.emailTemplates.length) {
            this.setState({selectedRows: []});
        } else {
            this.setState({selectedRows: this.state.emailTemplates});
        }
    };
    onSelectedRow = (row) => {
        const {selectedRows} = this.state;

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            this.setState({
                selectedRows: newSelectedRows
            })
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            this.setState({
                selectedRows: newSelectedRows
            })
        }
    };

    onDialogClose = () => {
        this.setState({
            openDialog: false
        });
    };
}

export default withTranslation("i18n")(
    withRouter(EmailTemplatePage)
);
