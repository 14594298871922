const MQService = {
  fullHeight: () => { return window.innerHeight; },
  fullWidth: () => { return window.innerWidth; },
  dialogLgHeight: () => { return (MQService.fullHeight() * 0.9) - MQService.NavBarHeight },
  dialogMdHeight: () => { return (MQService.fullHeight() * 0.7) - MQService.NavBarHeight },
  contentPageHeight: () => { return MQService.fullHeight() - (MQService.NavBarHeight + MQService.BreadCrumbHeight) },
  contentPageHeightOnContributorMaster: () => { return MQService.fullHeight() },
  auditViewHeaderHeight: () => { return (MQService.fullHeight() * 0.2) - (MQService.NavBarHeight + MQService.BreadCrumbHeight) },
  auditViewContentHeight: () => { return (MQService.fullHeight() * 0.8) - (MQService.NavBarHeight + MQService.BreadCrumbHeight) },
  NavBarHeight: 64,
  BreadCrumbHeight: 30,
  DrawerWidth: 240,
  DrawerWidthClose: 54
}
export default MQService;
