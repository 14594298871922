import pubsub from "pubsub-js";
import ObjectExt from '../utils/ObjectExt';

//TODO: Please Use this service instead pupsub direcly 
const PubSubService = {
  SubscribeTo: (msgKey, callBack) => {
    pubsub.subscribe(msgKey, (name, data) => {

      if (ObjectExt.IsNullOrUndefined(callBack))
        throw "SubscribeToFunc => Invalid callback param: callback can't be null or undefined";

      if (!ObjectExt.IsFunc(callBack))
        throw "SubscribeToFunc => Invalid callback param: callback most be a function";

      callBack(name, data);
    });
  },
  UnSubscribeFrom: (msgKey) => {
    pubsub.unsubscribe(msgKey)
  },
  PublishAt: (msgKey, data) => {
    pubsub.publish(msgKey, data);
  },
  KeyAuditCreated: "auditCreated",
  KeyAuditEdit: "auditEdited",
  KeyContributorsAdded: "contributorsAdded",
  KeyAdminCreated: "adminCreated",
  KeyAdminEdit: "adminEdited",
  KeyColaboratorCreated: "colaboratorCreated",
  KeyContributorEdited: "contributorEdited",
  KeyEmailTemplateCreated: "emailTemplateCreated",
  KeyEmailTemplateEdited: "emailTemplateEdited",
  KeyAttachmentCreated: "attachmentCreated",
  KeyToggleNavSideBar: "toggleNavSideBar",
  KeyShowToastMessage: "showToastMessage",
  KeyCloseToastMessage: "closeTeastMessage",
  KeyCommentAddedMessage: "commentAddedMessage",
  KeyAuditContributorRemoved: "auditContributorRemoved"
  //TODO: Please Add here any other Message Key.
}
export default PubSubService;
