import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './settings/i18n';
import Toaster from './components/Toaster/Toaster';
import ApiServiceComponent from './services/ApiService';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import defaultTheme from './styles/defaultTheme';

import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ApiServiceComponent />
    <MuiThemeProvider theme={defaultTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
        <Toaster />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>

  </BrowserRouter>,
  rootElement);

registerServiceWorker();
