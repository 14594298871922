import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import ChapterDataService from "../Chapter/ChapterDataService";
import i18n from "../../translations/i18n";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AuditDetailsContributorAddTable from "./AuditDetailsContributorRemoveTable";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import BaseIncludeTable from "../Commons/BaseIncludeTable";

const AuditDetailsChapterSelector = (props) => {

  const propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
  };
  const { open, onClose, onSelected, t } = props;
  const [chapters, setChapters] = React.useState([]);
  const [selected, setSelected] = React.useState();

const actions ={
    hideAddToolbar: true,
    hideRemoveToolbar:true,
    hideRemoveRow:true
};

  useEffect(() => {
    ChapterDataService.getAllChapters(response => {
      setChapters(response.data.result);
    }, error => {
      //TODO show toast
    })
  }, []);

  const headers = [
    t(i18n.ChapterNumber),
    t(i18n.Title),
    t(i18n.Language),
    t(i18n.Version),
    t(i18n.RevisionYear)
  ];

  const renderRowData = (data) => {
    return [
      data.number,
      data.title,
      data.language,
      data.version,
      data.releaseYear
    ];
  };
   const filterProps = [
        'number',
        'title',
        'language',
        'version',
        'releaseYear'
    ];

  const DialogScrollType = {
    paper: "paper",
    body: "body"
  };
  const DialogSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
  };

  return (

    <Dialog
      fullWidth={false}
      maxWidth={DialogSize.md}
      open={open}
      onClose={onClose}
      scroll={DialogScrollType.paper}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{t('AddChapterModalTitle')}</DialogTitle>
      <DialogContent>
          <BaseIncludeTable
              isNotMultiSelect={true}
              rowsData={chapters}
              renderHeaders={headers}
              renderRow={renderRowData}
              onAddRow={onSelected}
              selectedRows={[]}
              filterProps={filterProps}
              actions={actions}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {
            t("Close")
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
};
export default withTranslation("i18n")(AuditDetailsChapterSelector);
