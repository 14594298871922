import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import DashboardStyles from './DashboardStyles';

export const Icon = {
    home: 1,
    administrator: 2,
    contributor: 3,
    emailTemplate: 4,
    audit: 5,
    chapter: 6
}

const renderIcon = (icon) => {
    switch (icon) {
        case Icon.home:
            return (<HomeIcon ></HomeIcon>);
        case Icon.administrator:
            return (<SupervisorAccountIcon />);
        case Icon.contributor:
            return (<PeopleIcon />);
        case Icon.emailTemplate:
            return (<EmailIcon />);
        case Icon.audit:
            return (<QuestionAnswerIcon />);
        case Icon.chapter:
            return (<LibraryBooksIcon />);
        default:
            return (<HomeIcon></HomeIcon>);

    }
}

const DashboardTile = (props) => {

    const { t, title, subTitle, content, icon, onCardClick, number, numberUnit } = props;
    const classes = DashboardStyles();

    return (
        <Card elevation={0} className={classes.card}
            onClick={onCardClick}>
            <CardHeader avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>{renderIcon(icon)}</Avatar>
            }
                title={title}
                subheader={subTitle}
            />
            <CardContent>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="body1" color="textPrimary" component="p">
                        {number}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {numberUnit}
                    </Typography>
                </Grid>
            </CardContent>

        </Card>
    );
}
export default withTranslation("i18n")(withRouter(DashboardTile));
