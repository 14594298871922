import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import BaseSpacer from './BaseSpacer';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileSharp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { withTranslation } from "react-i18next";
import i18n from '../../translations/i18n';
import clsx from "clsx";
import ObjectExt from "../../utils/ObjectExt";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from "lodash";
import FileUpload from "../FileUpload/FileUpload";


const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  formControl: {
    minWidth: 120,
    marginTop: '16px',
    marginLeft: '20px',
    paddingRight: '10px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  actionsContiner: {
    width: 'max-content'
  },
  actionButton: {
    marginTop: '6%'
  }
}));

const BaseTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const initialState = {
    searchQuery: '',
    searchProp: {
      key: '',
      value: '',
      index: -1
    }
  };

  const { t, numSelected, actions, title, subTitle, onAdd,onDelete,
      onSendEmail, onSearch, onExcelImport, data, filterProps,onCleanSearch } = props;

  const [searchProp, setSearchProp] = React.useState(initialState.searchProp);
  const [searchQuery, setSearchQuery] = React.useState(initialState.searchQuery);

  const [selectableSearchProps, setSelectableSearchProps] = React.useState([]);

  React.useEffect(() => {

    if (!data || !filterProps || filterProps.length === 0 || selectableSearchProps.length > 1) return;

    const objProps = Object.keys(data).reverse();
    const keysArray = _.intersection(filterProps,objProps);
    const filterKeys = [];


    _.forEach(keysArray, (prop, i) => {
          filterKeys.push({ key: t(_.startCase(prop).replace(/ /s, '')), value: prop, index: i });
    });

    const constSelectableProps = [...selectableSearchProps, ...filterKeys.map((prop, i) => {
      return { key: prop.key, value: prop.value, index: i }
    })];
    setSearchProp(constSelectableProps.length>0 ? constSelectableProps[0]:initialState.searchProp);
    setSelectableSearchProps(constSelectableProps);

  });

  const handleSelectChange = (event) => {
    setSearchProp(event.target.value);
  };

  const handleSearch = (ev) => {
    setSearchQuery(ev.target.value.toLowerCase());
    onSearch(ev.target.value, searchProp);
  };

  const cleanSearchText = () => {
    setSearchQuery('');
    onSearch('', searchProp);
    onCleanSearch();
  };

  const renderAdd = () => {
    return (!ObjectExt.IsNullOrUndefined(actions) && actions.hideAdd) ? null
      : (
        <Tooltip className={classes.actionButton} title={t(i18n.Add)}>
          <IconButton aria-label="filter list" onClick={onAdd}>
            <NoteAddIcon color="primary" />
          </IconButton>
        </Tooltip>
      );
  };

  const renderDelete = () => {
    return (!ObjectExt.IsNullOrUndefined(actions) && actions.hideDelete) ? null
      : (<Tooltip className={classes.actionButton} title={t(i18n.Delete)}>
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon color="primary" />
        </IconButton>
      </Tooltip>);
  };

  const renderSend = () => {
    return (!ObjectExt.IsNullOrUndefined(actions) && actions.showSend) ?
      (<Tooltip className={classes.actionButton} title={t(i18n.Send)}>
        <IconButton aria-label="send" onClick={onSendEmail}>
          <SendIcon color="primary" />
        </IconButton>
      </Tooltip>) : null;
  };

  const renderExcelImport = ()=>{
      return (!ObjectExt.IsNullOrUndefined(actions) && actions.showExcelImport) ?
          (<Tooltip className={classes.actionButton} title={t(i18n.ImportFromExcel)}>
              <IconButton aria-label="import" onClick={onExcelImport}>
                  <InsertDriveFileIcon color="primary" />
              </IconButton>
          </Tooltip>) : null;
  };

  const renderSearch = () => {

    return (
      <>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="search-query">{t(i18n.SearchText)}</InputLabel>
          <Input
            id="search-query"
            value={searchQuery}
            margin="normal"
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="chapter picker" onClick={cleanSearchText}>
                  <CloseIcon color="primary" />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="search-input">{t(i18n.SearchFilter)}</InputLabel>
          <Select
            variant='standard'
            value={searchProp}
            onChange={handleSelectChange}
            autoWidth>
            {selectableSearchProps.map(prop =>  (
                <MenuItem key={prop.index} value={prop}>
                  {prop.key}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </>);
  };

  const renderActions = () => {
    switch (true) {
      case numSelected === 1: {
        return (
          <div className={classes.actionsContiner}>
            {renderSearch()}
            {renderAdd()}
            {renderExcelImport()}
            {renderDelete()}
            {renderSend()}
          </div>
        );
      }
      case numSelected > 1: {
        return (
          <div className={classes.actionsContiner}>
            {renderSearch()}
            {renderAdd()}
            {renderExcelImport()}
            {renderDelete()}
          </div>
        )
      }
      case numSelected === 0: {
        return (
          <div className={classes.actionsContiner}>
            {renderSearch()}
            {renderAdd()}
            {renderExcelImport()}
          </div>)
      }
    }
  };
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>

      {/*<Grid continer item xs={12}>*/}
      {/*<Grid item xs={6}>*/}
      {/**/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/**/}
      {/*</Grid>*/}
      {/*</Grid>*/}
      <div className={classes.title}>
        {
          numSelected > 0 ?
            (
              <>
                <Typography variant="h6" id="tableTitle">
                  {title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {subTitle}
                </Typography>
                <Typography color="inherit" variant="subtitle1">
                  {numSelected} selected
                </Typography>
              </>
            ) :
            (
              <>
                <Typography variant="h6" id="tableTitle">
                  {title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {subTitle}
                </Typography>

              </>
            )
        }
      </div>

      <BaseSpacer></BaseSpacer>

      <div className={classes.actions}>
        {renderActions()}
      </div>
    </Toolbar>
  );
};

BaseTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default withTranslation("i18n")(BaseTableToolbar);
