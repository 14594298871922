import PubSubService from './PubSubService';
import ObjectExt from '../utils/ObjectExt';

const StorageKeys = {
    USER_TOKEN: "access_token",
    USER_NAME: "username",
    USER_TOKEN_EXPIRATION: "user_token_expiration",
    APP_LANGUAGE: 'app_language'
}

const StorageService = {
    GetUserData: () => {
        return {
            username: localStorage.getItem(StorageKeys.USER_NAME),
            id: localStorage.getItem(StorageKeys.USER_ID),
            usertoken: localStorage.getItem(StorageKeys.USER_TOKEN)
        }
    },
    SetUserData: (usertoken, user) => {
        localStorage.setItem(StorageKeys.USER_TOKEN, usertoken);
        localStorage.setItem(StorageKeys.USER_NAME, user.userName);
        localStorage.setItem(StorageKeys.USER_ID, user.id);
        localStorage.setItem(StorageKeys.USER_TOKEN_EXPIRATION, JSON.stringify(60 * 60 * 24 * 1000 + new Date()));
    },
    RemoveUserData: () => {
        localStorage.removeItem(StorageKeys.USER_TOKEN);
        localStorage.removeItem(StorageKeys.USER_NAME);
        localStorage.removeItem(StorageKeys.USER_ID);
        localStorage.removeItem(StorageKeys.USER_TOKEN_EXPIRATION);
    },
    SetLanguage: (language) => {
        localStorage.setItem(StorageKeys.APP_LANGUAGE, language);
    },
    GetLanguage: () => {
        return localStorage.getItem(StorageKeys.APP_LANGUAGE);
    },
    RemoveLanguage: () => {
        return localStorage.removeItem(StorageKeys.APP_LANGUAGE);
    }
}
export default StorageService;
