import {makeStyles} from "@material-ui/core";
import React from "react";
import {DialogScrollType, DialogSize} from "../Commons/BaseDialog";
import i18n from "../../translations/i18n";
import {withTranslation} from "react-i18next";
import BaseDialog from "../Commons/BaseDialog";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        border: '0px'
    }, inputLabel: {
        zIndex: 1,
    },
}));

const ChapterAttachmentDetailsPage = (props) => {

    const { t, attachment, onUpdate, onClose, open } = props;
    const [data, setData] = React.useState(attachment);

    const handleChange = event => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const classes = useStyles();

    React.useEffect(() => {
        setData(attachment)
    }, [attachment]);

    const dialogButtons = [
        {
            title: props.t("Save"),
            onClick: () => {
                onUpdate(data);
            }
        }
    ];

    return (
        <BaseDialog
            size={DialogSize.md}
            onClose={onClose}
            fullWidth={false}
            open={open}
            scroll={DialogScrollType.body}
            title={t(i18n.Attachment)}
            buttons={dialogButtons}
        >
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-name"
                        name="name"
                        label={t(i18n.Name)}
                        className={classes.textField}
                        value={data.name}
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>
            </Grid>
        </BaseDialog>
    );
};

export default withTranslation("i18n")(
    withRouter(ChapterAttachmentDetailsPage)
);
