import _ from "lodash";
import numeral from "numeral";
import escape from "escape-html";
import { isFunction, isArray } from "util";

const getSectionContent = function(section, textMode) {
  switch (textMode) {
    case "FullText":
      return section.fullContent;
    case "ShortText":
      return section.fullContent ? _.take(section.fullContent, 2) : [];
    default:
      return section.keywordContent;
  }
};

export const NPKChapterType = {
  NPKPositionBase: 0,
  NPKSection: 1,
  NPKSubSection: 2,
  NPKPosition: 3,
  NPKNodeWithVariables: 4,
  NPKSubPositionGroup: 5,
  NPKSubPositionSubGroup: 6,
  NPKSubposition: 7,
  NPKRepeatPosition: 8
};

export const formatNpkItemNumber = function(number) {
  const mainNumber = Math.floor(number / 1000);
  const subNumber = number % 1000;

  const mainNumberFmt = numeral(mainNumber).format("000");
  const subNumberFmt = numeral(subNumber).format("000");

  return `.${mainNumberFmt}.${subNumberFmt}`;
};

export const formatNpkContent = function(section, textMode) {
  const content = getSectionContent(section, textMode);

  if (!content) return "";

  const separator = "------------------------------";
  const breakCode = "<br/>";

  let styledContents = "";

  if (Array.isArray(content)) {
    for (let i = 0; i < content.length; i += 1) {
      const line = content[i];

      if (i + 1 < content.length && content[i + 1] === separator) {
        styledContents += `<strong>${escape(line)}</strong>` + breakCode;
        i += 1;
      } else if (line.startsWith(". ")) {
        styledContents +=
          `<span style="display:list-item;list-style-type:disc;list-style-position:inside">` +
          escape(line.substr(2)) +
          `</span>`;
      } else {
        styledContents += line + breakCode;
      }
    }
  } else {
    styledContents = escape(content);
  }

  if (styledContents.endsWith(breakCode))
    styledContents = styledContents.substr(
      0,
      styledContents.length - breakCode.length
    );

  return styledContents;
};

export const Visit = (visitable, callback, childParams) => {
  if (!visitable) return;
  if (!callback || !isFunction(callback)) return;

  if (!childParams || childParams.visitingRoot) {
    callback({
      current: visitable,
      subNodes: visitable.subNodes,
      visitingRoot: true
    });
  } else {
    callback(childParams);
  }

  if (
    !visitable.subNodes ||
    visitable.subNodes === null ||
    !isArray(visitable.subNodes)
  )
    return;

  visitable.subNodes.forEach(child => {
    Visit(child, callback, {
      current: child,
      subNodes: child.subNodes,
      parent: visitable,
      visitingRoot: false
    });
  });
};
export const BuildTreeNode = visitables => {
  visitables.forEach(visitable => {
    Visit(visitable, params => {
      visitable.isRoot = params.visitingRoot;
      visitable.parent = params.parent;
    });
  });
  return visitables;
};
export const VisitNodeParents = (visitable, callback, childParams) => {
  if (!visitable) return;
  if (!callback || !isFunction(callback)) return;

  if (
    visitable.subNodes &&
    isArray(visitable.subNodes) &&
    visitable.subNodes.length > 0
  ) {
    visitable.subNodes.forEach(child => {
      VisitNodeParents(child, callback, {
        current: child,
        subNodes: child.subNodes,
        parent: visitable,
        visitingRoot: false
      });
    });
  } else if (childParams && childParams.parent) {
    callback({
      current: visitable,
      parent: childParams.parent,
      visitingRoot: false
    });
  } else {
    callback({ current: visitable, visitingRoot: true });
  }
};
