import React from 'react';
import PropTypes from 'prop-types';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BaseSpacer from './BaseSpacer';
import AddIcon from '@material-ui/icons/Add';
import {withTranslation} from "react-i18next";
import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from "@material-ui/core/InputAdornment";
import _ from "lodash";
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from "clsx";
import FormControlLabel from "../Audit/AuditDetailsContributorsAddTable";
import Switch from "@material-ui/core/Switch";


const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    formControl: {
        minWidth: 120,
        marginTop: '16px',
        marginLeft:'20px',
        paddingRight:'10px'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    actionsContiner: {
        width: 'max-content'
    },
    actionButton:{
        marginTop:'6%'
    }
}));

const BaseIncludeToolbar = (props) => {
    const classes = useToolbarStyles();
    const initialState = {
        searchQuery: '',
      searchProp: {
        key: '',
        value: '',
        index: -1
      }
    };

    const {t, numSelected, actions, title, subTitle, onAddToolbar,contributorFilter,
        onRemove,  onSearch, data,filterProps,onContributorFilterChange,onCleanSearch} = props;

    const [searchProp, setSearchProp] = React.useState(initialState.searchProp);
    const [searchQuery, setSearchQuery] = React.useState(initialState.searchQuery);
    const [selectableSearchProps, setSelectableSearchProps] = React.useState([]);

    React.useEffect(() => {

        if (!data || !filterProps || filterProps.length === 0 || selectableSearchProps.length > 1) return;

        const objProps = Object.keys(data).reverse();
        const keysArray = _.intersection(objProps, filterProps);
        const filterKeys = [];

        _.forEach(keysArray, (prop, i) => {
            filterKeys.push({ key: t(_.startCase(prop)), value: prop, index: i });
        });

        const constSelectableProps = [...selectableSearchProps, ...filterKeys.map((prop, i) => {
            return { key: prop.key, value: prop.value, index: i }
        })];
        setSearchProp(constSelectableProps.length>0 ? constSelectableProps[0]:initialState.searchProp);
        setSelectableSearchProps(constSelectableProps);

    });

    const handleSelectChange = (event) => {
        setSearchProp(event.target.value);
    };

    const handleSearch = (ev) => {
        setSearchQuery(ev.target.value.toLowerCase());
        onSearch(ev.target.value, searchProp);
    };

    const cleanSearchText = () =>{
        setSearchQuery('');
        onSearch('', searchProp);
        onCleanSearch();
    };

    const renderAdd = () => {
        return (!ObjectExt.IsNullOrUndefined(actions) && actions.hideAddToolbar) ? null
            : (

                <Tooltip className={classes.actionButton} title={t(i18n.Add)}>
                    <IconButton aria-label="filter list" onClick={onAddToolbar}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
            );
    };

    const renderRemove = () => {
        return (!ObjectExt.IsNullOrUndefined(actions) && actions.hideRemoveToolbar) ? null
            : (<Tooltip className={classes.actionButton} title={t(i18n.Remove)}>
                <IconButton aria-label="remove" onClick={onRemove}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>);
    };

    const renderContributorFilter = ()=>{
        return (!ObjectExt.IsNullOrUndefined(actions) && actions.showContributorFilter) ?
            (<Tooltip className={classes.actionButton} title={t(i18n.AuditContributorAvailableFilter)}>
                <Switch
                    checked={contributorFilter}
                    onChange={onContributorFilterChange}
                    value={contributorFilter}
                    color="primary"
                />
            </Tooltip>):null
    };


    const renderSearch = () => {

        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="search-query">{t(i18n.SearchText)}</InputLabel>
                    <Input
                        id="search-query"
                        value={searchQuery}
                        margin="normal"
                        onChange={handleSearch}
                        disabled={searchProp.value === 'None'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="chapter picker" onClick={cleanSearchText}>
                                    <CloseIcon color="primary" />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="search-input">{t(i18n.SearchFilter)}</InputLabel>
                    <Select
                        variant='standard'
                        value={searchProp}
                        onChange={handleSelectChange}
                        autoWidth>
                        {selectableSearchProps.map(prop => (
                            <MenuItem key={prop.index} value={prop}>
                                {prop.key}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </>);
    };

    const renderActions = () => {
        switch (true) {
            case numSelected === 1: {
                return (
                    <div className={classes.actionsContiner}>
                        {renderSearch()}
                        {renderContributorFilter()}
                        {renderAdd()}
                        {renderRemove()}
                    </div>
                );
            }
            case numSelected > 1: {
                return (
                    <div className={classes.actionsContiner}>
                        {renderSearch()}
                        {renderContributorFilter()}
                        {renderAdd()}
                        {renderRemove()}
                    </div>
                )
            }
            case numSelected === 0: {
                return (
                    <div className={classes.actionsContiner}>
                        {renderSearch()}
                        {renderContributorFilter()}
                        {renderAdd()}
                    </div>)
            }
        }
    };
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            <div className={classes.title}>
                {
                    numSelected > 0 ?
                        (
                            <>
                                <Typography variant="h6" id="tableTitle">
                                    {title}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {subTitle}
                                </Typography>
                                <Typography color="inherit" variant="subtitle1">
                                    {numSelected} selected
                                </Typography>
                            </>
                        ) :
                        (
                            <>
                                <Typography variant="h6" id="tableTitle">
                                    {title}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {subTitle}
                                </Typography>

                            </>
                        )
                }
            </div>

            <BaseSpacer></BaseSpacer>

            <div className={classes.actions}>
                {renderActions()}
            </div>
        </Toolbar>
    );
};

BaseIncludeToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default withTranslation("i18n")(BaseIncludeToolbar);
