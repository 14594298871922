import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    root: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    paper: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    link: {
      cursor: 'pointer'
    }
  }));
  