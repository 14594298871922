import { fade, makeStyles } from '@material-ui/core/styles';
import MQService from '../../services/MQService';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '0px 16px',
    height: `${MQService.NavBarHeight}px`,
    backgroundColor: theme.palette.primary,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: MQService.DrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: MQService.DrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: MQService.DrawerWidthClose,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  content: {
    top:  `${MQService.NavBarHeight}px`,
    position: 'relative',
    height: `${MQService.fullHeight()-MQService.NavBarHeight}px`,
    width: '100%',
    backgroundColor: theme.palette.common.white
  },
  grow: {
    flexGrow: 1,
  },
}));
