import PubSubService from './PubSubService';
import ObjectExt from '../utils/ObjectExt';

const ToastService = {
    ShowToast: (content) => {
        PubSubService.PublishAt(PubSubService.KeyShowToastMessage, content);
    },
    OnToastClose: (callback) => {
        PubSubService.SubscribeTo(PubSubService.KeyCloseToastMessage, () => {
           if(ObjectExt.IsNullOrUndefined(callback))  return;
           if(!ObjectExt.IsFunc(callback)) return;
           callback();
        });
        
    }
}
export default ToastService;