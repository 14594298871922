import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import useStateWithCallback from "use-state-with-callback";
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import ObjectExt from '../../utils/ObjectExt';
import AuditDetailsPage from '../Audit/AuditDetailsPage';
import BaseTable from '../Commons/BaseTable';
import { ConfirmDialog, ConfirmDialogService } from '../Commons/ConfirmDialog';
import AuditDataService from './AuditDataService';
import AuditStepperSendEmail from "./AuditStepperSendEmail";
import { ConfirmSaveDialog, ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";
import {AuditStatus} from "../../utils/Enums";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
        float: 'right'
    }
}));

const AuditPage = props => {
    const { t } = props;
    const initialState = {
        isLoaded: false,
        selectedRow: {
            id: 0,
            name: '',
            chapter: {
                title: '',
                id: 0
            },
            status:AuditStatus.Preparing,
            fromDate: new Date(),
            toDate:new Date(),
            chapterId: 0,
            templateId: 0,
            templateName: '',
            contributors: [],
            selectedRevisionId: 0,
            auditRevisions: []
        },
        selectedRows: [],
        openDialog: false,
        showConfirmDialog: false,
        audits: []

    };

    const [selectedRows, setSelectedRows] = React.useState(initialState.selectedRows);
    const [audits, setAudits] = React.useState(initialState.audits);
    const [openDialog, setOpenDialog] = React.useState(initialState.openDialog);
    const [selectedRow, setSelectedRow] = React.useState(initialState.selectedRow);
    const [openStepperDialog, setOpenStepperDialog] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(initialState.isLoaded);

    //This was needed because hook don't have callback function to call whe state is updated
    const [showConfirmDialog, setShowConfirmDialog] = useStateWithCallback(initialState.showConfirmDialog, () => {
        if (showConfirmDialog) {
            ConfirmDialogService.open();
            setShowConfirmDialog(initialState.showConfirmDialog);
        }
    });

    const classes = useStyles();

    const loadAudits = () => {
        AuditDataService.getAllAudits(
            response => {
                setAudits(response.data.result);
                setIsLoaded(true);
            },
            error => {
                ToastService.ShowToast(error);
            }
        );
    };

    React.useEffect(() => {
        loadAudits();
    }, []);

    const onSelectAllRows = () => {
        if (selectedRows.length === audits.filter(rev=>rev.isDefaultRevision).length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(audits.filter(rev=>rev.isDefaultRevision));
        }
    };

    const onSelectedRow = row => {

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
        }
    };

    const onEditRow = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    };

    const onDeleteRow = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            ConfirmDialogService.open();
            selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            setSelectedRows([tableRow]);
            ids.push(tableRow.id);
            setShowConfirmDialog(true);
        }
        ConfirmDialogService.onAccept = () => {
            AuditDataService.deleteAudits(
                ids,
                response => {
                    if (!response.data.isSuccess) {
                        ToastService.ShowToast(response.data.message);
                        setSelectedRows(initialState.selectedRows);
                        return;
                    }
                    const newAudits = audits.slice();
                    const auditsDeleted = response.data.result;
                    _.forEach(auditsDeleted, audit => {
                        _.remove(newAudits, q => q.id === audit.id);
                    });
                    setAudits(newAudits);
                    setSelectedRows(initialState.selectedRows)
                },
                error => {
                    setSelectedRows(initialState.selectedRows);
                    ToastService.ShowToast(props.t(i18n.ServerFailedToProcessCurrentRequest));
                }
            );
        };

        ConfirmDialogService.onClose = () => {
            setShowConfirmDialog(initialState.showConfirmDialog);
            setSelectedRows(initialState.selectedRows);
        };
    };

    const onCreateButton = () => {
        debugger;
        setSelectedRow(initialState.selectedRow);
        setOpenDialog(true);
    };
    const onDeleteButton = () => {
    };

    const onDialogClose = () => {
        setOpenDialog(false);
    };

    const onDetailsSave = selected => {
        const { id } = selected;

        if (ObjectExt.IsNullOrUndefined(selected.chapterId) || selected.chapterId === 0) {
            ToastService.ShowToast(t(i18n.RevisionWithoutChapter));
            return;
        }

        if (id == 0) {

            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {

                AuditDataService.addAudit(selected, response => {
                    if (!response.data.isSuccess) {
                        ToastService.ShowToast(response.data.message);
                        return;
                    }
                    loadAudits();
                },
                  error => {
                    ToastService.ShowToast(t(i18n.AuditDetailPageImpossibleToAddRevision));
                    }
                );
                setOpenDialog(false);
            }
        } else {
            // if (selectedRow.chapterId !== selected.chapterId) {
            //     const index = _.findIndex(selected.auditRevisions, revision => {
            //         return revision.chapterId === selected.chapterId
            //     });
            //     if (index !== -1) {
            //         ToastService.ShowToast("Chapter found in other revision.You must choose another chapter.");
            //         return;
            //     }
            // }
            ConfirmSaveDialogService.open();

            ConfirmSaveDialogService.onAccept = () => {
                AuditDataService.editAudit(
                    selected,
                    response => {
                        if (!response.data.isSuccess) {
                            ToastService.ShowToast(response.data.message);
                            return;
                        }
                        loadAudits();
                    },
                  error => {
                    ToastService.ShowToast(t(i18n.AuditDetailPageImpossibleToEditRevision));
                    }
                );
                setOpenDialog(false);
            }
        }
        ConfirmSaveDialogService.onClose = () => {
        }
    };
    const openSendEmailStepperDialog = () => {
        setSelectedRow(selectedRows[0]);
        setOpenStepperDialog(true);
    };

    const onCloseSendEmailStepperDialog = () => {
        setOpenStepperDialog(false);
        setSelectedRows(initialState.selectedRows);
        setSelectedRow(initialState.selectedRow);
    };

    const onNavigateToChapterView = (row) => {
        if (!ObjectExt.IsNullOrUndefined(row.chapter)) {
            props.history.push(`/audits/view`, { audit: row });
        }
        else {
            ToastService.ShowToast("Audit need chapter to navigate (TRANLATE)")
        }
    };
    const onAddRevision = auditWithNewRevision => {
        // const newAudits = audits.slice();
        // const index = _.findIndex(audits, audit => {
        //     return audit.id == auditWithNewRevision.id;
        // });
        // newAudits[index] = auditWithNewRevision;
        // setSelectedRow(auditWithNewRevision);
        // setAudits(newAudits);
      loadAudits();
    };

    const setToEspecificRevision = auditForSpecificRevision => {
        setSelectedRow(auditForSpecificRevision);
    };

    const renderRowData = data => {

        const { status, chapterId, chapter } = data;
        const chapterLanguage = ObjectExt.IsNullOrUndefined(chapter) ? "" : chapter.language;
        return [
            data.name,
            t(data.status),
            chapter.number,
            t(chapterLanguage),
            data.fromDate && moment(data.fromDate).format('LL'),
            data.toDate && moment(data.toDate).format('LL'),
            data.isDefaultRevision ? t(i18n.FirstRevision): t(i18n.NotFirstRevision)
        ];
    };

    const filterProps = [
        'name',
        'status',
        'fromDate',
        'chapterNumber',
        'chapterLanguage',
        'toDate',
    ];

    const actions = {
        hideAdd: false,
        hideEdit: false,
        hideDelete: false,
        showSend: true,
        navigateToChapterView: true
    };

    const headers = [
        t(i18n.Name),
        t(i18n.Status),
        t(i18n.Chapter),
        t(i18n.Language),
        t(i18n.FromDate),
        t(i18n.ToDate),
        t(i18n.IsRevision)
    ];

    return (
        <>
            <AuditDetailsPage
                onClose={onDialogClose}
                selected={selectedRow}
                open={openDialog}
                onUpdate={onDetailsSave}
                onAddRevision={onAddRevision}
                onSetToEspecificRevision={setToEspecificRevision}
            />

            <AuditStepperSendEmail
                open={openStepperDialog}
                onClose={onCloseSendEmailStepperDialog}
                audit={selectedRow}
            />

            <ConfirmSaveDialog
                title={t(i18n.ConfirmDialogTitle)} />

            <ConfirmDialog title={t(i18n.ConfirmDialogTitle)} elements={selectedRows.length} />

            <div style={{ padding: '5px' }}>
                <BaseTable
                    title={t(i18n.AvailableAudits)}
                    subTitle={t(i18n.AvailableAuditsMore)}
                    rowsData={audits}
                    renderHeaders={headers}
                    renderRow={renderRowData}
                    selectedRows={selectedRows}
                    onSelectRow={onSelectedRow}
                    onAddRow={onCreateButton}
                    onEditRow={onEditRow}
                    onDeleteRow={onDeleteRow}
                    onSendEmail={openSendEmailStepperDialog}
                    onNavigateToChapterView={onNavigateToChapterView}
                    onSelectAllRows={onSelectAllRows}
                    filterProps={filterProps}
                    actions={actions}
                    showLoader={!isLoaded}
                    isAuditTable={true}
                />
            </div>
        </>
    );
};

export default withTranslation('i18n')(withRouter(AuditPage));
