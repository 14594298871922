import { TableCell } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailIcon from "@material-ui/icons/Mail";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import cx from "classnames";
import _ from "lodash";
import numeral from "numeral";
import React from "react";
import { withTranslation } from "react-i18next";

import i18n from '../../translations/i18n';
import { Comments } from "../../utils/Mock";
import {
  CatalogCompareIcon,
  CatalogInfoIcon,
  CatalogInfoPictureIcon,
  CatalogLeafIcon
} from "./CatalogInteractors";
import CommentIcon from "@material-ui/icons/Comment";
import ObjectExt from "../../utils/ObjectExt";
import TeastService from "../../services/ToastService";
import CommentDataService from "../Comment/CommentDataService";
import PubSubService from "../../services/PubSubService";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  badge: {
    cursor: "pointer"
  },
  expander: {
    cursor: "pointer"
  },
  padding: {
    padding: theme.spacing(0, 2)
  }
}));

export const CatalogDrawColumn = props => {
  const { t, section, onBadgeClick, userId, audit } = props;

  const [commentNumber, setCommentNumber] = React.useState(null);
  const [hasReplies, setHasReplies] = React.useState(false);

  const [drawPositionId, setDrawPositionId] = React.useState(null);

  const classes = useStyles();

  const updateCommentsNumber = positionId => {
    CommentDataService.getCommentsByPosition(
      positionId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          TeastService.ShowToast(response.data.message);
          return;
        }
        if (response.data.result.length > 0) {
          _.forEach(response.data.result, (r) => {
            setHasReplies(!ObjectExt.IsNullOrUndefined(r.replies) && r.replies.length > 0);
          });
        }
        setCommentNumber(response.data.result.length);
      },
      error => {
        TeastService.ShowToast(error);
      }
    );
  };

  React.useEffect(() => {
    const positionId =
      section && section.drawingPositionID && section.drawingPositionID !== ""
        ? section.drawingPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    setDrawPositionId(positionId);
    PubSubService.SubscribeTo(
      PubSubService.KeyCommentAddedMessage,
      (name, positionId) => {
        if (positionId !== section.drawingPositionID) return;
        updateCommentsNumber(section.drawingPositionID);
      }
    );
  }, []);

  React.useEffect(() => {
    const positionId =
      section && section.drawingPositionID && section.drawingPositionID !== ""
        ? section.drawingPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    updateCommentsNumber(positionId);
  }, [props.section]);

  const contextToSource = () => {
    return {
      id: drawPositionId,
      typeLabel: t(i18n.CommentTypeDrawing),
      label: null,
      itemNumber: section.number,
      variableNumber: null,
      drawingAlt: section.drawingId,
      drawingUrl: section.drawingPath
    };
  };

  const commentBadge = () => {

    const color = !hasReplies ? "primary" : "secondary";

    if (commentNumber && commentNumber > 0)
      return (
        <Badge
          className={classes.badge}
          badgeContent={commentNumber}
          color={color}
          onClick={() => onBadgeClick(contextToSource())}
        >
          <CommentIcon
           className={classes.expander} color="primary" />
        </Badge>
      );

    return (
      <Badge
        className={classes.badge}
        badgeContent={0}
        color={color}
        onClick={() => {
          onBadgeClick(contextToSource());
        }}
      >
        <CommentIcon className={classes.expander} color="primary" />
      </Badge>
    );
  };

  return (
    <TableCell align="center" style={{ width: "10%", verticalAlign: "top" }}>
      {section.drawingPath && section.drawingPath !== "" ? (
        <>
          {commentBadge(section.drawingId)}
          <a href={section.drawingPath} target="_blank">
            <img
              src={section.drawingPath}
              alt={section.drawingId}
              height="50"
              width="50"
            />
          </a>
        </>
      ) : null}
    </TableCell>
  );
};
export default withTranslation("i18n")(CatalogDrawColumn);
