import StorageService from '../services/StorageService';

const jsonIt = require('./it.json');
const jsonDe = require('./de.json');

const CompareEntries = (entriesA, entriesB) => {
  entriesA.forEach(k => {
    var index = entriesB.indexOf(k);
    if (index === -1) {
      return k;
    }
  });
  return null;
}
export const i18nCheck = () => {

  const objKeys = Object.keys(i18n);
  const itEntries = Object.keys(jsonIt.i18n);
  const deEntries = Object.keys(jsonDe.i18n);

  const resultDe = CompareEntries(itEntries, deEntries);
  if (resultDe !== null) throw `Missing translation entry on German file. Entry name: ${resultDe}`;

  const resultKeys = CompareEntries(itEntries, objKeys);
  if (resultKeys !== null) throw `Missing translation entry on i18n keys definition file. Entry name: ${resultKeys}`;

  return objKeys;
}
export const Language = () => {
  return StorageService.GetLanguage();
}
const i18n = {
  ProductTitle: 'ProductTitle',
  Configuration: 'Configuration',
  Administrators: 'Administrators',
  EditAdministrator: 'EditAdministrator',
  CreateContributor: 'CreateContributor',
  EditContributor: 'EditContributor',
  EditRevision: 'EditRevision',
  ViewRevision: 'ViewRevision',
  CreateEmailTemplate: 'CreateEmailTemplate',
  EditEmailTemplate: 'EditEmailTemplate',
  Emailtemplates: 'Emailtemplates',
  users: 'users',
  templates: 'templates',
  Id: 'Id',
  Name: 'Name',
  Surname: 'Surname',
  Email: 'Email',
  PasswordCurrent: 'PasswordCurrent',
  Password: 'Password',
  RepeatPassword: 'RepeatPassword',
  AvailableAdministrators: 'AvailableAdministrators',
  AvailableAdministratorsMore: 'AvailableAdministratorsMore',
  AdministratorDetail: 'AdministratorDetail',
  AdministratorDetailMore: 'AdministratorDetailMore',
  DeleteSelectedAdministrators: 'DeleteSelectedAdministrators',
  Save: 'Save',
  Cancel: 'Cancel',
  OK: 'OK',
  NameIsMandatory: 'NameIsMandatory',
  SurnameIsMandatory: 'SurnameIsMandatory',
  EmailIsNotValid: 'EmailIsNotValid',
  FromDate: 'FromDate',
  ToDate: 'ToDate',
  Dashboard: 'Dashboard',
  CreateAdministrator: 'CreateAdministrator',
  EmailTemplates: 'EmailTemplates',
  Contributors: 'Contributors',
  Domain: 'Domain',
  DomainPlaceholder: 'DomainPlaceholder',
  Company: 'Company',
  Phone: 'Phone',
  Address: 'Address',
  FromChapter: 'FromChapter',
  ToChapter: 'ToChapter',
  From: 'From',
  To: 'To',
  Author: 'Author',
  AvailableContributors: 'AvailableContributors',
  AvailableContributorsMore: 'AvailableContributorsMore',
  ContributorDetail: 'ContributorDetail',
  ContributorDetailMore: 'ContributorDetailMore',
  Details: 'Details',
  ChaptersRanges: 'ChaptersRanges',
  ChaptersRangesMore: 'ChaptersRangesMore',
  AddNewRange: 'AddNewRange',
  ImportFromExcel: 'ImportFromExcel',
  ImportFromExcelAlert: 'ImportFromExcelAlert',
  DeleteSelectedContributors: 'DeleteSelectedContributors',
  AddRange: 'AddRange',
  DeleteSelectedRanges: 'DeleteSelectedRanges',
  FieldIsMandatory: 'FieldIsMandatory',
  InsertNumericValue: 'InsertNumericValue',
  SubjectPlaceholder: 'SubjectPlaceholder',
  AvailableEmailTemplates: 'AvailableEmailTemplates',
  AvailableEmailTemplatesMore: 'AvailableEmailTemplatesMore',
  EmailTemplateDetail: 'EmailTemplateDetail',
  EmailTemplateDetailMore: 'EmailTemplateDetailMore',
  DeleteSelectedEmailTemplates: 'DeleteSelectedEmailTemplates',
  SendTest: 'SendTest',
  SendTestAlert: 'SendTestAlert',
  SubjectIsMandatory: 'SubjectIsMandatory',
  Status: 'Status',
  Chapter: 'Chapter',
  Language: 'Language',
  RevisionYear: 'RevisionYear',
  CommentsCount: 'CommentsCount',
  ContributorsCount: 'ContributorsCount',
  Preparing: 'Preparing',
  Active: 'Active',
  Editing: 'Editing',
  Revisioning: 'Revisioning',
  Auditing: 'Auditing',
  Closed: 'Closed',
  it: 'it',
  de: 'de',
  AvailableAudits: 'AvailableAudits',
  AvailableAuditsMore: 'AvailableAuditsMore',
  AuditDetail: 'AuditDetail',
  AuditDetailMore: 'AuditDetailMore',
  CreateAudit: 'CreateAudit',
  DeleteSelectedAudits: 'DeleteSelectedAudits',
  NavigateToChapter: 'NavigateToChapter',
  ChangeState: 'ChangeState',
  SendEmailTemplate: 'SendEmailTemplate',
  ChapterPanelTitle: 'ChapterPanelTitle',
  CommentViewerTitle: 'CommentViewerTitle',
  CommentViewerDescription: 'CommentViewerDescription',
  CommentViewerSearchPlaceHolder: 'CommentViewerSearchPlaceHolder',
  Send: 'Send',
  Message: 'Message',
  Read: 'Read',
  UnRead: 'UnRead',
  ExportComments: 'ExportComments',
  PickChapterModalTitle: 'PickChapterModalTitle',
  GeneralInformation: 'GeneralInformation',
  Schema: 'Schema',
  Attachment: 'Attachment',
  Attachments: 'Attachments',
  Overview: 'Overview',
  FromDateIsMandatory: 'FromDateIsMandatory',
  ToDateIsMandatory: 'ToDateIsMandatory',
  SearchPlaceHolder: 'SearchPlaceHolder',
  FilterByRange: 'FilterByRange',
  Avatar: 'Avatar',
  LastName: 'LastName',
  AddUsers: 'AddUsers',
  AddNewAudit: 'AddNewAudit',
  DefaultAuditTitle: 'DefaultAuditTitle',
  StartDate: 'StartDate',
  EndDate: 'EndDate',
  AuditManagerDescription: 'AuditManagerDescription',
  AuditManagerTitle: 'AuditManagerTitle',
  Collaborators: 'Collaborators',
  Comments: 'Comments',
  CreatedBy: 'CreatedBy',
  CreationDate: 'CreationDate',
  OverviewTabTitle: 'OverviewTabTitle',
  AuditTitle: 'AuditTitle',
  AuditTitlePlaceHolder: 'AuditTitlePlaceHolder',
  AuditStatus: 'AuditStatus',
  AuditActiveStatus: 'AuditActiveStatus',
  AuditRevisionStatus: 'AuditRevisionStatus',
  AuditClosedStatus: 'AuditClosedStatus',
  ChangeAuditStatus: 'ChangeAuditStatus',
  StatusStartDate: 'StatusStartDate',
  StatusEndDate: 'StatusEndDate',
  ChapterTitle: 'ChapterTitle',
  ChapterTitlePlaceHolder: 'ChapterTitlePlaceHolder',
  ChapterLanguage: 'ChapterLanguage',
  German: 'German',
  ChapterVersion: 'ChapterVersion',
  ChapterEdition: 'ChapterEdition',
  Update: 'Update',
  CommentsNumber: 'CommentsNumber',
  CollaboratorsNumber: 'CollaboratorsNumber',
  AuditViewRevisionSelectTitle: 'AuditViewRevisionSelectTitle',
  Revision: 'Revision',
  Report: 'Report',
  Title: 'Title',
  AddChapterModalTitle: 'AddChapterModalTitle',
  SearchChapterPlaceHolder: 'SearchChapterPlaceHolder',
  Version: 'Version',
  Edition: 'Edition',
  Position: 'Position',
  Response: 'Response',
  RemoveUser: 'RemoveUsers',
  ContactUser: 'ContactUser',
  UserProfile: 'UserProfile',
  Contributor: 'Contributor',
  Auditor: 'Auditor',
  ChapterDetail: "ChapterDetail",
  ConfirmDialogDeleteContent: "ConfirmDialogDeleteContent",
  ConfirmDialogSaveContent: "ConfirmDialogSaveContent",
  Elements: "Elements",
  ConfirmDialogTitle: "ConfirmDialogTitle",
  Activate: "Activate",
  CommentsFor: "CommentsFor",
  ChapterNumber: "ChapterNumber",
  Delete: "Delete",
  Add: "Add",
  Filter: "Filter",
  Subject: "Subject",
  AvailableChapters: "AvailableChapters",
  AvailableChaptersMore: "AvailableChaptersMore",
  UploadChapter: "UploadChapter",
  DeleteSelectedChapters: "DeleteSelectedChapters",
  ChapterAttachments: "ChapterAttachments",
  UploadAttachment: "UploadAttachment",
  ContributorChapterRanges: 'ContributorChapterRanges',
  ContributorChapterRangeDetailsTitle: "ContributorChapterRangeDetailsTitle",
  AuditViewBlockedByStatus: "AuditViewBlockedByStatus",
  Edit: "Edit",
  ChapterDrawings: "ChapterDrawings",
  UploadDrawings: "UploadDrawings",
  DrawingsOnChapter: "DrawingsOnChapter",
  DrawingsToUpload: "DrawingsToUpload",
  DrawingsUploaded: "DrawingsUploaded",
  SearchText: "SearchText",
  SearchFilter: "SearchFilter",
  Actions: "Actions",
  AuditContributorAvailableFilter: "AuditContributorAvailableFilter",
  Remove: "Remove",
  EmailTemplateLegendTitle: "EmailTemplateLegendTitle",
  UserNameTooltip: "UserNameTooltip",
  AuditLinkTooltip: "AuditLinkTooltip",
  ChapterNumberTooltip: "ChapterNumberTooltip",
  ChapterLanguageTooltip: "ChapterLanguageTooltip",
  ChapterRevisionTooltip: "ChapterRevisionTooltip",
  Revisions: "Revisions",
  RevisionSelectPlaceholder: "RevisionSelectPlaceholder",
  EmailStepperInfo: "EmailStepperInfo",
  EmailStepperTitle: "EmailStepperTitle",
  EmailStepperSelectTemplate: "EmailStepperSelectTemplate",
  EmailStepperSelectDestination: "EmailStepperSelectDestination",
  EmailStepperSelectFinish: "EmailStepperSelectFinish",
  Audit: "Audit",
  EmailSentSuccessfully: "EmailSentSuccessfully",
  ErrorSendingEmail: "ErrorSendingEmail",
  EmailStepperFinish: "EmailStepperFinish",
  EmailStepperNext: "EmailStepperNext",
  Login: "Login",
  SignIn: "SignIn",
  SignOutSuccessfully: "SignOutSuccessfully",
  ErrorSigningOut: "ErrorSigningOut",
  Today: "Today",
  SelectPleaseHolder: "SelectPleaseHolder",
  SendEmail: "SendEmail",
  CreateRevision: "CreateRevision",
  CommentEditorTitle: "CommentEditorTitle",
  CommentCreatorTitle: "CommentCreatorTitle",
  CommentRespondsTitle: "CommentRespondsTitle",
  FirstRevision: "FirstRevision",
  NotFirstRevision: "NotFirstRevision",
  AuditViewBlockedByChapter: "AuditViewBlockedByChapter",
  RevisionWithoutChapter: "RevisionWithoutChapter",
  ExcelImportReportDialogTitle: "ExcelImportReportDialogTitle",
  ExcelImportReportDialogImportedCount: "ExcelImportReportDialogImportedCount",
  ExcelImportReportDialogNotImportedCount: "ExcelImportReportDialogNotImportedCount",
  ExcelImportReportDialogNotImportedExcelRows: "ExcelImportReportDialogNotImportedExcelRows",
  Row: "Row",
  ChapterRangeValidation: "ChapterRangeValidation",
  FinishBtn: "Finish",
  NextBtn: "NextBtn",
  BackBtn: "BackBtn",
  ExcelCommentExportIsRunning: "ExcelCommentExportIsRunning",
  EmailTemplateSelected: "EmailTemplateSelected",
  ChangePasswordMismatch: "ChangePasswordMismatch",
  ChapterViewContentColumnTitle: "ChapterViewContentColumnTitle",
  ChapterViewUnitsColumnTitle: "ChapterViewUnitsColumnTitle",
  ChapterViewDrawingColumnTitle: "ChapterViewDrawingColumnTitle",
  CommentTypeItem: "CommentTypeItem",
  CommentTypeVariable: "CommentTypeVariable",
  CommentTypeUnit: "CommentTypeUnit",
  CommentTypeHint: "CommentTypeHint",
  CommentTypeDrawing: "CommentTypeDrawing",
  CommentTypeAttachment: "CommentTypeAttachment",
  EmailBody: "EmailBody",
  PositionContentNotAvailableToCopy: "PositionContentNotAvailableToCopy",
  CopyPositionContentButtonLabel: "CopyPositionContentButtonLabel",
  SendMessageButtonLabel: "SendMessageButtonLabel",
  CommentSingleCreatorTitle: "CommentSingleCreatorTitle",
  CommentSingleCreatorContentText: "CommentSingleCreatorContentText",
  CommentSingleEditorTitle: "CommentSingleEditorTitle",
  CommentSingleEditorContentText: "CommentSingleEditorContentText",
  CommentReplyCreatorTitle: "CommentReplyCreatorTitle",
  CommentReplyEditorTitle: "CommentReplyEditorTitle",
  CommentPublicTooltip: "CommentPublicTooltip",
  CommentPrivateTooltip: "CommentPrivateTooltip",
  CommentEditTooltip: "CommentEditTooltip",
  CommentAttachmentTooltip: "CommentAttachmentTooltip",
  CommentReplyTooltip: "CommentReplyTooltip",
  ExpandMore: "ExpandMore",
  ExpandLess: "ExpandLess",
  ServerFailedToProcessCurrentRequest: "ServerFailedToProcessCurrentRequest",
  RowsPerPage: "RowsPerPage",
  UserEmailTooltip: "UserEmailTooltip",
  WriteYourAnswer: "WriteYourAnswer",
  IsRevision: "IsRevision",
  ChapterViewSwitchTooltip: "ChapterViewSwitchTooltip",
  Of: "Of",
  AuditDetailPageCreateRevisionBeforeCollaboratorsMigration: "AuditDetailPageCreateRevisionBeforeCollaboratorsMigration",
  AuditDetailPageImpossibleToAddRevision: "AuditDetailPageImpossibleToAddRevision",
  AuditDetailPageImpossibleToEditRevision: "AuditDetailPageImpossibleToEditRevision"

};

export default i18n;

