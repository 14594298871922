import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";
import AuditDataService from "./AuditDataService";
import ObjectExt from "../../utils/ObjectExt";
import PubSubService from "../../services/PubSubService";
import AuditDetailsContributorRemoveTable from "./AuditDetailsContributorRemoveTable";
import AuditDetailsContributorsAddTable from "./AuditDetailsContributorsAddTable";
import ToastService from "../../services/ToastService";

const AuditDetailsContributors = (props) => {

    const { t, audit, onUpdateContributors } = props;
    const [contributors, setContributors] = React.useState(props.contributors);
    const [contributorsAvailable, setContributorsAvailable] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);

    useEffect(() => {
        setContributors(props.contributors)
    }, [props.contributors]);

    useEffect(() => {
        AuditDataService.getContributorsAvialable(audit.id, audit.selectedRevisionId, response => {
            setContributorsAvailable(response.data.result)
        }, error => {
            ToastService.ShowToast("Impossible to get contributors list");
        });
    }, []);

    const mergeContributorsUpdates = result => {
        if (!result.length)
            return;

        onUpdateContributors(_.map(contributors, c => {
            const updatedContributor = _.find(result, r => r.id == c.id);
            return updatedContributor || c;
        }));
    };

    const onOpenAddContributorTable = () => {
        setOpenDialog(true);
    };

    const toggleAuthorFlag = contributor => {
    
        // const request = {
        //     auditId: audit.id,
        //     auditRevisionId: audit.selectedRevisionId,
        //     contributorId: contributor.id
        // };

        // AuditDataService.toggleContributorAuthorFlag(request, response => {
        //     if (!ObjectExt.IsNullOrUndefined(response.data.result) && response.data.result.length > 0) {
        //         mergeContributorsUpdates(response.data.result);
        //     } else {
        //         //TODO Toast message response.data.result is null or empty;
        //     }
        // }, error => {
        //     ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
        // });

        let newContributors = [];
        _.forEach(contributors, c => {
            if (c.id === contributor.id) {
                c.isAuthor = !contributor.isAuthor;
            }
            newContributors.push(c);
        });
        setContributors(newContributors);
    };


    const onRemoveRow = contributorIds => {

        const newContributors = contributors.slice();
        const contributorsRemoved = [];
        _.forEach(contributorIds, id => {
            const index = _.findIndex(newContributors, contributor => {
                return contributor.id == id;
            });
            contributorsRemoved.push(newContributors[index]);
            _.remove(newContributors, q => q.id === id);
        });

        const newContributorsAvailable = _.concat(contributorsAvailable.slice(), contributorsRemoved);
        setContributorsAvailable(newContributorsAvailable);
        onUpdateContributors(newContributors);

    };

    const onClose = () => {
        setOpenDialog(false);
    };

    const onAddRow = contributors => {
        const newAudit = Object.assign({}, audit);
        _.forEach(contributors, (cont) => {
            newAudit.contributors = [...newAudit.contributors, cont];
        });
        setContributors(newAudit.contributors);
        onUpdateContributors(newAudit.contributors);
    };

    return (
        <>
            <AuditDetailsContributorsAddTable
                auditId={audit.id}
                auditRevisionId={audit.selectedRevisionId}
                chapter={audit.chapter}
                contributorsAvailable={contributorsAvailable}
                open={openDialog}
                onClose={onClose}
                onAdd={onAddRow}
            />
            <AuditDetailsContributorRemoveTable
                rowsData={contributors}
                onRemoveRow={onRemoveRow}
                showRemoveInclude={true}
                openAddContributorTable={onOpenAddContributorTable}
                onToggleAuthorFlag={toggleAuthorFlag}
            />
        </>
    );


};
export default withTranslation("i18n")(AuditDetailsContributors);
