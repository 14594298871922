import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { withTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import i18n from "../../translations/i18n";
import EmailTemplateDataService from "../EmailTemplate/EmailTemplateDataService";
import ToastService from "../../services/ToastService";
import BaseTable from "../Commons/BaseTable";
import _ from "lodash"
import EmailTemplateDetailsPage from "../EmailTemplate/EmailTemplateDetailsPage";
import ObjectExt from "../../utils/ObjectExt";
import Grid from "@material-ui/core/Grid";
import AuditService from "./AuditDataService";
import MQService from '../../services/MQService';
import BaseDialog, {DialogMaxHeight} from "../Commons/BaseDialog";
import {AuditStatus} from "../../utils/Enums";
import QueueIcon from "./AuditDetailsPage";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  dialogPaper: {
    minHeight: '90%',
    maxHeight: '100%',
    minWidth: '90%',
    maxWidth: '100%'
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  leftActions: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    marginBottom: "1%",
  },
  section: {
    marginTop: "20%"
  }
}));

export const DialogScrollType = {
  paper: "paper",
  body: "body"
};
export const DialogSize = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};

/*
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
* */
const AuditStepperSendEmail = (props, classes) => {
  const initialState = {
    activeStep: 0,
    selectedTemplate: {
      id: 0,
      name: '',
      subject: '',
      body: ''
    },
    openEditTemplate: false,
    emailTemplates: [],
    selectedTemplateRows: [],
    contributors: [],
    selectedContributorsRows: [],
    disableBtn: true
  };


  const { open, onClose, audit, t } = props;
  const styles = useStyles();
  const [activeStep, setActiveStep] = useState(initialState.activeStep);
  const [emailTemplates, setEmailTemplates] = useState(initialState.emailTemplates);
  const [selectedTemplateRows, setSelectedTemplateRows] = useState(initialState.selectedTemplateRows);
  const [selectedTemplate, setSelectedTemplate] = useState(initialState.selectedTemplate);
  const [openEditTemplate, setOpenEditTemplate] = useState(initialState.openEditTemplate);
  const [contributors, setContributors] = useState(initialState.contributors);
  const [selectedContributorsRows, setSelectedContributorsRows] = useState(initialState.selectedContributorsRows);

  const [disableBtn, setDisableBtn] = useState(initialState.disableBtn);
  const steps = [t(i18n.EmailStepperSelectTemplate), t(i18n.EmailStepperSelectDestination), t(i18n.EmailStepperSelectFinish)];


  useEffect(() => {
    EmailTemplateDataService.getAllEmailTemplates(response => {
      setEmailTemplates(response.data.result);
    }, error => {
      ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
    });
  }, []);
  useEffect(() => {
    setContributors(audit.contributors);
  }, [audit]);

  const onTemplateSelected = (row) => {

    if (ObjectExt.ContainsById(selectedTemplateRows, row.id)) {
      setSelectedTemplateRows(initialState.selectedTemplateRows);
      setSelectedTemplate(initialState.selectedTemplate);
      setDisableBtn(true)

    } else {
      setSelectedTemplateRows([row]);
      setSelectedTemplate(row);
      setDisableBtn(false)
    }
  };

  const onContributorSelected = (row) => {

    if (ObjectExt.ContainsById(selectedContributorsRows, row.id)) {
      const newSelectedRows = ObjectExt.RemoveById(selectedContributorsRows, row.id);
      setSelectedContributorsRows(newSelectedRows);
      if (newSelectedRows.length == 0) {
        setDisableBtn(true)
      }
    } else {
      const newSelectedRows = selectedContributorsRows.slice();
      newSelectedRows.push(row);
      setSelectedContributorsRows(newSelectedRows);
      setDisableBtn(false)
    }
  };

  const onSelectAllContributorRows = () => {
    if (selectedContributorsRows.length === contributors.length) {
      setSelectedContributorsRows(initialState.selectedContributorsRows);
      setDisableBtn(true)
    } else {
      setSelectedContributorsRows(contributors);
      setDisableBtn(false)
    }
  };

  const onTemplateDialogClose = () => {
    setOpenEditTemplate(false)
  };

  const onTemplateEdit = () => {
    setSelectedTemplate(selectedTemplate);
    setOpenEditTemplate(true);
  };

  const onTemplateUpdate = (template) => {
    const { id } = template;
    setOpenEditTemplate(false);
    EmailTemplateDataService.editEmailTemplate(template, (response) => {
      const data = response.data.result;
      const newEmailTemplates = emailTemplates.slice();
      const index = _.findIndex(newEmailTemplates, (emailTemplate) => {
        return emailTemplate.id == data.id;
      });
      newEmailTemplates[index] = data;
      setEmailTemplates(newEmailTemplates);
    },
      (error) => {
        ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
      })
  };

  const templateHeaders = [
    t(i18n.Name),
    t(i18n.Subject),
  ];

  const contributorHeaders = [
    t(i18n.Name),
    t(i18n.Email),
    t(i18n.Domain),
    t(i18n.Company),
    t(i18n.Phone),
    t(i18n.Address)
  ];

  const renderTemplateRowData = (data) => {
    return [
      data.name,
      data.subject
    ]
  };


  const renderContributorRowData = (data) => {
    return [
      `${data.name} ${data.lastname}`,
      data.email,
      data.domain,
      data.company,
      data.phone,
      data.address
    ];
  };

  const filterContributorProps = [
    'name',
    'email',
    'domain',
    'company',
    'phone',
    'address'
  ];

  const filterLinkProps = [
    'name',
    'subject'
  ];

  const onFinish = () => {
    setDisableBtn(initialState.disableBtn);
    setSelectedContributorsRows(initialState.selectedContributorsRows);
    setOpenEditTemplate(initialState.openEditTemplate);
    setActiveStep(initialState.activeStep);
    setSelectedTemplateRows(initialState.selectedTemplateRows);
    onClose();

  };

  const stepsContent = (step) => {
    switch (step) {
      case 0:
        return renderEmailTemplateStep();
      case 1:
        return renderContributorStep();
      case 2:
        return renderFinished();
      default:
        return 'Unknown step';
    }
  };

  const renderEmailTemplateStep = () => {
    return (
      <>
        <EmailTemplateDetailsPage
          onClose={onTemplateDialogClose}
          selected={selectedTemplate}
          open={openEditTemplate}
          onUpdate={onTemplateUpdate}>
        </EmailTemplateDetailsPage>

        <BaseTable
          title={t(i18n.AvailableEmailTemplates)}
          rowsData={emailTemplates}
          selectedRows={selectedTemplateRows}
          renderHeaders={templateHeaders}
          renderRow={renderTemplateRowData}
          onEditRow={onTemplateEdit}
          onSelectRow={onTemplateSelected}
          isNotMultiSelect={true}
          actions={{
            hideAdd: true,
            hideEdit: false,
            hideDelete: true
          }}
          filterProps={filterLinkProps}>
        </BaseTable>
      </>
    )
  };

  const renderContributorStep = () => {
    return (
      <>
        <BaseTable
          title={t(i18n.Contributors)}
          rowsData={contributors}
          selectedRows={selectedContributorsRows}
          renderHeaders={contributorHeaders}
          renderRow={renderContributorRowData}
          onSelectRow={onContributorSelected}
          onSelectAllRows={onSelectAllContributorRows}
          actions={{
            hideAdd: true,
            hideEdit: true,
            hideDelete: true
          }}
          filterProps={filterContributorProps}
        >
        </BaseTable>
      </>
    )
  };
  const renderFinished = () => {
    return (
      <>
        <Grid container xs={12} style={{ padding: '10px' }}>
          <Grid container item xs={12} justify="center" >
            <Grid item xs={4} align="center">
              <div className={styles.section}>
                <h3>{t(i18n.EmailTemplateSelected)}</h3>
                <Typography variant="subtitle1" gutterBottom>
                  {selectedTemplate.name}
                </Typography>
              </div>
              <div className={styles.section}>
                <h3>{t(i18n.Contributors)}</h3>
                <Typography variant="subtitle1" gutterBottom>
                  {selectedContributorsRows.length}
                </Typography>
              </div>

            </Grid>
            <Grid item xs={4} align="center">
              <div className={styles.section}>
                <h3>{t(i18n.Audit)}</h3>
                <Typography variant="subtitle1" gutterBottom>
                  {audit.name}
                </Typography>
              </div>
              <div className={styles.section}>
                <h3>{t(i18n.ChapterNumber)}</h3>
                <Typography variant="subtitle1" gutterBottom>
                  {audit.chapter.number}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4} align="center">
              <div className={styles.section}>
                <h3>{t(i18n.ChapterVersion)}</h3>
                <Typography variant="subtitle1" gutterBottom>
                  {audit.chapter.version}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  };

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    switch (activeStep) {
      case 0: {
        if (selectedContributorsRows.length > 0) {
          setDisableBtn(false);
          break;
        }
        setDisableBtn(true);
        break;
      }
      case 1: {
        setDisableBtn(false);
        break;
      }
      case 2: {
        //Finish case
        const sendAudit = Object.assign({}, audit);
        sendAudit.contributors = selectedContributorsRows;
        sendAudit.templateId = selectedTemplate.id;
        sendAudit.comments = [];

        AuditService.sendEmail(sendAudit, response => {
          if (!ObjectExt.IsNullOrUndefined(response.data) && response.data.isSuccess) {
            ToastService.ShowToast(t(i18n.EmailSentSuccessfully));
          }
          else {
            ToastService.ShowToast(t(i18n.ErrorSendingEmail));
          }
          onFinish();
        },
          error => {
            ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
            onFinish();
          });
      }
    }

  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setDisableBtn(false)
  }

  function handleReset() {
    setActiveStep(0);
  }

  const dialogExtraButtons = [
    {
      render: () => {
        return (<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {t(i18n.BackBtn)}
        </Button>)
      }
    },
    {
      render: () => {
        return (<Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
          disabled={disableBtn}
        >
          {activeStep === steps.length - 1 ? t(i18n.FinishBtn) : t(i18n.NextBtn)}
        </Button>)
      }
    }
  ];

  return (
    <BaseDialog
      size={DialogSize.xl}
      onClose={onClose}
      fullWidth={true}
      open={open}
      extraButtons={dialogExtraButtons}
      scroll={DialogScrollType.paper}
      title={t(i18n.EmailStepperTitle)}
      maxHeight={DialogMaxHeight.md}
      showSave={true}
    >
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <Typography className={styles.instructions}>{stepsContent(activeStep)}</Typography>
      </div>
    </BaseDialog>
  );
};
export default withTranslation("i18n")(AuditStepperSendEmail)
