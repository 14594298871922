import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import i18n from '../../translations/i18n';
import CRBLogo from './CRBLogo';
import LoginPageStyles from './LoginPageStyles';
import Grid from '@material-ui/core/Grid';
import { ApiService } from '../../services/ApiService';
import ObjectExt from '../../utils/ObjectExt';

const LoginPage = props => {
  const { t, history } = props;
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [productVersion, setProductVersion] = React.useState('');
  const [productName, setProductName] = React.useState('');

  const classes = LoginPageStyles();

  React.useEffect(() => {
    ApiService.get('api/auth/version', (response) => {
      if (ObjectExt.IsNullOrUndefined(response)) return;
      if (ObjectExt.IsNullOrUndefined(response.data)) return;
      if (ObjectExt.IsNullOrUndefined(response.data.result)) return;
      const { productName, productVersion } = response.data.result;
      setProductName(productName);
      setProductVersion(productVersion);
    }, (error) => {
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <CRBLogo width={200} height={100} />
        <Typography component="h1" variant="h5">
          {t(i18n.Login)}
        </Typography>

        <form className={classes.form} noValidate autoComplete="on">
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={ev => {
              setEmail(ev.target.value);
            }}
            InputLabelProps={{ className: classes.inputLabel }}
          />
          <TextField
            variant="standard"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onKeyPress={(e) => {
              if (e.key !== 'Enter') return;
              AuthService.login(
                email,
                password,
                response => {
                  history.push('/');
                },
                error => { }
              );
            }}
            onChange={ev => {
              setPassword(ev.target.value);
            }}
            InputLabelProps={{ className: classes.inputLabel }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={ev => {
                      ev.preventDefault();
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            fullWidth
            className={classes.singInButton}
            onClick={() => {
              AuthService.login(
                email,
                password,
                response => {
                  history.push('/');
                },
                error => { }
              );
            }}
          >
            {t(i18n.SignIn)}
          </Button>
        </form>

        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: "8px" }}>

          <Grid item>
            <Typography variant="caption">
              {productName}
            </Typography>
          </Grid>

        </Grid>

        <Grid container direction="row" justify="center" alignItems="center" style={{ paddingTop: "8px" }}>

          <Grid item>
            <Typography variant="caption" style={{ paddingRight: "2px" }}>
              {`${t(i18n.Version)}:`}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption">
              {productVersion}
            </Typography>
          </Grid>
        </Grid>

      </div>
    </Container>
  );
};

export default withTranslation('i18n')(withRouter(LoginPage));
