import { Component } from "react";
import React from "react";
import ObjectExt from "../../utils/ObjectExt";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import i18n from "../../translations/i18n";
import {withTranslation} from "react-i18next";

const styles = theme => ({
  button: {
    margin: '5px'
  },
  input: {
    display: 'none',
  },
});

class FileUpload extends Component {

  static propTypes = {
    allowType: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    
    const { classes, text, allowType, inputRef, hidden,t } = this.props;

    return (
      <div>
        <input
          ref={inputRef}
          className={classes.input}
          id={allowType}
          multiple
          type="file"
          onChange={this.upload}
        />
        <label htmlFor={allowType} style={{ display: hidden ? 'none' : 'auto'}}>
          <Button variant="outlined" component="span" className={classes.button}>
              {text}
          </Button>
        </label>
      </div>
    )
  }



  upload = (ev) => {
    const { allowType } = this.props;
    if (ObjectExt.IsNullOrUndefined(allowType) || ev.target.files.length <= 0) {
      return;
    }
    switch (allowType) {
      case 'xml': {
        if (ev.target.files[0].type == 'text/xml') {
          this.props.onUpload(ev.target.files[0]);
        } else {
          alert("insert an xml file");
        }
        break;
      }
      case 'excel': {

        if (ev.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.props.onUpload(ev.target.files[0]);
        } else {
          alert("insert an excel file");
        }
        break;
      }
      case 'zip': {
        debugger;
        if (ev.target.files[0].type == 'application/x-zip-compressed') {
          this.props.onUpload(ev.target.files[0]);
        } else {
          alert("insert a zip file");
        }
        break;
      }

      default: {
        this.props.onUpload(ev.target.files[0]);
        break;
      }
    }
    ev.target.value = '';
  }
}

export default withTranslation("i18n")(withStyles(styles)(FileUpload));
