export const AuditStatus = {
  Preparing: "Preparing",
  Active: "Active",
  Editing: "Editing",
  Revisioning: "Revisioning",
  Closed: "Closed"
};

export const GetNextState = currentState => {
  switch (currentState) {
    case AuditStatus.Preparing: {
      return AuditStatus.Active;
    }
    case AuditStatus.Active: {
      return AuditStatus.Revisioning;
    }
    case AuditStatus.Revisioning: {
      return AuditStatus.Closed;
    }
    case AuditStatus.Closed: {
      return null;
    }
    default:
      return null;
  }
};

export const GetPreviuosState = currentState => {
  switch (currentState) {
    case AuditStatus.Preparing: {
      return null;
    }
    case AuditStatus.Active: {
      return AuditStatus.Preparing;
    }
    case AuditStatus.Revisioning: {
      return AuditStatus.Active;
    }
    case AuditStatus.Closed: {
      return AuditStatus.Revisioning;
    }
    default:
      return null;
  }
};

export const Position = {
  ZERO: 0,
  ZERO080: 80,
  ZERO100: 100,
  ZERO200: 200,
  CENTO: 100000,
  CENTO120: 120000,
  DUECENTO: 200000,
  DUECENTO11: 211000,
  DUECENTO11_110: 211110,
  DUECENTO11_111: 211111,
  DUECENTO11_112: 211112,
  TRECENTO: 300000,
  QUATROCENTO: 400000,
  CINQUECENTO: 500000,
  SEICENTO: 600000,
  SETECENTO: 700000,
  OTTOCENTO: 800000,
  NOVECECENTO: 900000
};

export const CommentStatus = {
  UNREAD: 0,
  READ: 1,
};

export const CommentType = {
  COMMENT: 0,
  ANSWER: 1
};

export const AuditEventType = {
  JOINED: 0,
  COMMENTED: 1
};
