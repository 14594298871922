import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Visit, NPKChapterType } from "../../utils/NpkUtils";
import ObjectExt from "../../utils/ObjectExt";
import ChapterDataService from "../Chapter/ChapterDataService";
import CatalogItem from "./CatalogItem";
import ToastService from "../../services/ToastService";
import BaseLoader from "../Commons/BaseLoader";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useStateWithCallback from "use-state-with-callback";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MessageIcon from "@material-ui/icons/Message";
import Typography from "@material-ui/core/Typography";
import i18n from "../../translations/i18n";
import MQService from "../../services/MQService";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxHeight: 600,
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 650
  },
  expander: {
    cursor: "pointer",
    marginLeft: theme.spacing(5)
  },
  columnLabel: {
    marginRight: "5px",
    color: "black",
    fontWeight: "bold"
  },
  tableCellHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  tableFirstCellHead: {
    width: "20%",
    padding: theme.spacing(2)
  },
  tableFirstCellHeadContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const StyledLoader = styled(CircularProgress)`
  position: fixed;
  left: 50%;
  top: 50%;
`;

const ChapterView = props => {
  const { t, onNodeSelection, parentRef, userId, audit } = props;
  const [chapter, setChapter] = React.useState(null);
  const [textMode, setTextMode] = React.useState("FullText");
  const [sections, setSections] = React.useState([]);
  const [parentH, setParentH] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isExpanding, setIsExpanding] = React.useState(false);
  const [chapterId, setChapterId] = React.useState(null);
  const [filterByComments, setFilterByComments] = React.useState(false);

  const classes = useStyles();

  const updateChapter = () => {
    const { chapterId, userId, audit } = props;
    if (ObjectExt.IsNullOrUndefined(chapterId)) return;
    setSections([]);
    setIsLoaded(false);
    ChapterDataService.getChapterContent(
      chapterId,
      userId,
      audit,
      response => {
        setIsLoaded(true);
        const chapter = response.data.result;
        // setOriginalNodes(chapter && chapter.nodes ? chapter.nodes : []);
        const expandableSections =
          chapter && chapter.nodes ? chapter.nodes : [];
        expandableSections.forEach(section => {
          Visit(section, params => {
            params.current.expanded = false;
          });
        });
        setSections(expandableSections);
      },
      error => {
        ToastService.ShowToast("Error loading the chapter's content");
        setIsLoaded(true);
      }
    );
  };

  React.useEffect(() => {
    if (ObjectExt.IsNullOrUndefined(props.chapterId)) return;
    updateChapter();
  }, [props.chapterId]);

  React.useEffect(() => {
    if (ObjectExt.IsNullOrUndefined(props.parentRef)) return;
    if (ObjectExt.IsNullOrUndefined(props.parentRef.current)) return;
    setParentH(props.parentRef.current.clientHeight);
  }, [props.parentRef]);

  React.useEffect(() => {
    if (!isLoaded && isExpanding) {
      setIsLoaded(true);
      setIsExpanding(false);
    }
    //console.log("Component did update");
  });

  const onCollapseAll = () => {
    let node = null;

    sections.forEach(section => {
      section.expanded = false;

      Visit(section, params => {
        params.current.expanded = false;
      });
    });

    setSections([...sections]);
  };

  const onExpandAll = () => {
    let node = null;
    setIsLoaded(false);
    setIsExpanding(true);
    sections.forEach(section => {
      section.expanded = true;

      Visit(section, params => {
        params.current.expanded = true;
      });
    });

    setSections([...sections]);
  };

  const onExpandAllSection = (section) => {
    let node = null;
    setIsLoaded(false);
    setIsExpanding(true);
    sections.forEach(s => {
      if (s.id !== section.id) return;
      s.expanded = true;
      Visit(s, params => {
        params.current.expanded = true;
      });
    });

    setSections([...sections]);
  };



  const onExpandedToggle = ({ id, expanded }) => {
    let node = null;

    sections.forEach(section => {
      if (section.positionID === id)
        node = section;

      Visit(section, params => {
        if (params.current.positionID === id)
          node = params.current;
      });
    });

    if (node !== null)
      node.expanded = expanded;

    setSections([...sections]);
  };
  const onFilterByCommentsToggle = () => {
    setFilterByComments(!filterByComments);
  };

  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.tableFirstCellHead}>
              <span>
                <div className={classes.tableFirstCellHeadContent}>
                  <Tooltip title={t(i18n.ChapterViewSwitchTooltip)}>
                    <Switch
                      checked={filterByComments}
                      onChange={onFilterByCommentsToggle}
                      color="primary"
                    />
                  </Tooltip>
                  <ExpandLessIcon
                    color="primary"
                    fontSize="small"
                    onClick={onCollapseAll}
                    className={classes.expander}
                  />
                  {/* <ExpandMoreIcon
                    color="primary"
                    fontSize="small"
                    onClick={onExpandAll}
                    className={classes.expander} /> */}
                </div>
              </span>
            </TableCell>
            <TableCell align="left" style={{ width: '60%' }}>
              <Typography className={classes.columnLabel} variant="subtitle1">
                {t(i18n.ChapterViewContentColumnTitle)}
              </Typography>
            </TableCell>
            <TableCell align="left" style={{ width: '10%' }}>
              <Typography className={classes.columnLabel} variant="subtitle1">
                {t(i18n.ChapterViewUnitsColumnTitle)}
              </Typography>
            </TableCell>
            <TableCell align="left" style={{ width: '10%' }}>
              <Typography className={classes.columnLabel} variant="subtitle1">
                {t(i18n.ChapterViewDrawingColumnTitle)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {isLoaded ? (
              sections.map((item, i) => {
                return (
                  <CatalogItem
                    filterByComments={filterByComments}
                    key={item.number}
                    section={item}
                    textMode={textMode}
                    userId={userId}
                    audit={audit}
                    onBadgeClick={onNodeSelection}
                    onExpandedToggle={onExpandedToggle}
                  />
                );
              })
            ) : (
                <StyledLoader>
                  <BaseLoader show={!isLoaded} />
                </StyledLoader>
              )}
          </TableBody>
        </Table>
      </div>
    </>);
};

export default withTranslation("i18n")(withRouter(ChapterView));
