import { withTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../../translations/i18n";
import React from "react";
import BaseDialog, { DialogScrollType, DialogSize } from './BaseDialog';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        boxShadow: 'none'
    },
    buttonEndIcon: {
        marginLeft: theme.spacing(1),
    }
}));

export let ConfirmSaveDialogService = {};


export const ConfirmSaveDialog = withTranslation("i18n")((props) => {

    const { title, elements, t } = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [contentDialog, setContentDialog] = useState('');
    ConfirmSaveDialogService.open = (textContent) => {
        setOpen(true);
        setContentDialog(t(i18n.ConfirmDialogSaveContent))
    };

    const onSaveClick = () => {
        ConfirmSaveDialogService.onAccept();
        setOpen(false);
    }

    const dialogButtons = [
        {
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    onClick={onSaveClick}
                    color="primary">
                    {t(i18n.OK)}
                    <CheckIcon className={classes.buttonEndIcon} />
                </Button>)
            }
        }
    ];
    return (
        <BaseDialog
            size={DialogSize.md}
            onClose={() => {
                ConfirmSaveDialogService.onClose();
                setOpen(false);
            }}
            fullWidth={false}
            open={open}
            scroll={DialogScrollType.body}
            title={title}
            contentText={contentDialog}
            buttons={dialogButtons}
        >
        </BaseDialog>
    )
});
