import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import i18n, { Language } from '../../translations/i18n';


const NavLanguageMenu = {
    id: "language-menu-appbar",
    render: (anchorEl, handleClose, open, t) => {
        
        return (
            <Menu
                id="language-menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={NavLanguageMenu.onItalian}>{t(i18n.it)}</MenuItem>
                <MenuItem onClick={NavLanguageMenu.onDeutch}>{t(i18n.de)}</MenuItem>
            </Menu>);
    },
    onItalian: () => {},
    onDeutch: () => {}
}
export default NavLanguageMenu;