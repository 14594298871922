import IconButton from '@material-ui/core/IconButton';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from "react";
import PubSubService from '../../services/PubSubService';
import ToasterStyles, { variantIcon } from './ToasterStyles';
import ObjectExt from '../../utils/ObjectExt';
import makeStyles from "@material-ui/core/styles/makeStyles";




const Toaster = () => {

  const classes = ToasterStyles();

  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState("");

  React.useEffect(() => {
    PubSubService.SubscribeTo(PubSubService.KeyShowToastMessage, (name, content) => {
      if (ObjectExt.IsNullOrUndefined(content)) {
        return;
      }
      if (ObjectExt.IsNotStringOrEmpty(content)) {
        return;
      }
      setContent(content);
      setOpen(true);
    })
    return () => {
      PubSubService.UnSubscribeFrom(PubSubService.KeyShowToastMessage)
    }
  }, []);

  const handleClose = () => {
    PubSubService.PublishAt(PubSubService.KeyCloseToastMessage);
    setOpen(false);
    setContent("");
  }

  return (
    <Snackbar
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.snackBar}
        message={content}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon/>
          </IconButton>]}
      />

    </Snackbar>
  );
}
export default Toaster;
