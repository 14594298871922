import { TableCell } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MailIcon from "@material-ui/icons/Mail";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import cx from "classnames";
import _ from "lodash";
import numeral from "numeral";
import React from "react";
import { Comments } from "../../utils/Mock";
import {
  CatalogCompareIcon,
  CatalogInfoIcon,
  CatalogInfoPictureIcon,
  CatalogLeafIcon
} from "./CatalogInteractors";
import CommentIcon from "@material-ui/icons/Comment";
import { withTranslation } from "react-i18next";

import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import TeastService from "../../services/ToastService";
import CommentDataService from "../Comment/CommentDataService";
import PubSubService from "../../services/PubSubService";
import { CommentBadge } from "./CommentBadge";
import { formatNpkContent } from "../../utils/NpkUtils";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  badge: {
    cursor: "pointer"
  },
  expander: {
    cursor: "pointer"
  },
  padding: {
    padding: theme.spacing(0, 2)
  }
}));

export const CatalogUnitColumn = props => {
  const { t, section, onBadgeClick, userId, audit } = props;

  const [commentNumber, setCommentNumber] = React.useState(null);
  const [hasReplies, setHasReplies] = React.useState(false);

  const [unitPositionId, setUnitPositionId] = React.useState(null);

  const classes = useStyles();

  const updateCommentsNumber = positionId => {
    CommentDataService.getCommentsByPosition(
      positionId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          TeastService.ShowToast(response.data.message);
          return;
        }
        if (response.data.result.length > 0) {
          _.forEach(response.data.result, (r) => {
            setHasReplies(!ObjectExt.IsNullOrUndefined(r.replies) && r.replies.length > 0);
          });
        }
        setCommentNumber(response.data.result.length);
      },
      error => {
        TeastService.ShowToast(error);
      }
    );
  };

  React.useEffect(() => {
    const positionId =
      section && section.unitPositionID && section.unitPositionID !== ""
        ? section.unitPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    setUnitPositionId(positionId);
    PubSubService.SubscribeTo(
      PubSubService.KeyCommentAddedMessage,
      (name, positionId) => {
        if (positionId !== section.unitPositionID) return;
        updateCommentsNumber(section.unitPositionID);
      }
    );
  }, []);

  React.useEffect(() => {
    const positionId =
      section && section.unitPositionID && section.unitPositionID !== ""
        ? section.unitPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    updateCommentsNumber(positionId);
  }, [props.section]);

  // React.useEffect(() => {
  //     debugger;
  //     const { userId, audit } = props;

  //     if (ObjectExt.IsStringEmpty(section.unit)) return;

  //     setUnitPositionId(section.unitPositionID);
  //     PubSubService.SubscribeTo(PubSubService.KeyCommentAddedMessage, (name, positionId) => {
  //         if (positionId !== unitPositionId) return;
  //         debugger;
  //         let commentNumberCount = commentNumber;
  //         setCommentNumber(++commentNumberCount);
  //     });
  //     if (ObjectExt.IsNullOrUndefined(section.unitCommentsCount)) return;
  //     setCommentNumber(section.unitCommentsCount);
  // }, []);

  // const CommentBadge = () => {
  //   if (commentNumber && commentNumber > 0)
  //     return (
  //       <Badge
  //         className={classes.badge}
  //         badgeContent={commentNumber}
  //         color="primary"
  //         onClick={() => {
  //           onBadgeClick(section, unitPositionId);
  //         }}
  //       >
  //         <CommentIcon className={classes.expander} color="secondary" />
  //       </Badge>
  //     );

  //   return (
  //     <Badge
  //       className={classes.badge}
  //       badgeContent={0}
  //       color="secondary"
  //       onClick={() => {
  //         onBadgeClick(section, unitPositionId);
  //       }}
  //     >
  //       <CommentIcon className={classes.expander} color="secondary" />
  //     </Badge>
  //   );
  // };

  const contextToSource = () => {
    return {
      id: unitPositionId,
      typeLabel: t(i18n.CommentTypeUnit),
      label: formatNpkContent(section, "FullText"),
      itemNumber: section.number,
      unit: section.unit
    };
  };

  return (
    <TableCell align="center" style={{ width: "10%", verticalAlign: "top" }}>
      {section.unit ? (
        <>
          <CommentBadge
            hasReplies={hasReplies}
            count={commentNumber}
            onClick={() => onBadgeClick(contextToSource())}
          />
          <p>{section.unit}</p>
        </>
      ) : null}
    </TableCell>
  );
};

export default withTranslation("i18n")(CatalogUnitColumn);
