import ClassicEditor from "ckeditor5-font-custombuild";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SendIcon from "@material-ui/icons/Send";
import ShareIcon from "@material-ui/icons/Share";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CommentDataService from "./CommentDataService";
import PubSubService from "../../services/PubSubService";
import TeastService from "../../services/ToastService";
import i18n from "../../translations/i18n";
import { CommentStatus, CommentType } from "../../utils/Enums";
import ObjectExt from "../../utils/ObjectExt";
import CustomUploadAdapter from "../../utils/UploaderAdapter";
import blue from "@material-ui/core/colors/blue";
import Switch from "@material-ui/core/Switch";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FileUpload from "../FileUpload/FileUpload";
import EditIcon from "@material-ui/icons/Edit";
import ReplyIcon from "@material-ui/icons/Reply";
import Tooltip from "@material-ui/core/Tooltip";
import BaseDialog, {
  DialogScrollType,
  DialogSize,
  DialogMaxHeight,
  DialogActionResult
} from "../Commons/BaseDialog";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CommentEditor from "./CommentEditor";
import { formatNpkItemNumber } from "../../utils/NpkUtils";

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    maxHeight: 500
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  editor: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: "transparent",
    overflowX: "auto"
  },
  listRoot: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: "transparent",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    position: "relative"
  },
  inline: {
    display: "inline"
  },
  cardActions: {
    fontSize: "10px"
  },
  listItem: {
    backgroundColor: "white"
  },
  card: {
    width: "80%",
    backgroundColor: theme.palette.primary.light
  },
  cardResponse: {
    width: "80%",
    marginLeft: "20%",
    backgroundColor: theme.palette.secondary.light
  },
  media: {
    height: 140
  },
  avatar: {
    backgroundColor: "green"
  },
  card: {
    backgroundColor: "transparent",
    border: "1px black solid"
  }
}));

const CommentSingleCreator = props => {
  const {
    onCopyContentFromPosition,
    onUploadAttachment,
    onClose,
    comment,
    userId,
    audit,
    source,
    open,
    t
  } = props;

  const [commentSingleFiles, setCommentSingleFiles] = React.useState([]);
  const [commentSingle, setCommentSingle] = React.useState({});

  React.useEffect(() => {
    if (ObjectExt.IsNullOrUndefined(props.comment)) return;
    setCommentSingle(props.comment);
  }, [props.comment]);

  const onSendComment = (comment, onSent, onSentError) => {
    if (ObjectExt.IsNullOrUndefined(source.id)) return;

    CommentDataService.addComment(
      comment,
      response => {
        PubSubService.PublishAt(
          PubSubService.KeyCommentAddedMessage,
          comment.positionId
        );
        onClose(DialogActionResult.Succeeded);
        if(ObjectExt.IsDefinedFunc(onSent)) onSent();
        
      },
      error => {
        onClose(DialogActionResult.Failed);
        if(ObjectExt.IsDefinedFunc(onSentError)) onSentError();
      }
    );
  };

  return (
    <CommentEditor
      title={"Comment editor"}
      position={source}
      onSendComment={onSendComment}
      onUploadAttachment={attachment => {
        const model = new FormData();
        model.append("file", attachment.file);
        commentSingleFiles.push(model);
        setCommentSingleFiles(commentSingleFiles);
      }}
      open={open}
      onClose={onClose}
      comment={commentSingle}
    />
    // <BaseDialog
    //   size={DialogSize.md}
    //   onClose={onClose}
    //   fullWidth
    //   open={open}
    //   scroll={DialogScrollType.paper}
    //   title={"Comment editor"}
    //   contentText={`Add comment to position ${
    //     source && source.itemNumber
    //       ? formatNpkItemNumber(source.itemNumber)
    //       : ""
    //   }`}
    //   maxHeight={DialogMaxHeight.md}
    // >
    //   <CommentEditor
    //     position={source}
    //     onSendComment={onSendComment}
    //     onUploadAttachment={attachment => {
    //       const model = new FormData();
    //       model.append("file", attachment.file);
    //       commentSingleFiles.push(model);
    //       setCommentSingleFiles(commentSingleFiles);
    //     }}
    //     comment={commentSingle}
    //   />
    // </BaseDialog>
  );
};
CommentSingleCreator.propTypes = {
  onCopyContentFromPosition: PropTypes.func,
  onClose: PropTypes.func,
  onUploadAttachment: PropTypes.func,
  comment: PropTypes.object,
  open: PropTypes.bool,
  contentText: PropTypes.string
};
export default withTranslation("i18n")(withRouter(CommentSingleCreator));
