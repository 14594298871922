import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React from "react";
import { withTranslation } from "react-i18next";
import CommentDataService from "../Comment/CommentDataService";
import PubSubService from "../../services/PubSubService";
import ToastService from "../../services/ToastService";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChatIcon from "@material-ui/icons/Chat";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(4)
  },
  chip: {
    border: "0px",
    margin: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  badge: {
    cursor: "pointer"
  }
}));

const ChapterAttachmentChip = props => {
  const { onChipClick, chip, userId, audit } = props;
  const [commentNumber, setCommentNumber] = React.useState(null);

  const classes = useStyles();

  React.useEffect(() => {
    PubSubService.SubscribeTo(
      PubSubService.KeyCommentAddedMessage,
      (name, positionId) => {
        if (positionId !== chip.id) return;
        updateCommentsNumber(positionId);
      }
    );
  }, []);

  React.useEffect(() => {
    updateCommentsNumber(props.chip.id);
  }, [props.chip]);

  const updateCommentsNumber = positionId => {
    CommentDataService.getCommentsByPosition(
      positionId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.data.message);
          return;
        }
        setCommentNumber(response.data.result.length);
      },
      error => {
        ToastService.ShowToast(error);
      }
    );
  };
  const linkRef = React.createRef();

  const downloadAttachment = () => {
    const fileLinkEL = linkRef.current;
    if (!fileLinkEL) return;
    fileLinkEL.click();
  };

  return (
    <Grid item className={classes.root}>
      <Badge
        badgeContent={commentNumber}
        max={99}
        color="primary"
        onClick={onChipClick}
        className={classes.badge}
      >
        <a ref={linkRef} href={chip.url} target="_blank" />
        <ChatIcon className={classes.expander} color="primary" />
      </Badge>
      <Chip
        variant="outlined"
        label={chip.name}
        size="small"
        key={chip.id}
        clickable
        onClick={() => {
          downloadAttachment();
        }}
        onDelete={() => {
          downloadAttachment();
        }}
        className={classes.chip}
        deleteIcon={<GetAppIcon color="primary" />}
      />
    </Grid>
  );
};

export default withTranslation("i18n")(ChapterAttachmentChip);
