
import CKEditor from "@ckeditor/ckeditor5-react";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SendIcon from '@material-ui/icons/Send';
import ClassicEditor from "ckeditor5-font-custombuild";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ToastService from "../../services/ToastService";
import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import CustomUploadAdapter from "../../utils/UploaderAdapter";
import BaseDialog, { DialogMaxHeight, DialogScrollType, DialogSize } from '../Commons/BaseDialog';
import BaseLoader from "../Commons/BaseLoader";
import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        boxShadow: 'none'
    },
    buttonEndIcon: {
        marginLeft: theme.spacing(1)
    }
}));

const StyledLoader = styled(CircularProgress)`
  position: fixed;
  left: 50%;
  top:50%;
`;

const CommentEditor = (props) => {

    const { title, onSendComment, onClose, open, position, comment, t } = props;


    const [writingComment, setWritingComment] = React.useState({});
    const [writingCommentFiles, setWritingCommentFiles] = React.useState([]);

    const [isOpen, setIsOpen] = React.useState(false);

    const [disabledSendButton, setDisabledSendButton] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const commentFileUploadRef = React.createRef();

    const classes = useStyles();

    React.useEffect(() => {

        const commentAttachment = props.comment && props.comment.attachments ? props.comment.attachments : [];
        setWritingCommentFiles(commentAttachment);

        const wcomment = props.comment ? props.comment : {};
        setWritingComment(wcomment);

    }, [props.comment]);

    React.useEffect(() => {
        setIsOpen(props.open);
    }, [props.open]);


    const copyContentFromPosition = () => {
        debugger;
        if (ObjectExt.IsNullOrUndefined(position) || ObjectExt.IsNotStringOrEmpty(position.label)) {
            ToastService.ShowToast(t(i18n.PositionContentNotAvailableToCopy));
            return;
        };

        let newWritingComment = { ...writingComment };
        newWritingComment.content += position.label;
        setWritingComment(newWritingComment);
    }
    const renderAttachments = () => {

        if (!writingCommentFiles || writingCommentFiles.length === 0) return null;

        return (
            <Grid item container direction="column" justify="center" alignItems="flex-start">
                {
                    writingCommentFiles.map(attchment => {
                        return (
                            <Grid item>
                                {attchment.name}
                            </Grid>
                        );
                    })
                }
            </Grid>
        );
    }
    const dialogExtraButtons = [
        {
            render: () => {
                return (<Button variant="contained"

                    className={classes.button}
                    onClick={copyContentFromPosition}
                    color="primary">
                    {t(i18n.CopyPositionContentButtonLabel)}
                    <FileCopyIcon className={classes.buttonEndIcon} />
                </Button>)
            }
        }
    ]

    const dialogButtons = [
        {
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    disabled={disabledSendButton}
                    onClick={() => {
                        setDisabledSendButton(true);
                        setIsLoading(true);
                        onSendComment(writingComment, () => {
                            setDisabledSendButton(false);
                            setIsLoading(false);
                            setIsOpen(false);
                        }, () => {
                            setDisabledSendButton(false);
                            setIsLoading(false);
                            setIsOpen(false);
                        });
                    }}
                    color="primary">
                    {t(i18n.SendMessageButtonLabel)}
                    <SendIcon className={classes.buttonEndIcon} />
                </Button>)
            }
        },
    ];


    const renderEditor = () => {

        if (isLoading) {
            return (<StyledLoader>
                <BaseLoader show={isLoading} />
            </StyledLoader>)
        }
        return (<CKEditor
            styles={{ height: "80%", width: "100%", overflow: 'auto' }}
            editor={ClassicEditor}
            data={writingComment.content}
            onInit={editor => {
                editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                    return new CustomUploadAdapter(loader);
                };
                editor.setData(writingComment.content);
            }}
            onChange={(event, editor) => {
                const editorData = editor.getData();
                let newWriting = { ...writingComment };
                newWriting.content = editor.getData();
                setWritingComment(newWriting)
            }}
        />)
    }

    return (

        <BaseDialog
            size={DialogSize.md}
            onClose={onClose}
            fullWidth
            open={isOpen}
            scroll={DialogScrollType.paper}
            title={title}
            contentText={`${position && position.ItemCpnCode ? position.ItemCpnCode : ""}`}
            maxHeight={DialogMaxHeight.md}
            extraButtons={dialogExtraButtons}
            buttons={dialogButtons}
        >
            <div style={{ height: "90%", width: "100%" }}>
                {
                    renderEditor()
                }
            </div>
        </BaseDialog>
    );
}
export default withTranslation("i18n")(withRouter(CommentEditor));
