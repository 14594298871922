import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CommentDataService from "./CommentDataService";
import TeastService from "../../services/ToastService";
import { CommentStatus, CommentType } from "../../utils/Enums";
import ObjectExt from "../../utils/ObjectExt";
import { DialogActionResult } from "../Commons/BaseDialog";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CommentReplyEditor from "./CommentReplyEditor";
import CommentReplyCreator from "./CommentReplyCreator";
import CommentSingleEditor from "./CommentSingleEditor";
import CommentSingleCreator from "./CommentSingleCreator";
import Comment from "./Comment";
import List from "@material-ui/core/List";
import BaseLoader from "../Commons/BaseLoader";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CommentHeader } from "./CommentHeader";
import i18n from "../../translations/i18n";

const StyledLoader = styled(CircularProgress)`
  position: fixed;
  left: 80%;
  top: 50%;
`;

const useStyles = makeStyles(theme => ({
  container: {
    padding: 10,
    maxHeight: 500
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  header: {
    flex: "0 0 auto"
  },
  table: {
    minWidth: 650
  },
  editor: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: "transparent",
    overflowX: "auto"
  },
  listRoot: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: "transparent",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    position: "relative"
  },
  inline: {
    display: "inline"
  },
  cardActions: {
    fontSize: "10px"
  },
  listItem: {
    backgroundColor: "white"
  },
  card: {
    width: "80%",
    backgroundColor: theme.palette.primary.light,
    border: "1px black solid"
  },
  cardResponse: {
    width: "80%",
    marginLeft: "20%",
    backgroundColor: theme.palette.secondary.light
  },
  media: {
    height: 140
  },
  avatar: {
    backgroundColor: "green"
  },
  rootList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

const getDefaultComment = props => {
  const { source, userId, audit } = props;
  const { id: sourceId } = source;

  return {
    content: "",
    state: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    positionId: sourceId,
    userId: userId,
    auditId: audit.id,
    auditRevisionId: audit.selectedRevisionId
  };
};
const getDefaultCommentReply = (props, comment) => {
  const { user, userId } = props;
  return {
    content: `<em><strong>${comment.content}</strong><em><p>&nbsp;${props.t(i18n.WriteYourAnswer)}</p>`,
    state: CommentStatus.UNREAD,
    isPublic: false,
    commentId: comment.id,
    userId: userId,
    isAuthor: user.isAuthor
  };
};

const CommentView = props => {
  const { source, t, userId, audit, user, onHide, parentRefEl } = props;
  const { id: sourceId } = source;

  const [comments, setComments] = React.useState([]);
  const [currentComment, setCurrentComment] = React.useState(null);
  const [writingCommentFiles, setWritingCommentFiles] = React.useState([]);
  const [showSingleCommentEditor, setShowCommentSingleEditor] = React.useState(
    false
  );
  const [
    showSingleCommentCreator,
    setShowCommentSingleCreator
  ] = React.useState(false);
  const [showCommentReplyEditor, setShowCommentReplyEditor] = React.useState(
    false
  );
  const [showCommentReplyCreator, setShowCommentReplyCreator] = React.useState(
    false
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    updateCurrentComment(props);
  }, [props]);

  const updateCurrentComment = props => {
    //debugger;
    const { source, userId, auditId, audit } = props;
    const { id: sourceId } = source;

    let newCurrentComment = { ...currentComment };
    newCurrentComment.positionId = sourceId;
    newCurrentComment.userId = userId;
    newCurrentComment.auditId = audit.id;
    newCurrentComment.auditRevisionId = audit.selectedRevisionId;
    // return newCurrentComment;
    setCurrentComment(newCurrentComment);
  };

  const uploadAttachment = attachment => {
    //debugger;
    const model = new FormData();
    model.append("file", attachment.file);
    writingCommentFiles.push(model);
    setWritingCommentFiles(writingCommentFiles);
  };

  const onDeleteComment = ()=>{
    CommentDataService.getCommentsByPosition(
      sourceId,
      userId,
      audit,
      response => {
        setComments(response.data.result);
        setIsLoading(false);
      },
      error => {
        //TODO: Show toast error.
        setIsLoading(false);
      })
  };

  React.useEffect(() => {
    if (ObjectExt.IsNullOrUndefined(sourceId)) return;

    setIsLoading(true);
    CommentDataService.getCommentsByPosition(
      sourceId,
      userId,
      audit,
      response => {
        setComments(response.data.result);
        setIsLoading(false);
      },
      error => {
        //TODO: Show toast error.
        setIsLoading(false);
      }
    );
  }, [sourceId]);

  React.useEffect(() => {
    // setWritingComment(updateDefaultComment(props, writingComment));
  }, [props.userId, props.auditId]);

  const onTogglePrivacyFlag = (comment, onPrivacyChange, onPrivacyChangeError) => {
    //setIsLoading(true);
    const isReply = !ObjectExt.IsNullOrUndefined(comment.commentId);
    comment.isPublic = !comment.isPublic;

    if (isReply) {
      CommentDataService.editReply(
        comment,
        response => {
          CommentDataService.getCommentsByPosition(
            sourceId,
            userId,
            audit,
            response => {
               //setIsLoading(false);
              if (!response.data.isSuccess) {
                TeastService.ShowToast(response.data.message);
                return;
              }
              if(ObjectExt.IsDefinedFunc(onPrivacyChange)) onPrivacyChange();
              // setComments(response.data.result);
            },
            error => {
              
              TeastService.ShowToast("Error loading Comments");
            }
          );
        },
        error => {
           //setIsLoading(false);
          if(ObjectExt.IsDefinedFunc(onPrivacyChangeError)) onPrivacyChangeError();
        }
      );
    } else {
      CommentDataService.editComment(
        comment,
        response => {
          CommentDataService.getCommentsByPosition(
            sourceId,
            userId,
            audit,
            response => {
               //setIsLoading(false);
              if (!response.data.isSuccess) {
                TeastService.ShowToast(response.data.message);
                return;
              }
              if(ObjectExt.IsDefinedFunc(onPrivacyChange)) onPrivacyChange();
              // setComments(response.data.result);
            },
            error => {
              //setIsLoading(false);
              if(ObjectExt.IsDefinedFunc(onPrivacyChangeError)) onPrivacyChangeError();
              TeastService.ShowToast("Error loading comments");
            }
          );
        },
        error => {
          //setIsLoading(false);
          if(ObjectExt.IsDefinedFunc(onPrivacyChangeError)) onPrivacyChangeError();
        }
      );
    }
  };
  const onAttachmentUpdate = commentUpdated => {
    //TODO update comment here instead of go to back-end
    CommentDataService.getCommentsByPosition(
      sourceId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          TeastService.ShowToast(response.data.message);
          return;
        }
        setComments(response.data.result);
      },
      error => {
        TeastService.ShowToast("Error loading comments");
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        flexShrink: "1",
        flexDirection: "column",
        height: "98%",
        width: "100%"
      }}
    >
      <CommentHeader
        className={classes.header}
        source={source}
        onHide={onHide}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          flexShrink: "1",
          height: "90%",
          width: "100%",
          overflow: "auto",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        {!isLoading ? (
          <List className={classes.rootList}>
            {comments.map(comment => {
              return (
                <Comment
                  loggedUser={user}
                  comment={comment}
                  onTogglePrivacyFlag={onTogglePrivacyFlag}
                  onDelete={onDeleteComment}
                  onEdit={(commentToEdit, isReply) => {
                    if (!ObjectExt.IsNullOrUndefined(isReply) && isReply) {
                      setCurrentComment(commentToEdit);
                      setShowCommentReplyEditor(true);
                    } else {
                      setCurrentComment(commentToEdit);
                      setShowCommentSingleEditor(true);
                    }
                  }}
                  onReply={commentToEdit => {
                    setCurrentComment(getDefaultCommentReply(props, commentToEdit));
                    setShowCommentReplyCreator(true);
                  }}
                  onAttachmentUpdate={commentAttachment => {
                    onAttachmentUpdate();
                  }}
                />
              );
            })}
          </List>
        ) : (
            <StyledLoader>
              <BaseLoader show={!isLoading} />
            </StyledLoader>
          )}
      </div>
      <Grid
        content
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Grid item>
          <Fab
            size="small"
            onClick={() => {
              setCurrentComment(getDefaultComment(props));
              setShowCommentSingleCreator(true);
            }}
            color="primary"
            aria-label="add"
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>

      <CommentSingleCreator
        source={source}
        audit={audit}
        onClose={result => {
          if (result === DialogActionResult.Succeeded) {
            CommentDataService.getCommentsByPosition(
              sourceId,
              userId,
              audit,
              response => {
                if (!response.data.isSuccess) {
                  TeastService.ShowToast(response.data.message);
                  return;
                }
                setComments(response.data.result);
                setShowCommentSingleCreator(false);
              },
              error => {
                TeastService.ShowToast("Impossible to load comments");
                setShowCommentSingleCreator(false);
              }
            );
          } else {
            setShowCommentSingleCreator(false);
          }
        }}
        onUploadAttachment={uploadAttachment}
        comment={currentComment}
        open={showSingleCommentCreator}
        contentText={""}
      />
      <CommentSingleEditor
        source={source}
        audit={audit}
        onClose={result => {
          if (result === DialogActionResult.Succeeded) {
            CommentDataService.getCommentsByPosition(
              sourceId,
              userId,
              audit,
              response => {
                if (!response.data.isSuccess) {
                  TeastService.ShowToast(response.data.message);
                  return;
                }
                setComments(response.data.result);
                setShowCommentSingleEditor(false);
              },
              error => {
                TeastService.ShowToast("Impossible to load comments");
                setShowCommentSingleEditor(false);
              }
            );
          } else {
            setShowCommentSingleEditor(false);
          }
        }}
        onUploadAttachment={uploadAttachment}
        comment={currentComment}
        open={showSingleCommentEditor}
        contentText={""}
      />
      <CommentReplyCreator
        source={source}
        onClose={result => {
          //debugger;
          if (result === DialogActionResult.Succeeded) {
            CommentDataService.getCommentsByPosition(
              sourceId,
              userId,
              audit,
              response => {
                if (!response.data.isSuccess) {
                  TeastService.ShowToast(response.data.message);
                  return;
                }
                setComments(response.data.result);
                setShowCommentReplyCreator(false);
              },
              error => {
                TeastService.ShowToast("Impossible to load comments");
                setShowCommentReplyCreator(false);
              }
            );
          } else {
            setShowCommentReplyCreator(false);
          }
        }}
        onUploadAttachment={uploadAttachment}
        comment={currentComment}
        open={showCommentReplyCreator}
        contentText={""}
      />

      <CommentReplyEditor
        source={source}
        onClose={result => {
          //debugger;
          if (result === DialogActionResult.Succeeded) {
            CommentDataService.getCommentsByPosition(
              sourceId,
              userId,
              audit,
              response => {
                if (!response.data.isSuccess) {
                  TeastService.ShowToast(response.data.message);
                  return;
                }
                setComments(response.data.result);
                setShowCommentReplyEditor(false);
              },
              error => {
                TeastService.ShowToast("Impossible to load comments");
                setShowCommentReplyEditor(false);
              }
            );
          } else {
            setShowCommentReplyEditor(false);
          }
        }}
        onUploadAttachment={uploadAttachment}
        comment={currentComment}
        open={showCommentReplyEditor}
        contentText={""}
      />
    </div>
  );
};

CommentView.propTypes = {
  source: PropTypes.object,
  onHide: PropTypes.func,
};

export default withTranslation("i18n")(withRouter(CommentView));
