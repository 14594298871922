import { ApiEndPoints } from "../../settings/ApiSettings";
import { ApiService } from '../../services/ApiService';

const AuditService = {
  getAllAudits: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.AUDIT, response => onResponse(response), error => onError(error));
  },
  getAuditByRevision: (id, rev, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.AUDIT}/${id}/revision/${rev}`, response => onResponse(response), error => onError(error));
  },
  getAuditsCount: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.AUDITS_COUNT, response => onResponse(response), error => onError(error));
  },
  addAudit: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT, data, response => onResponse(response), error => onError(error));
  },
  editAudit: (data, onResponse, onError) => {
    ApiService.put(`${ApiEndPoints.AUDIT}/${data.id}`, data, response => onResponse(response), error => onError(error));
  },
  deleteAudits: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_DELETE, ids, response => onResponse(response), error => onError(error));
  },
  getChapter: (id, rev, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.AUDIT}/${id}/revision/${rev}/chapter`, response => onResponse(response), error => onError(error));
  },
  addChapter: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_ADD_CHAPTER, data, response => onResponse(response), error => onError(error));
  },
  getContributorsAvialable: (id, rev, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_CONTRIBUTORS_AVIALABLE, { auditId: id, auditRevisionId: rev }, response => onResponse(response), error => onError(error));
  },
  addContributorToAudit: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_ADD_CONTRIBUTOR, data, response => onResponse(response), error => onError(error));
  },
  removeContributorsFromAudit: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_REMOVE_CONTRIBUTOR, data, response => onResponse(response), error => onError(error));
  },
  toggleContributorAuthorFlag: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_TOGGLE_CONTRIBUTOR_AUTHOR_FLAG, data, response => onResponse(response), error => onError(error));
  },
  sendTestEmail: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_SEND_TEST_EMAIL, data, response => onResponse(response), error => onError(error));
  },
  sendEmail: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_SEND_EMAIL, data, response => onResponse(response), error => onError(error));
  },
  editRevision: (data, onResponse, onError) => {
    ApiService.post(`${ApiEndPoints.AUDIT_EDIT_REVISION}`, data, response => onResponse(response), error => onError(error));
  },
  addRevision: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_ADD_REVISION, data, response => onResponse(response), error => onError(error));
  },
  exportComments: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUDIT_EXPORT_COMMENTS, data, response => onResponse(response), error => onError(error));
  }
};
export default AuditService;
