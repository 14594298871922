import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import NavAppBar from '../NavAppBar/NavAppBar';
import { Container } from '@material-ui/core';
import AdminMasterPageStyles from './AdminMasterPageStyles';
import MQService from '../../services/MQService';

const AdminMasterPage = (props) => {

  const { children } = props;
  const classes = AdminMasterPageStyles();
  //TODO: Fix content positioning
  return (
    <NavAppBar>
      <BreadCrumb />
      <div style={{ height: `${MQService.contentPageHeight()}px` }}>
        {children}
      </div>
    </NavAppBar>
  );
}
export default withRouter(props => <AdminMasterPage {...props} />);
