import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Routes from '../../settings/Routes';
import BreadCrumbStyles from './BreadCrumbStyles';
import Typography from "@material-ui/core/Typography";
import routesInfo from "../../utils/RoutesInfo";
import _ from "lodash";
import MQService from '../../services/MQService';

const BreadCrumb = (props) => {
  const { t, history} = props;
  const classes = BreadCrumbStyles();
  const { pathname } = props.location;

  const tokens = (pathname.endsWith("/")
      ? pathname.substr(0, pathname.length - 1)
      : pathname
  ).split("/");

  let current = "";

  const links = _(tokens)
    .map(tok => {
      const url = current.endsWith("/") ? current + tok : `${current}/${tok}`;
      const info = routesInfo.getFromUrl(url);

      current = url;
      return { url, title: t(_.get(info, "title")) };
    })
    .filter(i => !!i.title)
    .map(i => {
      if (i.url === pathname)
        return (
          <span key={i.url}>
            <Typography color="inherit">{i.title}</Typography>
          </span>
        );

      return (
        <span key={i.url}>
          <Link color="inherit" className={classes.link} onClick={() => {
            history.push(i.url)
          }}>
            {i.title}
          </Link>
        </span>
      );
    })
    .value();

      return (
    <Paper style={{ height: `${MQService.BreadCrumbHeight}px` }} elevation={0} className={classes.paper}>
        {links ? <Breadcrumbs aria-label="breadcrumb">{links}</Breadcrumbs> : false}
    </Paper>
  );
};

export default withTranslation("i18n")(withRouter(props => <BreadCrumb {...props} />));

