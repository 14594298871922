import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ObjectExt from '../../utils/ObjectExt';
import i18n from "../../translations/i18n";
import { withTranslation } from "react-i18next";
import BaseIncludeTable from "../Commons/BaseIncludeTable";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    iconButton: {
        cursor: 'pointer'
    }
}));


const AuditDetailsContributorRemoveTable = (props) => {

    const classes = useStyles();
    //
    // React.useEffect(() => {
    //     //As componentDidMount
    // }, []);

    // React.useEffect(() => {
    //     debugger;
    // }, [props.selectedRows]);

    const { rowsData, onRemoveRow, onToggleAuthorFlag, openAddContributorTable, t } = props;
    const rowCount = ObjectExt.IsNullOrUndefined(rowsData) || ObjectExt.IsNotArrayOrEmpty(rowsData) ? 0 : rowsData.length;
    const initialState = {
        selectedRows: [],
    };
    const [selectedRows, setSelectedRows] = useState(initialState.selectedRows);

    const actions = {
        hideAddRow: true,
    };

    const headers = [
        t("Name"),
        t("Email"),
        t("Domain"),
        t("Company"),
        t("Phone"),
        t("Address"),
        t("Author"),
    ];

    const renderRowData = (data) => {
        return [
            `${data.name} ${data.lastname}`,
            data.email,
            data.domain,
            data.company,
            data.phone,
            data.address,
            <Switch
                checked={data.isAuthor}
                onChange={() => {
                    onToggleAuthorFlag(data)
                }}
                value={data}
                color="primary"
            />
        ];
    };
    const filterProps = [
        'name',
        'email',
        'domain',
        'company',
        'phone',
        'address',
    ];



    const onSelectedRow = (row) => {

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
        }

    };

    const onSelectAllRows = () => {
        if (selectedRows.length === rowsData.length) {
            setSelectedRows(initialState.selectedRows);
        } else {
            setSelectedRows(rowsData);
        }
    };

    const onRemove = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            selectedRows.map(row => {
                ids.push(row.id);
            });
        }
        else {
            ids.push(tableRow.id);
        }
        setSelectedRows(initialState.selectedRows);
        onRemoveRow(ids);
    };


    return (
        <Paper elevation={0} className={classes.root}>
            <BaseIncludeTable
                title={t(i18n.AvailableContributors)}
                rowsData={rowsData}
                renderHeaders={headers}
                renderRow={renderRowData}
                onAddToolbar={openAddContributorTable}
                selectedRows={selectedRows}
                onSelectRow={onSelectedRow}
                onRemoveRow={onRemove}
                onSelectAllRows={onSelectAllRows}
                filterProps={filterProps}
                actions={actions}
            />
        </Paper>
    );
}

AuditDetailsContributorRemoveTable.propsTypes = {
    rows: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    renderHeaders: PropTypes.func,
}
export default withTranslation("i18n")(AuditDetailsContributorRemoveTable);
