import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import FileUpload from "../../components/FileUpload/FileUpload";
import ContributorDataService from "./ContributorDataService";
import PubSubService from "../../services/PubSubService";
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import BaseTable from "../Commons/BaseTable";
import { ConfirmDialog, ConfirmDialogService } from '../Commons/ConfirmDialog';
import ContributorDetailPage from "./ContributorDetailsPage";
import { ConfirmSaveDialog, ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";
import BaseLoader from "../Commons/BaseLoader";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import BaseDialog, { DialogScrollType, DialogSize, DialogMaxHeight } from '../Commons/BaseDialog';
import Typography from '@material-ui/core/Typography';

const StyledLoader = styled(CircularProgress)`position: fixed; left: 50%; top:50%;`;

class ContributorPage extends React.Component {

    static propTypes = {
        t: PropTypes.object,
        history: PropTypes.object
    };

    initialState = {
        isLoaded: false,
        isLoading: false,
        selectedRows: [],
        collaborators: [],
        selectedRow: {
            id: null,
            name: "",
            lastname: "",
            email: "",
            domain: "",
            company: "",
            phone: "",
            address: "",
            chapterRanges: []
        },
        openDialog: false,
        openImportDialog: false,
        excelReport: null
    };

    state = this.initialState;

    componentDidMount() {
        ContributorDataService.getAllContributors(response => {
            this.setState({
                isLoaded: true,
                collaborators: response.data.result
            })
        }, error => {
            ToastService.ShowToast(error);
        });
    }

    renderRowData(data) {
        return [
            `${data.name} ${data.lastname}`,
            data.email,
            data.domain,
            data.company,
            data.phone,
            data.address
        ];
    }

    filterProps = [
        'name',
        'email',
        'domain',
        'company',
        'phone',
        'address'
    ];

    fileExcelImportRef = React.createRef();

    render() {

        const { t } = this.props;
        const { collaborators, selectedRows, selectedRow, isLoaded } = this.state;

        const headers = [
            t(i18n.Name),
            t(i18n.Email),
            t(i18n.Domain),
            t(i18n.Company),
            t(i18n.Phone),
            t(i18n.Address)
        ];

        return (
            <div style={{ height: '100%' }}>
                {this.state.isLoading ?
                    <StyledLoader>
                        <BaseLoader show={!this.state.isLoading} />
                    </StyledLoader> : null
                }
                <ConfirmSaveDialog title={t(i18n.ConfirmDialogTitle)} />

                <BaseDialog
                    size={DialogSize.md}
                    onClose={() => {
                        this.setState({ isLoaded: false });
                        ContributorDataService.getAllContributors(response => {
                            this.setState({
                                isLoaded: true,
                                collaborators: response.data.result,
                                openImportDialog: false
                            })
                        }, error => {
                            ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
                            this.setState({ isLoaded: true });
                        });
                    }}
                    maxHeight={DialogMaxHeight.md}
                    fullWidth={true}
                    open={this.state.openImportDialog}
                    scroll={DialogScrollType.body}
                    title={t(i18n.ExcelImportReportDialogTitle)}
                >
                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                {t(i18n.ExcelImportReportDialogImportedCount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                {this.state.excelReport && this.state.excelReport.contributorsImported ? this.state.excelReport.contributorsImported.length : 0}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                {t(i18n.ExcelImportReportDialogNotImportedCount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                {this.state.excelReport && this.state.excelReport.contributorsFailed ? this.state.excelReport.contributorsFailed.length : 0}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                {t(i18n.ExcelImportReportDialogNotImportedExcelRows)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        {
                            this.state.excelReport && this.state.excelReport.contributorsFailedExcelRows ?
                                this.state.excelReport.contributorsFailedExcelRows.map((row) => {
                                    return (
                                        <>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1">
                                                    {t(i18n.Row)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1">
                                                    {row}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )
                                }) : null
                        }
                    </Grid>
                </BaseDialog>


                <ContributorDetailPage
                    onClose={this.onDialogClose}
                    selected={selectedRow}
                    open={this.state.openDialog}
                    onUpdate={this.onDetailsSave}>
                </ContributorDetailPage>

                <ConfirmDialog
                    title={t(i18n.ConfirmDialogTitle)}
                    elements={selectedRows.length} />

                <div style={{ padding: '5px', height: '100%' }}>
                    <BaseTable
                        title={t(i18n.Collaborators)}
                        subTitle={""}
                        rowsData={collaborators}
                        renderHeaders={headers}
                        selectedRows={selectedRows}
                        onSelectRow={this.onSelectedRow}
                        renderRow={this.renderRowData}
                        onAddRow={this.onCreateButton}
                        onEditRow={this.onEditRow}
                        onDeleteRow={this.onDeleteRow}
                        onSelectAllRows={this.onSelectAllRows}
                        onExcelImport={this.onExcelImportClick}
                        filterProps={this.filterProps}
                        actions={{ showExcelImport: true }}
                        showLoader={!isLoaded}
                        style={{ height: '100%' }}
                    >
                    </BaseTable>

                    <FileUpload
                        hidden
                        inputRef={this.fileExcelImportRef}
                        text={t(i18n.ImportFromExcel)}
                        allowType={'excel'}
                        onUpload={file => {
                            this.onExcelImport(file);
                        }} />

                </div>

            </div>
        );
    }

    onCreateButton = () => {
        this.setState({
            selectedRow: this.initialState.selectedRow,
            openDialog: true
        });
    };


    onDetailsSave = (selected) => {
        const { id } = selected;

        if (ObjectExt.IsNullOrUndefined(id)) {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                ContributorDataService.addContributor(selected, (response) => {

                    if (!response.data.isSuccess) {
                        ToastService.ShowToast(response.data.message);
                        return;
                    }
                    this.setState({ collaborators: [...this.state.collaborators, response.data.result] })

                }, (error) => {
                    ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                });
                this.setState({ openDialog: false });
            }
        } else {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                ContributorDataService.editContributor(id, selected, (response) => {

                    if (!response.data.isSuccess) {
                        ToastService.ShowToast(response.data.message);
                        return;
                    }

                    const data = response.data.result;
                    const newCollaborators = this.state.collaborators.slice();

                    const index = _.findIndex(newCollaborators, (collaborator) => {
                        return collaborator.id == data.id;
                    });

                    newCollaborators[index] = data;
                    this.setState({
                        collaborators: newCollaborators,
                        selectedRows: this.initialState.selectedRows
                    })
                },
                    (error) => {
                        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                    })
                this.setState({ openDialog: false });
            }
        }
        ConfirmSaveDialogService.onClose = () => {
        }
    };

    onSelectAllRows = () => {
        if (this.state.selectedRows.length === this.state.collaborators.length) {
            this.setState({ selectedRows: [] });
        } else {
            this.setState({ selectedRows: this.state.collaborators });
        }
    };
    onSelectedRow = (row) => {
        const { selectedRows } = this.state;

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            this.setState({
                selectedRows: newSelectedRows
            })
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            this.setState({
                selectedRows: newSelectedRows
            })
        }
    };
    onEditRow = (row) => {
        this.setState({
            selectedRow: row,
            openDialog: true
        });
    };
    onDeleteRow = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            ConfirmDialogService.open();
            this.state.selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            this.setState({
                selectedRows: [tableRow]
            }, () => {
                ids.push(tableRow.id);
                ConfirmDialogService.open();
            });
        }

        ConfirmDialogService.onAccept = () => {

            this.setState({ isLoaded: false });
            ContributorDataService.deleteContributor(ids, response => {
                ToastService.ShowToast(response.data.message);

                const newCollaborators = this.state.collaborators.slice();
                const usersDeleted = response.data.result;
                _.forEach(usersDeleted, user => {
                    _.remove(newCollaborators, q => q.id === user.id);
                });

                this.setState({
                    collaborators: newCollaborators,
                    selectedRows: this.initialState.selectedRows
                });
                this.setState({ isLoaded: true });
            }, error => {
                ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                this.setState({ isLoaded: true });
            });
        };

        ConfirmDialogService.onClose = () => {
            this.setState({
                selectedRows: this.initialState.selectedRows
            });
        }

    };


    onExcelImportClick = () => {
        const fileExcelImportEL = this.fileExcelImportRef.current;
        if (!fileExcelImportEL) return;
        fileExcelImportEL.click();
    };
    onExcelImport = file => {
        ConfirmSaveDialogService.open();
        ConfirmSaveDialogService.onAccept = () => {

            const data = new FormData();
            data.append('excelFile', file);

            this.setState({ isLoaded: false });

            ContributorDataService.addContributorFromExel(data, response => {
                if (!response.data.isSuccess || ObjectExt.IsNullOrUndefined(response.data.result)) {
                    ToastService.ShowToast(response.data.message);
                    return;
                }

                const collaborators = this.state.collaborators.slice();

                const { contributorsImported } = response.data.result;
                let newColaborators = _.concat(collaborators, contributorsImported);
                this.setState({
                    collaborators: newColaborators,
                    isLoaded: true,
                    excelReport: response.data.result,
                    openImportDialog: true
                });

            }, error => {
                this.setState({ isLoaded: true });
                ToastService.ShowToast("Error importing excel data");
            })
        }
        ConfirmSaveDialogService.onClose = () => {
        }
    };

    onDeleteButton = () => {
        let { collaborators, selectedRows } = this.state;
        _.remove(collaborators, q => selectedRows.includes(q.id));
        collaborators = [...collaborators];
        this.setState({
            selectedRows: [],
            collaborators
        });
        this.forceUpdate();
    };

    onDialogClose = () => {
        this.setState({
            selectedRows: this.initialState.selectedRows,
            openDialog: false
        });
    };
};

export default withTranslation("i18n")(withRouter(ContributorPage));
