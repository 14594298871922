import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import DashboardTile, { Icon } from './DashboardTile';
import Grid from '@material-ui/core/Grid';
import DashboardStyles from './DashboardStyles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AdministratorService from "../Administrator/AdministratorService";
import Toaster from "../Toaster/Toaster";
import ToastService from "../../services/ToastService";
import ContributorDataService from "../Contributor/ContributorDataService";
import EmailTemplateDataService from "../EmailTemplate/EmailTemplateDataService";
import ChapterService from "../Chapter/ChapterDataService";
import AuditService from "../Audit/AuditDataService";

const DashboardPage = (props) => {

  const [adminCount, setAdminCount] = useState(0);
  const [contributorCount, setContributorCount] = useState(0);
  const [emailTemplateCount, setEmailTemplateCount] = useState(0);
  const [chapterCount, setChapterCount] = useState(0);
  const [auditCount, setAuditCount] = useState(0);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

  useEffect(() => {
    //Aministrators request
    AdministratorService.getAllAdministrators(response => {
      if (response && response.data) {
        setAdminCount(response.data.result.length)
      }
    }, error => {
      //TODO Show Toast
    });

    //Contributors request
    ContributorDataService.getAllContributors(response => {
      if (response && response.data) {
        setContributorCount(response.data.result.length)
      }
    }, error => {
      //TODO Show Toast
    });

    //EmailTemplate request
    EmailTemplateDataService.getAllEmailTemplates(response => {
      if (response && response.data) {
        setEmailTemplateCount(response.data.result.length)
      }
    }, error => {
      //TODO Show Toast
    });

    //Chapter request
    ChapterService.getChaptersCount(response => {
      if (response && response.data) {
        setChapterCount(response.data.result)
      }
    }, error => {
      //TODO Show Toast
    });

    //Audit request
    AuditService.getAuditsCount(response => {
      if (response && response.data) {
        setAuditCount(response.data.result)
      }
    }, error => {
      //TODO Show Toast
    });


  }, []);



  const { t, history } = props;
  const classes = DashboardStyles();

  return (
    <Grid container justify="center" alignItems="center">

      <Grid container xs={12} className={classes.cardContainer}>

        <Grid item xs={3} className={classes.tileContainer}>
          <DashboardTile
            icon={Icon.administrator}
            title={t("Administrators")}
            number={adminCount}
            numberUnit={t("Administrators")}
            onCardClick={() => { history.push('/admins'); }} />
        </Grid>

        <Grid item xs={3} className={classes.tileContainer}>
          <DashboardTile
            icon={Icon.contributor}
            title={t("Contributors")}
            number={contributorCount}
            numberUnit={t("Contributors")}
            onCardClick={() => { history.push('/contributors'); }} />
        </Grid>

        <Grid item xs={3} className={classes.tileContainer}>
          <DashboardTile
            icon={Icon.emailTemplate}
            title={t("EmailTemplates")}
            number={emailTemplateCount}
            numberUnit={t("EmailTemplates")}
            onCardClick={() => { history.push('/emailTemplates'); }} />
        </Grid>

        <Grid item xs={3} className={classes.tileContainer}>
          <DashboardTile
            icon={Icon.chapter}
            title={t("Chapters")}
            number={chapterCount}
            numberUnit={t("Chapters")}
            onCardClick={() => { history.push('/chapters'); }} />
        </Grid>

        <Grid item xs={3} className={classes.tileContainer}>
          <DashboardTile
            icon={Icon.audit}
            title={t("Revisions")}
            number={auditCount}
            numberUnit={t("Revisions")}
            onCardClick={() => { history.push('/audits'); }} />
        </Grid>

        <Grid item xs={3} className={classes.tileContainer}>
        </Grid>

      </Grid>
    </Grid>
  );
}
export default withTranslation("i18n")(withRouter(DashboardPage));
