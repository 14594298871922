import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from "react-i18next";
import MQService from '../../services/MQService';
import ObjectExt from '../../utils/ObjectExt';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),

    },
    button: {
        margin: theme.spacing(1),
        boxShadow: 'none'
    },
    buttonEndIcon: {
        marginLeft: theme.spacing(1)
    }
}));

export const DialogScrollType = {
    paper: "paper",
    body: "body"
}
export const DialogSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
}
export const DialogMaxHeight = {
    md: 'md',
    lg: 'lg'
}
export const DialogActionResult = {
    Succeeded: 0,
    Failed: 1
}

const renderbuttons = (buttons, classes) => {

    return ObjectExt.IsNullOrUndefined(buttons) || ObjectExt.IsNotArrayOrEmpty(buttons) ? null : (
        <>
            {
                buttons.map((b) => {
                    if (!ObjectExt.IsNullOrUndefined(b.hidden) && b.hidden) return null;
                    if (ObjectExt.IsDefinedFunc(b.render)) {
                        return b.render();
                    }
                    return (
                        <Button onClick={b.onClick} className={classes.button} color="primary">
                            {b.title}
                        </Button>
                    )
                })
            }

        </>
    );
};


const renderExtraButtons = (extraButtons, classes) => {

    return ObjectExt.IsNullOrUndefined(extraButtons) || ObjectExt.IsNotArrayOrEmpty(extraButtons) ? null : (
        <>
            {
                extraButtons.map((b) => {

                    if (!ObjectExt.IsNullOrUndefined(b.hidden) && b.hidden) return null;
                    if (ObjectExt.IsDefinedFunc(b.render)) {
                        return b.render();
                    }
                    return (
                        <Button variant="contained" onClick={b.onClick} className={classes.button} color="primary">
                            {b.title}
                        </Button>
                    )
                })
            }

        </>
    );
}

const BaseDialog = (props) => {

    const classes = useStyles();

    const { size, onClose, fullScreen, fullWidth, open, title, contentText, scroll, buttons, t, extraButtons, maxHeight, hideButtons, showSave } = props;

    let maxHeightValue = undefined;
    if (maxHeight && maxHeight === DialogMaxHeight.lg) {
        maxHeightValue = `${MQService.dialogLgHeight()}px`;
    } else if (maxHeight) {
        maxHeightValue = `${MQService.dialogMdHeight()}px`;
    }
    return (
        <Dialog
            fullScreen={fullScreen ? fullScreen : false}
            fullWidth={fullWidth}
            maxWidth={size}
            open={open}
            onClose={onClose}
            scroll={scroll}
            aria-labelledby="max-width-dialog-title">

            <DialogTitle id="max-width-dialog-title">
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        {title}
                    </Grid>
                    <Grid item>
                        <Button onClick={onClose} color="primary" className={classes.button}>
                            <CloseIcon />
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={maxHeightValue ? { height: maxHeightValue, top: `${MQService.NavBarHeight}px` } : null}>
                <DialogContentText>
                    {contentText}
                </DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justify="space-between">
                    <Grid item>
                        {
                            ObjectExt.IsNullOrUndefined(hideButtons) || !hideButtons ?
                                renderExtraButtons(extraButtons, classes) : null
                        }
                    </Grid>
                    <Grid item>
                        {
                            ObjectExt.IsNullOrUndefined(showSave) || !showSave ?
                                ObjectExt.IsNullOrUndefined(hideButtons) || !hideButtons ?
                                    renderbuttons(buttons, classes) : null :
                                renderbuttons(buttons, classes)
                        }

                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
BaseDialog.propsTypes = {
    size: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
    contentText: PropTypes.string,
    scroll: PropTypes.string,
    buttons: PropTypes.arrayOf(),
    extraButtons: PropTypes.arrayOf(),
    maxHeight: PropTypes.string
}
export default withTranslation("i18n")(BaseDialog);

