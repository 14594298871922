import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BaseDialog, { DialogSize, DialogMaxHeight, DialogScrollType } from '../Commons/BaseDialog';
import ObjectExt from '../../utils/ObjectExt';
import i18n from '../../translations/i18n';
import ToastService from '../../services/ToastService';

const useStyles = makeStyles(theme => ({
  inputLabel: {
    zIndex: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 325,
    border: 0,

    '& div': {
      '& input': {
        backgroundColor: 'white',
        border: 0,
        '&:before': {
          border: 0,
        },
        '&:after': {
          border: 0,
        },
        '&:hover': {
          border: 0,
        },
        '&:focus': {
          border: 0,
        },
      },
    },
  },
}));

const AdministratorDetailPage = props => {
  const { t, selected, onUpdate, onClose, open } = props;
  const [data, setData] = React.useState(selected);
  const classes = useStyles();

  React.useEffect(() => {
    setData(selected);
  }, [selected]);

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const dialogButtons = [
    {
      title: props.t(i18n.Save),
      onClick: () => {

        if (ObjectExt.IsNullOrUndefined(data.currentPassword) || ObjectExt.IsStringEmpty(data.currentPassword)) {
          onUpdate(data);
          return;
        }

        if (data.password !== data.password2) {
          ToastService.ShowToast(t(i18n.ChangePasswordMismatch));
          return;
        }
        onUpdate(data);
      },
    },
  ];

  return (
    <BaseDialog
      size={DialogSize.md}
      onClose={() => {
        setData(selected);
        onClose()
      }}
      fullWidth={false}
      open={open}
      scroll={DialogScrollType.body}
      title={t(i18n.AdministratorDetail)}
      contentText={t(i18n.AvailableAdministratorsMore)}
      buttons={dialogButtons}
      maxHeight={DialogMaxHeight.md}

    >
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label="Name"
              name="name"
              required
              className={classes.textField}
              value={data.name}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label={t(i18n.Surname)}
              className={classes.textField}
              name="lastname"
              value={data.lastname}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label={t(i18n.Email)}
              className={classes.textField}
              name="email"
              required
              value={data.email}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
          <Grid item xs={12}>
            {
              selected.id === 0 ? null :
                <TextField
                  id="standard-name"
                  label={t(i18n.PasswordCurrent)}
                  className={classes.textField}
                  name="currentPassword"
                  required
                  value={data.currentPassword}
                  type="password"
                  onChange={handleChange}
                  margin="normal"
                  InputLabelProps={{ className: classes.inputLabel }}
                />
            }

          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label={t(i18n.Password)}
              className={classes.textField}
              name="password"
              required
              value={data.password}
              type="password"
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="standard-name"
              label={t(i18n.RepeatPassword)}
              className={classes.textField}
              name="password2"
              required
              value={data.password2}
              type="password"
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};
export default withTranslation('i18n')(withRouter(AdministratorDetailPage));
