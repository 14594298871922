import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
import SvgIcon from '@material-ui/core/SvgIcon';
import crbLogo from '../../assets/crb-logo-schwarz_de.svg';

const CRBLogo = (props) => {

  const logoStyleSize = {
    width: `${props.width}px`,
    height: `${props.height}px`
  }
  return (<img src={crbLogo} alt="crb-logo-schwarz_de" style={logoStyleSize} /> );
}
CRBLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};
export default CRBLogo;
