import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import clsx from 'clsx';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import i18nService from '../../services/i18nService';
import ToastService from '../../services/ToastService';
import i18n, { Language } from '../../translations/i18n';
import NavAppBarStyles from './NavAppBarStyles';
import NavLanguageMenu from './NavLanguageMenu';
import NavUserMenu from './NavUserMenu';
import MQService from '../../services/MQService';
import ObjectExt from '../../utils/ObjectExt';

const NavAppBar = props => {
  const classes = NavAppBarStyles();
  const theme = useTheme();

  const [anchorUserMenuEl, setAnchorUserMenuEl] = React.useState(null);
  const [anchorLanguageMenuEl, setAnchorLanguageMenuEl] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const { t } = props;

  const loggedUser = AuthService.getAuthenticatedUserData();

  if (ObjectExt.IsNullOrUndefined(loggedUser) || ObjectExt.IsNullOrUndefined(loggedUser.username)) {
    AuthService.logout(
      response => {
        ToastService.ShowToast('System need authentication');
      },
      error => { }
    );
  }

  const openMenu = Boolean(anchorUserMenuEl);
  const openLanguageMenu = Boolean(anchorLanguageMenuEl);

  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const renderSideNavList = () => {
    return (
      <>
        <List>
          <ListItem
            button
            key={'Dashboard'}
            onClick={() => {
              props.history.push('/');
            }}
          >
            <Tooltip title={t('Dashboard')}>
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Dashboard')} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key={'Administrators'}
            onClick={() => {
              props.history.push('/admins');
            }}
          >
            <Tooltip title={t('Administrators')}>
              <ListItemIcon>
                <PeopleIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Administrators')} />
          </ListItem>
          <ListItem
            button
            key={'Contributors'}
            onClick={() => {
              props.history.push('/contributors');
            }}
          >
            <Tooltip title={t('Contributors')}>
              <ListItemIcon>
                <PeopleOutlineIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Contributors')} />
          </ListItem>
          <ListItem
            button
            key={'Emails'}
            onClick={() => {
              props.history.push('/EmailTemplates');
            }}
          >
            <Tooltip title={t('EmailTemplates')}>
              <ListItemIcon>
                <EmailIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('EmailTemplates')} />
          </ListItem>
          <ListItem
            button
            key={'Chapters'}
            onClick={() => {
              props.history.push('/chapters');
            }}
          >
            <Tooltip title={t('Chapters')}>
              <ListItemIcon>
                <LibraryBooksIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Chapters')} />
          </ListItem>

        </List>
        <Divider />
        <List>
          <ListItem
            button
            key={'Revisions'}
            onClick={() => {
              props.history.push('/audits');
            }}>
            <Tooltip title={t('Revisions')}>
              <ListItemIcon>
                <QuestionAnswerIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('Revisions')} />
          </ListItem>
        </List>
      </>
    );
  };

  NavUserMenu.onProfile = () => {
    setAnchorUserMenuEl(null);
  };
  NavUserMenu.onLogout = () => {
    AuthService.logout(
      response => {
        ToastService.ShowToast(t(i18n.SignOutSuccessfully));
        props.history.push('/');
      },
      error => { }
    );
    setAnchorUserMenuEl(null);
  };
  NavLanguageMenu.onItalian = () => {
    i18nService.setItalianLanguage(props.i18n);
    setAnchorLanguageMenuEl(null);
  };
  NavLanguageMenu.onDeutch = () => {
    i18nService.setDeutchLanguage(props.i18n);
    setAnchorLanguageMenuEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={1} position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">{t(i18n.ProductTitle)}</Typography>
          </Grid>
          <div className={classes.grow} />
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Typography align="justify" variant="subtitle1">
              {t(Language())}
            </Typography>
            <IconButton
              aria-label="language app menu"
              aria-controls={NavLanguageMenu.id}
              aria-haspopup="true"
              onClick={event => {
                setAnchorLanguageMenuEl(event.currentTarget);
              }}
              color="inherit">
              <LanguageIcon />
            </IconButton>
            {NavLanguageMenu.render(
              anchorLanguageMenuEl,
              () => {
                setAnchorLanguageMenuEl(null);
              },
              openLanguageMenu,
              t
            )}

            <Typography align="justify" variant="subtitle1">
              {loggedUser.username}
            </Typography>
            <IconButton
              aria-label="account of current user"
              aria-controls={NavUserMenu.id}
              aria-haspopup="true"
              onClick={event => {
                // setAnchorUserMenuEl(event.currentTarget);
              }}
              color="inherit">
              <AccountCircle />
            </IconButton>
            <IconButton
              aria-label="logout form system"
              onClick={() => {
                AuthService.logout(
                  response => {
                    ToastService.ShowToast(t(i18n.SignOutSuccessfully));
                    props.history.push('/');
                  },
                  error => { }
                );
                setAnchorUserMenuEl(null);
              }}
              color="inherit">
              <ExitToAppIcon />
            </IconButton>
            {/* {NavUserMenu.render(
              anchorUserMenuEl,
              () => {
                setAnchorUserMenuEl(null);
              },
              openMenu
            )} */}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        elevation={1}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          }),
        }}
        open={openDrawer}
      >
        <Grid style={{ marginTop: `${MQService.NavBarHeight}px` }}>{renderSideNavList()}</Grid>
      </Drawer>

      <div className={classes.content}>
        {props.children}
      </div>
    </div >
  );
};
export default withTranslation('i18n')(withRouter(props => <NavAppBar {...props} />));
