import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

export default makeStyles(theme => ({
    appMasterLayout: {
        backgroundColor: theme.palette.common.white
    },
    appContributorLayout: {
        backgroundColor: theme.palette.common.white
    }
}));
