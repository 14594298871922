import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AuditDataService from './AuditDataService';
import EmailTemplateDataService from '../EmailTemplate/EmailTemplateDataService';
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import { AuditStatus } from '../../utils/Enums';
import BaseDialog, { DialogScrollType, DialogSize, DialogMaxHeight } from '../Commons/BaseDialog';
import { BaseTab, BaseTabPanel, BaseTabs, BaseTabsProps } from '../Commons/BaseTabs';
import AuditDetailsChapterSelector from './AuditDetailsChapterSelector';
import AuditDetailsContributors from './AuditDetailsContributors';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import AuditStepperSendEmail from "./AuditStepperSendEmail";
import Fab from "@material-ui/core/Fab";
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import ObjectExt from '../../utils/ObjectExt';
import _ from "lodash";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";
import Badge from "../ChapterAttachmentViewer/ChapterAttachmentChip";
import useStateWithCallback from "use-state-with-callback";
import BaseLoader from "../Commons/BaseLoader";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import RateReviewIcon from '@material-ui/icons/RateReview';
import QueueIcon from '@material-ui/icons/Queue';

const StyledLoader = styled(CircularProgress)`
  position: fixed;
  left: 50%;
  top:50%;
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  padding: {
    padding: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
    minWidth: 250
  },
  inputLabel: {
    zIndex: 1,
  },
  fab: {
    margin: theme.spacing(1),
    float: 'right'
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  buttonEndIcon: {
    marginLeft: theme.spacing(1),
  }
}));

const AuditDetailsPage = props => {

  const linkRef = React.createRef();
  const { t, selected, onUpdate, onAddRevision, onSetToEspecificRevision, onClose, open } = props;
  const [audit, setAudit] = React.useState(selected);
  const [openChapterDialog, setOpenChapterDialog] = React.useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openStepperDialog, setOpenStepperDialog] = React.useState(false);
  const [auditChapter, setAuditChapter] = React.useState(null);
  const [auditRevision, setAuditRevision] = React.useState(null);
  const [newAuditRevision, setNewAuditRevision] = React.useState(null);
  const [isWorking, setIsWorking] = React.useState(false);
  const [hideButtons, setHideButtons] = React.useState(false);

  const auditStatusValues = [
    {
      value: AuditStatus.Preparing,
      label: t(AuditStatus.Preparing),
    },
    {
      value: AuditStatus.Active,
      label: t(AuditStatus.Active),
    },
    {
      value: AuditStatus.Editing,
      label: t(AuditStatus.Editing),
    },
    {
      value: AuditStatus.Revisioning,
      label: t(AuditStatus.Revisioning),
    },
    {
      value: AuditStatus.Closed,
      label: t(AuditStatus.Closed),
    },
  ];


  React.useEffect(() => {

    const updatedAudit = Object.assign({}, selected);
    updatedAudit.chapterId = selected && selected.chapter ? selected.chapter.id : 0;

    setAudit(updatedAudit);
    if (selected.id === 0) {
      setHideButtons(true);
    }
    else {
      setHideButtons(false);
    }
  }, [selected]);

  // React.useEffect(() => {
  //     setAudit({ ...audit, ['fromDate']: new Date(props.fromDate) });
  //     setAudit({ ...audit, ['toDate']: new Date(props.toDate) });
  // }, []);

  const onTabChange = (event, newTab) => {
    if (newTab === 1 && !ObjectExt.IsNullOrUndefined(newAuditRevision)) {
      ToastService.ShowToast(t(i18n.AuditDetailPageCreateRevisionBeforeCollaboratorsMigration));
      return;
    }
    setTab(newTab);
  };
  const handleChange = event => {
    setAudit({ ...audit, [event.target.name]: event.target.value });
  };
  const handleStatusChange = event => {
    setAudit({ ...audit, [event.target.name]: event.target.value });
  };
  const handleFromDateChange = date => {
    setAudit({ ...audit, ['fromDate']: date });
  };
  const handleToDateChange = date => {
    setAudit({ ...audit, ['toDate']: date });
  };

  const classes = useStyles();

  const onChapterChange = chapter => {
    const updatedAudit = Object.assign({}, audit);
    updatedAudit.chapter = chapter;
    updatedAudit.chapterId = chapter.id;
    setAudit(updatedAudit);
    setOpenChapterDialog(false);
  };

  const openSendEmailStepperDialog = () => {
    setOpenStepperDialog(true);
  };
  const onCloseSendEmailStepperDialog = () => {
    setOpenStepperDialog(false);
  };

  const openPickChapter = () => {
    setOpenChapterDialog(true);
  };

  const onChapterPickClose = () => {
    setOpenChapterDialog(false);
  };

  const onTemplatePickerClose = () => {
    setOpenTemplateDialog(false);
  };

  const onExportComments = () => {

    if (isWorking) {
      ToastService.ShowToast(t(i18n.ExcelCommentExportIsRunning));
      return;
    }

    setIsWorking(true);
    setHideButtons(true);
    AuditDataService.exportComments(selected, response => {
      if (!response.data.isSuccess) {
        ToastService.ShowToast(response.message);
        setIsWorking(false);
        setHideButtons(false);
        return;
      }
      window.open(response.data.result);
      onClose();
      setIsWorking(false);
      setHideButtons(false);

    }, error => {
      ToastService.ShowToast(error);
      setIsWorking(false);
      setHideButtons(false);
    })
  };

  const onCreateRevision = () => {

    ConfirmSaveDialogService.open();

    ConfirmSaveDialogService.onAccept = () => {

      const addRevisionModel = {
        auditId: audit.id,
        chapterId: audit.chapterId
      };

      const updatedAudit = Object.assign({}, audit);
      updatedAudit.chapter = null;
      updatedAudit.chapterId = null;
      updatedAudit.status = AuditStatus.Preparing;
      updatedAudit.fromDate = null;
      updatedAudit.toDate = null;
      setAudit(updatedAudit);
      setNewAuditRevision(addRevisionModel);
    };

    ConfirmSaveDialogService.onClose = () => {
    };

  };

  const NavigateToAuditCommentsView = () => {
    if (audit.chapter == null) {
      ToastService.ShowToast(t(i18n.AuditViewBlockedByChapter));
      return;
    }
    if (!ObjectExt.IsNullOrUndefined(audit.chapter)) {
      props.history.push(`/audits/view`, { audit });
    } else {
      ToastService.ShowToast("Audit need chapter to navigate")
    }
  }

  const dialogExtraButtons = [
    {
      render: () => {
        if (selected.status !== AuditStatus.Closed || (selected.auditRevisions && selected.auditRevisions.length > 2)) return null;
        return (<Button variant="contained"
          className={classes.button}
          onClick={onCreateRevision}
          color="primary">
          {t(i18n.CreateRevision)}
          <QueueIcon className={classes.buttonEndIcon} />
        </Button>)
      }
    },
    {
      render: () => {
        return (<Button variant="contained"
          className={classes.button}
          onClick={NavigateToAuditCommentsView}
          color="primary">
          {t(i18n.NavigateToChapter)}
          <RateReviewIcon className={classes.buttonEndIcon} />
        </Button>)
      }
    },
    {
      render: () => {
        return (<Button variant="contained"
          className={classes.button}
          onClick={onExportComments}
          color="primary">
          {t(i18n.ExportComments)}
          <ImportExportIcon className={classes.buttonEndIcon} />
        </Button>)
      }
    },
    {
      render: () => {
        return (<Button variant="contained"
          className={classes.button}
          onClick={openSendEmailStepperDialog}
          color="primary">
          {t(i18n.EmailStepperInfo)}
          <EmailIcon className={classes.buttonEndIcon} />
        </Button>)
      }

    }
  ]

  const onSaveIcon = () => {

    if (!ObjectExt.IsNullOrUndefined(newAuditRevision)) {

      if (ObjectExt.IsNullOrUndefined(audit.chapterId)) {
        ToastService.ShowToast("Please to create the revision add an unused chapter");
        return;
      }

      const newNewAuditRevision = Object.assign({}, newAuditRevision);
      newNewAuditRevision.chapterId = audit.chapterId;
      newNewAuditRevision.fromDate = audit.fromDate;
      newNewAuditRevision.toDate = audit.toDate;

      AuditDataService.addRevision(newNewAuditRevision, response => {

        ToastService.ShowToast(response.data.message);

        if (!ObjectExt.IsNullOrUndefined(response.data.result)) {
          onAddRevision(response.data.result);
        }

        setNewAuditRevision(null);
        onClose();

      }, error => {
        ToastService.ShowToast("Imossible to save this new revision");
      });
      return;
    }
    onUpdate(audit);
  }

  const dialogButtons = [
    {
      render: () => {
        return (<Button variant="contained"
          className={classes.button}
          onClick={onSaveIcon}
          color="primary">
          {t(i18n.Save)}
          <SaveIcon className={classes.buttonEndIcon} />
        </Button>)
      }
    },
  ];

  const onSendEmailClick = () => {
    AuditDataService.sendEmail(
      audit,
      response => {
        ToastService.ShowToast(response.data.message);
      },
      error => {
        ToastService.ShowToast('Impossible to send the email');
      }
    );
  };

  const onContributorsChange = contributors => {
    const updatedAudit = Object.assign({}, audit);
    updatedAudit.contributors = contributors;
    setAudit(updatedAudit);
  };

  const onSelectRevisionChange = ev => {
    const revisionId = ev.target.value;
    if (revisionId !== audit.selectedRevisionId) {
      AuditDataService.getAuditByRevision(audit.id, revisionId, response => {
        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.message);
          return;
        }
        onSetToEspecificRevision(response.data.result);
      },
        error => {
          ToastService.ShowToast("Impossible to get audit");
        })
    }
  };

  const renderOverviewTab = () => {

    const auditChapterTitle = ObjectExt.IsNullOrUndefined(audit.chapter) ? "" : audit.chapter.title;

    return (
      <>
        {
          isWorking ? <StyledLoader>
            <BaseLoader show={!isWorking} />
          </StyledLoader> : null
        }
        <AuditStepperSendEmail
          audit={audit}
          open={openStepperDialog}
          onClose={onCloseSendEmailStepperDialog}
        />

        <AuditDetailsChapterSelector
          onSelected={onChapterChange}
          open={openChapterDialog}
          onClose={onChapterPickClose}
        />


        <Grid item container justify="flex-start" alignItems="center" xs={12}>
          <Grid item xs={4}>
            <TextField
              id="standard-name"
              name="name"
              label={t('Name')}
              className={classes.textField}
              value={audit.name}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}
            />
          </Grid>
          <Grid item xs={4}>
            <InputLabel className={classes.textField} htmlFor="chapter">{t('Chapters')}</InputLabel>
            <Input
              id="chapter"
              value={auditChapterTitle}
              onChange={onChapterChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="chapter picker" onClick={openPickChapter}>
                    <LibraryBooksIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={4}>
            {
              audit.auditRevisions.length > 1 && ObjectExt.IsNullOrUndefined(newAuditRevision) ?
                <Select
                  value={audit.selectedRevisionId}
                  onChange={onSelectRevisionChange}
                  name="age"
                  displayEmpty
                  className={classes.textField}>
                  <MenuItem value="" disabled> {t(i18n.RevisionSelectPlaceholder)} </MenuItem>
                  {
                    audit.auditRevisions.map((prop, index) => (
                      index === 0 ?
                        (<MenuItem key={prop.index} value={prop.id}>
                          {`${t(i18n.FirstRevision)}`}
                        </MenuItem>) :

                        (<MenuItem key={prop.index} value={prop.id}>
                          {`${t(i18n.NotFirstRevision)}`}
                          {/* TODO: SE DECIDONO DI CREARE PIU DI UNA REVISIONE  {`${t(i18n.NotFirstRevision)} ${index}`} */}
                        </MenuItem>)
                    ))
                  }
                </Select> : null
            }
          </Grid>

        </Grid>

        <Grid item container justify="flex-start" alignItems="center" xs={12}>
          <Grid item xs={4}>
            <TextField
              select
              id="standard-name"
              name="status"
              label={t(i18n.Status)}
              className={classes.textField}
              value={audit.status}
              onChange={handleStatusChange}
              margin="normal"
              InputLabelProps={{ className: classes.inputLabel }}>
              <MenuItem value="" disabled> {t(i18n.SelectPleaseHolder)}</MenuItem>
              {auditStatusValues.map(status => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              disableToolbar
              variant="dialog"
              showTodayButton
              todayLabel={t(i18n.Today)}
              okLabel={t(i18n.OK)}
              cancelLabel={t(i18n.Cancel)}
              margin="normal"
              id="date-picker-inline"
              label={t(i18n.FromDate)}
              value={audit.fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change from date',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <KeyboardDatePicker
              disableToolbar
              showTodayButton
              todayLabel={t(i18n.Today)}
              okLabel={t(i18n.OK)}
              cancelLabel={t(i18n.Cancel)}
              variant="dialog"
              margin="normal"
              id="date-picker-inline"
              label={t(i18n.ToDate)}
              value={audit.toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change to date',
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid item container justify="flex-start" alignItems="center" xs={12}>
          <Grid item xs={4} style={{ paddingTop: '40px' }}>
            <Grid item>
              <Typography align="justify" variant="h6" gutterBottom>
                {`${t(i18n.ContributorsCount)}:
                        ${!ObjectExt.IsArray(audit.contributors) ? 0 : audit.contributors.length}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="justify" variant="h6" gutterBottom>
                {`${t(i18n.CommentsCount)}:
                        ${!ObjectExt.IsArray(audit.comments) ? 0 : audit.comments.length}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </>
    );
  };

  const renderCollaborators = () => {
    const { id } = audit;

    return (
      <>
        <Grid item container xs={12}>
          <AuditDetailsContributors
            contributors={audit.contributors}
            onUpdateContributors={onContributorsChange}
            audit={audit}
          />
        </Grid>
        <Grid item content direction="row" style={{ paddingTop: '10px' }}>
          {audit.templateId > 0 && audit.contributors.length > 0 ? (
            <Button style={{ marginRight: '10px' }} variant="outlined" onClick={onSendEmailClick}>
              {t(i18n.SendTest)}
            </Button>
          ) : null}
        </Grid>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Grid item content direction="row" style={{ paddingTop: '10px' }}>
        {}
      </Grid>
    );
  };

  const onActivateClick = () => {
    const data = { ...data };
    data.status = AuditStatus.Active;
    setAudit(data);
  };

  return (
    <BaseDialog
      size={DialogSize.xl}
      onClose={() => {
        setAudit(selected);
        onClose()
      }}
      fullWidth={true}
      open={open}
      scroll={DialogScrollType.paper}
      title={t(i18n.AuditDetail)}
      contentText={t(i18n.AuditDetailMore)}
      buttons={dialogButtons}
      extraButtons={dialogExtraButtons}
      maxHeight={DialogMaxHeight.md}
      hideButtons={hideButtons}
      showSave={true}
    >
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <BaseTabs value={tab} onChange={onTabChange} aria-label="tab details">
            <BaseTab label={t(i18n.Overview)} {...BaseTabsProps(0)} />
            <BaseTab label={t(i18n.Contributors)} {...BaseTabsProps(1)} />
          </BaseTabs>
          <BaseTabPanel value={tab} index={0}>
            {renderOverviewTab()}
          </BaseTabPanel>
          <BaseTabPanel value={tab} index={1}>
            {renderCollaborators()}
          </BaseTabPanel>
        </Grid>
        <Grid item xs={12}>
          {renderFooter()}
        </Grid>
      </Grid>
    </BaseDialog>
  );
};
export default withRouter(withTranslation('i18n')(AuditDetailsPage));
