
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    spacer: {
        flex: '1 1 100%',
    }
}));

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.spacer} />
    );
}