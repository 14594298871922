import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PubSubService from '../../services/PubSubService';
import i18n from '../../translations/i18n';
import ObjectExt from '../../utils/ObjectExt';
import BaseTable from '../Commons/BaseTable';
import { ConfirmDialog, ConfirmDialogService } from '../Commons/ConfirmDialog';
import AdministratorDetailPage from './AdministratorDetailsPage';
import AdministratorService from './AdministratorService';
import { ConfirmSaveDialog, ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";
import ToastService from "../../services/ToastService";
import BaseLoader from "../Commons/BaseLoader";


class AdministratorPage extends React.PureComponent {

    static propTypes = {
        t: PropTypes.object,
        history: PropTypes.object
    };

    initialState = {
        selectedRows: [],
        administrators: [],
        isLoaded: false,
        openDialog: false,
        openConfirmDialog: false,
        selectedRow: {
            id: 0,
            name: '',
            lastname: '',
            email: "",
            currentPassword: "",
            password: "",
            password2: ""
        },
    };


    state = this.initialState;

    componentWillMount() {

        PubSubService.SubscribeTo(PubSubService.KeyAdminCreated, (name, data) => {
            this.setState({ administrators: [...this.state.administrators, data] })
        });

        PubSubService.SubscribeTo(PubSubService.KeyAdminEdit, (name, data) => {
            const newAdministrators = this.state.administrators.slice();
            const index = _.findIndex(newAdministrators, (admin) => {
                return admin.id == data.id;
            });
            newAdministrators[index] = data;
            this.setState({ administrators: newAdministrators })
        });
    }

    componentWillUnmount() {
        PubSubService.UnSubscribeFrom(PubSubService.KeyAdminCreated);
    }

    componentDidMount() {
        AdministratorService.getAllAdministrators(response => {
            this.setState({
                isLoaded: true,
                administrators: response.data.result
            })
        }, error => {
            ToastService.ShowToast(error);
        })
    }

    renderRowData(data) {
        return [
            `${data.name} ${data.lastname}`,
            data.email,
        ];
    }

    onSelectAllRows = () => {
        if (this.state.selectedRows.length === this.state.administrators.length) {
            this.setState({ selectedRows: [] });
        } else {
            this.setState({ selectedRows: this.state.administrators });
        }
    };
    onSelectedRow = (row) => {
        const { selectedRows } = this.state;

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            this.setState({
                selectedRows: newSelectedRows
            })
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            this.setState({
                selectedRows: newSelectedRows
            })
        }
    };
    // onEditRow = () => {
    //   this.setState({
    //     selectedRow: this.state.selectedRows[0],
    //     openDialog: true
    //   });
    // };

    onEditRow = (row) => {
        this.setState({
            selectedRow: row,
            openDialog: true
        });
    };

    onDeleteRow = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            ConfirmDialogService.open();
            this.state.selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            this.setState({
                selectedRows: [tableRow]
            }, () => {
                ids.push(tableRow.id);
                ConfirmDialogService.open();
            });
        }

        ConfirmDialogService.onAccept = () => {
            AdministratorService.deleteAdminstrator(ids, response => {
                ToastService.ShowToast(response.data.message);
                const newAdministrators = this.state.administrators.slice();
                const usersDeleted = response.data.result;
                _.forEach(usersDeleted, user => {
                    _.remove(newAdministrators, q => q.id === user.id);
                });
                this.setState({
                    administrators: newAdministrators,
                    selectedRows: this.initialState.selectedRows
                });
            }, error => {
                ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
            });
        };

        ConfirmDialogService.onClose = () => {
            this.setState({
                selectedRows: this.initialState.selectedRows
            });
        }
    };

    onCreateButton = () => {
        this.setState({
            selectedRow: this.initialState.selectedRow,
            openDialog: true
        });
    };

    onDialogClose = () => {
        this.setState({
            openDialog: false
        });
    };

    onDetailsSave = (selected) => {
        const { id } = selected;

        if (ObjectExt.IsNullOrUndefined(id) || id === 0) {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                AdministratorService.addAdministrator(selected,
                    (response) => {
                        this.setState({
                            administrators: [...this.state.administrators, response.data.result],
                            openDialog: false
                        })
                    }, (error) => {
                        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                    });
            }
        } else {
            ConfirmSaveDialogService.open();
            ConfirmSaveDialogService.onAccept = () => {
                
                const editModel = {
                    id: selected.id,
                    name: selected.name,
                    lastname: selected.lastname,
                    email: selected.email,
                    currentPassword: selected.currentPassword,
                    newPassword: selected.password
                }
                AdministratorService.editAdministrator(editModel, (response) => {
                    const data = response.data.result;
                    const newAdministrators = this.state.administrators.slice();
                    const index = _.findIndex(newAdministrators, (admin) => {
                        return admin.id == data.id;
                    });
                    newAdministrators[index] = data;
                    this.setState({ administrators: newAdministrators, openDialog: false })
                },
                    (error) => {
                        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
                    })
            }
        }
        ConfirmSaveDialogService.onClose = () => {

        }
    };

    render() {
        const { t } = this.props;
        const { administrators, selectedRows, selectedRow, isLoaded } = this.state;

        const headers = [
            t("Name"),
            t("Email")
        ];

        const filterProps = [
            'name',
            'email'
        ];

        return (
            <>
                <ConfirmSaveDialog
                    title={t(i18n.ConfirmDialogTitle)} />

                <AdministratorDetailPage
                    onClose={this.onDialogClose}
                    selected={selectedRow}
                    open={this.state.openDialog}
                    onUpdate={this.onDetailsSave}>
                </AdministratorDetailPage>

                <ConfirmDialog
                    title={t(i18n.ConfirmDialogTitle)}
                    elements={selectedRows.length} />

                <div style={{ padding: '5px' }}>
                    <BaseTable
                        title={t(i18n.AvailableAdministrators)}
                        subTitle={t(i18n.AvailableAdministratorsMore)}
                        rowsData={administrators}
                        renderHeaders={headers}
                        renderRow={this.renderRowData}
                        selectedRows={selectedRows}
                        onAddRow={this.onCreateButton}
                        onSelectRow={this.onSelectedRow}
                        onEditRow={this.onEditRow}
                        onDeleteRow={this.onDeleteRow}
                        onSelectAllRows={this.onSelectAllRows}
                        filterProps={filterProps}
                        showLoader={!isLoaded}>
                    </BaseTable>
                </div>
            </>
        );
    }
}

export default withTranslation("i18n")(withRouter(AdministratorPage));
