import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import BaseDialog, { DialogScrollType, DialogSize } from './BaseDialog'
import i18n from '../../translations/i18n';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

//TODO: Check as unique confirm dialog on page? 
//TODO: Enable the use of more that one Confirmation dialog on the same parent component!

export let ConfirmDialogService = {};


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        boxShadow: 'none'
    },
    buttonEndIcon: {
        marginLeft: theme.spacing(1),
    }
}));


export const ConfirmDialog = withTranslation("i18n")((props) => {

    const { title, elements, t } = props;

    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [contentDialog, setContentDialog] = useState('');
    ConfirmDialogService.open = (textContent) => {
        setOpen(true);
        setContentDialog(`${t(i18n.ConfirmDialogDeleteContent)} ${elements} ${t(i18n.Elements)}?`)
    };

    const onSaveClick = () => {
        ConfirmDialogService.onAccept();
        setOpen(false);
    }

    const dialogButtons = [
        {
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    onClick={onSaveClick}
                    color="primary">
                    {t(i18n.OK)}
                    <CheckIcon className={classes.buttonEndIcon} />
                </Button>)
            }
        }
    ];
    return (
        <BaseDialog
            size={DialogSize.md}
            onClose={() => {
                ConfirmDialogService.onClose();
                setOpen(false);
            }}
            fullWidth={false}
            open={open}
            scroll={DialogScrollType.body}
            title={title}
            contentText={contentDialog}
            buttons={dialogButtons}
        >
        </BaseDialog>
    )
});


