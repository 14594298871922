import _ from "lodash";

const infos = [
  {
    path: "/",
    title: "Dashboard"
  },
  {
    path: "/admins",
    title: "Administrators"
  },
  {
    path: "/admins/create",
    title: "CreateAdministrator"
  },
  {
    path: "/admins/:id",
    title: "EditAdministrator"
  },
  {
    path: "/contributors",
    title: "Contributors"
  },
  {
    path: "/chapters",
    title: "Chapter"
  },
  {
    path: "/contributors/create",
    title: "CreateContributor"
  },
  {
    path: "/contributors/:id",
    title: "EditContributor"
  },
  {
    path: "/audits",
    title: "Revisions"
  },
  {
    path: "/audits/:id",
    title: "EditRevision"
  },
  {
    path: "/audits/view",
    title: "ViewRevision"
  },
  {
    path: "/emailTemplates",
    title: "EmailTemplates"
  },
  {
    path: "/emailTemplates/create",
    title: "CreateEmailTemplate"
  },
  {
    path: "/emailTemplates/:id",
    title: "EditEmailTemplate"
  }
];

class RoutesInfo {
  getFromUrl(url) {
    const urlTokens = url.split("/");

    return _.find(infos, r => {
      if (r === url) {
        return true;
      }

      const routeTokens = r.path.split("/");

      if (urlTokens.length !== routeTokens.length) {
        return false;
      }

      return _.every(urlTokens, (ut, idx) => {
        const rt = routeTokens[idx];
        return rt.startsWith(":") || ut === rt;
      });
    });
  }
}

export default new RoutesInfo();
