import { makeStyles } from "@material-ui/core";
import React from "react";
import i18n from "../../translations/i18n";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import BaseDialog, { DialogScrollType, DialogSize } from "../Commons/BaseDialog";
import Grid from "@material-ui/core/Grid";
import ObjectExt from "../../utils/ObjectExt";
import ToastService from "../../services/ToastService";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    border: '0px'
  }, inputLabel: {
    zIndex: 1,
  },
}));

const ContributorChapterRangeDetails = (props) => {

  const { t, selected, onUpdate, onClose, open } = props;
  const [data, setData] = React.useState(selected);

  const handleChange = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const validateForm = () => {
    debugger;
    if (data.from === "" || data.to === "") {
      return false;
    }

    const from = parseInt(data.from);
    const to = parseInt(data.to);
    
    if (to < from) {
      return false;
    }
    return true;
  };

  const classes = useStyles();

  React.useEffect(() => {
    setData(selected)
  }, [selected]);

  const dialogButtons = [
    {
      title: props.t("Save"),
      onClick: () => {

        if (!validateForm()) {
          ToastService.ShowToast(t(i18n.ChapterRangeValidation));
          setData({ to: "", from: "" });
        }
        else {
          onUpdate(data);
        }
      }
    }
  ];

  return (
    <BaseDialog
      size={DialogSize.md}
      onClose={() => {
        setData(selected);
        onClose()
      }}
      fullWidth={false}
      open={open}
      scroll={DialogScrollType.body}
      title={t(i18n.ContributorChapterRangeDetailsTitle)}
      buttons={dialogButtons}
    >
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <TextField
            id="standard-name"
            name="from"
            label={t(i18n.FromChapter)}
            className={classes.textField}
            value={data.from}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-name"
            name="to"
            label={t(i18n.ToChapter)}
            className={classes.textField}
            value={data.to}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default withTranslation("i18n")(
  withRouter(ContributorChapterRangeDetails)
);
