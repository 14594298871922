import { lighten, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  fileInput: {
    display: 'none',
  },
  progress: {
    margin: theme.spacing(4),
    padding: theme.spacing(4)
  },
  textField: {
    flexBasis: 200,
    minWidth: 250
  },
  buttonEndIcon: {
    marginLeft: theme.spacing(1),
  }
}));
