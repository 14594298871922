import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import _ from "lodash";
import cx from "classnames";
import Grid from "@material-ui/core/Grid";
import grey from "@material-ui/core/colors/grey";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ObjectExt from "../../utils/ObjectExt";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  badge: {
    cursor: "pointer"
  },
  catalogHeaderCol: {
    display: "flex",
    flexDirection: "row"
  },
  catalogHeaderColNumber: {
    flex: "1 0 auto"
  },
  catalogHeaderColExp: {
    flex: "0 0 auto",
    display: "inline-flex"
  },
  expander: {
    cursor: "pointer",
    marginLeft: theme.spacing(5)
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  headerCell: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    cursor: "pointer",
    verticalAlign: "top",
    borderRight: "1px solid #e5e5e5",
    padding: 0,
    display: "flex",
    flexDirection: "row",

    "&:hover": {
      fontWeight: "bold"
    }
  },
  headerCellPin: {
    flex: "0 0 auto",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    width: "10px"
  },
  headerCellPinFull: {
    backgroundColor: theme.palette.primary.main
  },
  headerCellPinFullAnswer: {
    backgroundColor: theme.palette.secondary.main
  },
  headerCellNumber: {
    flex: "1 0 auto",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(1.25)
  },
  headerCellExp: {
    flex: "0 0 auto",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingRight: theme.spacing(1.25)
  },
  mainNumber: {
    color: grey[900]
  },
  subNumber: {
    color: grey[500]
  }
}));

const CatalogHeaderColumn = ({
  section,
  expandable,
  expanded,
  toggleHandler
}) => {
  const [commentNumber, setCommentNumber] = React.useState(null);

  const updateCommentsNumber = positionId => {
    // TODO: CommentDataService.getCommentsByPosition(props.positionId, userId, audit, (response) => {}, (error) => {})
    // CommentDataService.getCommentsByPosition(positionId, (response) => {
    //   if (!response.data.isSuccess) {
    //     TeastService.ShowToast(response.data.message);
    //     return;
    //   }
    //   setCommentNumber(response.data.result.length);
    // }, (error) => {
    //   TeastService.ShowToast(error);
    // })
  };

  React.useEffect(() => {
    // PubSubService.SubscribeTo(PubSubService.KeyCommentAddedMessage, (name, positionId) => {
    //   if (positionId !== section.positionID) return;
    //   updateCommentsNumber(positionId);
    // })
  }, []);

  React.useEffect(() => {
    const positionId = section ? section.positionID : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    // updateCommentsNumber(positionId);
  }, [section]);

  const classes = useStyles();

  const Pin = ({ className }) => {
    const classNameConditional = section.hasCommentsWithAnswers ?
      cx(className, { [classes.headerCellPinFullAnswer]: section.hasCommentsWithAnswers }) :
      cx(className, { [classes.headerCellPinFull]: section.hasComments });
    return <div className={classNameConditional} />;
  };

  const Number = props => {
    const { mainNumber, subNumber } = section;

    const mainNumberFmt = numeral(mainNumber).format("000");
    const subNumberFmt = numeral(subNumber).format("000");

    return (
      <div {...props}>
        <span className={classes.mainNumber}>{`.${mainNumberFmt}`}</span>
        {subNumber !== 0 ? (
          <span className={classes.subNumber}>{`.${subNumberFmt}`}</span>
        ) : null}
      </div>
    );
  };
  const Arrow = props => {
    if (!expandable) return null;

    return (
      <div {...props}>
        {expanded ? (
          <ExpandMoreIcon
            color="primary"
            fontSize="small"
            className={classes.expander}
          />
        ) : (
            <NavigateNextIcon
              color="primary"
              fontSize="small"
              className={classes.expander}
            />
          )}
      </div>
    );
  };

  return (
    <TableCell align="left"
      style={{ padding: 0, verticalAlign: "top", position: "relative", width: '20%' }}
      onClick={toggleHandler}>
      <div className={classes.headerCell}>
        <Pin className={classes.headerCellPin} />
        <Number className={classes.headerCellNumber} />
        <Arrow className={classes.headerCellExp} />
      </div>
    </TableCell>
  );
};

CatalogHeaderColumn.propTypes = {
  section: PropTypes.array,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  toggleHandler: PropTypes.func
};

export default CatalogHeaderColumn;
