import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import _ from "lodash";
import cx from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { Typography, IconButton, Chip } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ChatIcon from "@material-ui/icons/Chat";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  box: {
    margin: 0,
    padding: "10px 15px",
    borderBottom: "1px solid #e0e0e0"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "0.9rem",
    padding: "5px 20px"
  },
  titleText: {
    flex: "1 0 auto"
  },
  titleIcon: {
    flex: "0 0 auto"
  },
  item: {
    display: "flex",
    flexDirection: "row"
  },
  itemNumber: {
    flex: "0 0 auto",
    width: theme.spacing(15)
  },
  itemVariable: {
    flex: "0 0 auto"
  },
  itemUnit: {
    flex: "1 0 auto",
    textAlign: "right"
  },
  itemDrawing: {
    flex: "1 0 auto",
    textAlign: "right"
  },
  mainNumber: {
    color: grey[900]
  },
  subNumber: {
    color: grey[500]
  },
  variable: {
    display: "flex",
    flexDirection: "row"
  },
  variableNumber: {
    flex: "0 0 auto",
    width: theme.spacing(10)
  },
  variableLabel: {
    flex: "1 0 auto"
  }
}));

const CommentHeader = ({ className, source, onHide, ...other }) => {
  const {
    typeLabel,
    label,
    itemNumber,
    variableNumber,
    variableLabel,
    unit,
    drawingAlt,
    drawingUrl,
    attachmentLabel,
    attachmentUrl
  } = source;

  const classes = useStyles();

  const Item = () => {
    if (!_.isNumber(itemNumber)) return false;

    return (
      <div className={classes.item}>
        <Number className={classes.itemNumber} />
        {label && <div dangerouslySetInnerHTML={{ __html: label }} />}
        <Variable className={classes.itemVariable} />
        <Unit className={classes.itemUnit} />
        <Drawing className={classes.itemDrawing} />
      </div>
    );
  };
  const Number = props => {
    if (!_.isNumber(itemNumber)) return false;

    const mainNumber = Math.floor(itemNumber / 1000);
    const subNumber = itemNumber % 1000;

    const mainNumberFmt = numeral(mainNumber).format("000");
    const subNumberFmt = numeral(subNumber).format("000");

    return (
      <div {...props}>
        <span className={classes.mainNumber}>{`.${mainNumberFmt}`}</span>
        {subNumber !== 0 ? (
          <span className={classes.subNumber}>{`.${subNumberFmt}`}</span>
        ) : null}
      </div>
    );
  };
  const Variable = ({ className, ...props }) => {
    if (!variableNumber) return false;

    return (
      <div className={cx(classes.variable, className)} {...props}>
        <div className={classes.variableNumber}>{variableNumber}</div>
        {variableLabel && (
          <div className={classes.variableLabel}>{variableLabel}</div>
        )}
      </div>
    );
  };
  const Unit = props => {
    if (!unit) return false;

    return <div {...props}>{unit}</div>;
  };
  const Drawing = props => {
    if (!drawingUrl) return false;

    return (
      <a href={drawingUrl} target="_blank" {...props}>
        <img src={drawingUrl} alt={drawingAlt || ""} height="50" width="50" />
      </a>
    );
  };
  const Attachment = props => {
    if (!attachmentLabel) return false;

    const linkRef = React.createRef();

    return (
      <>
        <a ref={linkRef} href={attachmentUrl} target="_blank" />
        <Chip
          icon={<GetAppIcon color="primary" />}
          variant="outlined"
          label={attachmentLabel}
          size="small"
          clickable
          onClick={() => {
            if (!linkRef.current) return;
            linkRef.current.click();
          }}
          className={classes.chip}
          deleteIcon={<ChatIcon color="primary" />}
        />
      </>
    );
  };

  return (
    <>
      <div className={classes.title}>
        <Typography className={classes.titleText} variant="subtitle1">
          {typeLabel}
        </Typography>
        <IconButton
          className={classes.titleIcon}
          size="small"
          color="inherit"
          onClick={onHide}
        >
          <Close fontSize="small" />
        </IconButton>
      </div>
      <div className={cx(className, classes.box)} {...other}>
        <Item />
        <Attachment />
      </div>
    </>
  );
};

CommentHeader.propTypes = {
  className: PropTypes.string,
  source: PropTypes.object,
  onHide: PropTypes.func
};

export { CommentHeader };
