import CKEditor from '@ckeditor/ckeditor5-react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ClassicEditor from 'ckeditor5-font-custombuild';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import i18n from '../../translations/i18n';
import CustomUploadAdapter from '../../utils/UploaderAdapter';
import BaseDialog,{ DialogScrollType, DialogSize, DialogMaxHeight } from '../Commons/BaseDialog';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0.8),
        marginRight: theme.spacing(0.8),
        width: "100%",
        border: '0px',
    },
    inputLabel: {
        zIndex: 1,
    },
    editor: {
        height: '500px'
    },
    editorField: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '16px',
        marginLeft: '-4px',
        marginRight: '-4px'
    },
    editorLabel: {
        marginBottom: '8px'
    },
    legendContiner: {
        margin: '10%'
    },
    legendLabelContiner: {
        display: 'grid',
    },
    legendLabel: {
        paddingTop: '3%'
    }
}));

const EmailTemplateDetailsPage = props => {
    const { t, open, selected, onUpdate, onClose } = props;
    const [data, setData] = React.useState(selected);

    const handleChange = event => {
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const classes = useStyles();

    React.useEffect(() => {
        setData(selected);
    }, [selected]);

    const dialogButtons = [
        {
            title: t('Save'),
            onClick: () => {
                onUpdate(data);
            },
        },
    ];

    return (
        <BaseDialog
            size={DialogSize.md}
            onClose={()=>{
                setData(selected);
                onClose()
            }}
            fullWidth={false}
            open={open}
            scroll={DialogScrollType.body}
            title={t(i18n.EmailTemplateDetail)}
            subTitle={t(i18n.EmailTemplateDetailMore)}
            buttons={dialogButtons}
            maxHeight={DialogMaxHeight.md}
        >
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <TextField
                        id="standard-name"
                        name="name"
                        label={t(i18n.Name)}
                        className={classes.textField}
                        value={data.name}
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="standard-name"
                        name="subject"
                        label={t(i18n.Subject)}
                        className={classes.textField}
                        value={data.subject}
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>

                <Grid
                    item
                    xs={8}
                    className={classes.editor}>
                    <div className={classes.editorField}>
                        <FormLabel className={classes.editorLabel}>{t(i18n.EmailBody)}</FormLabel>
                        <CKEditor
                            editor={ClassicEditor}
                            data={data.body}
                            // config={{
                            //     toolbar: [
                            //         'heading', '|',
                            //         'bold', 'italic', 'strikethrough', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
                            //         'imageUpload', '|',
                            //         'undo', 'redo'
                            //     ],
                            //     height: '500px'
                            // }}
                            onInit={editor => {
                                editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                                    return new CustomUploadAdapter(loader);
                                };

                                editor.setData(data.body);
                            }}
                            onChange={(event, editor) => {
                                const editorData = editor.getData();
                                let newData = { ...data };
                                newData.body = editor.getData();

                                setData(newData);
                            }}
                        />
                    </div>

                </Grid>
                <Grid item xs={4}>
                    <div className={classes.legendContiner}>
                        <div>
                            <Typography variant="h6" id="legendTitle">
                                {t(i18n.EmailTemplateLegendTitle)}
                            </Typography>
                        </div>
                        <div className={classes.legendLabelContiner}>
                            <Tooltip title={t(i18n.UserNameTooltip)}>
                                <label className={classes.legendLabel}>
                                    [USER_NAME]
                                </label>
                            </Tooltip>
                            <Tooltip title={t(i18n.UserEmailTooltip)}>
                                <label className={classes.legendLabel}>
                                     [USER_EMAIL]
                                </label>
                            </Tooltip>
                            <Tooltip title={t(i18n.AuditLinkTooltip)}>
                                <label className={classes.legendLabel}>
                                    [AUDIT_LINK]
                                </label>
                            </Tooltip>
                            <Tooltip title={t(i18n.ChapterNumberTooltip)}>
                                <label className={classes.legendLabel}>
                                    [KAPITEL_NUMMER]
                                </label>
                            </Tooltip>
                            <Tooltip title={t(i18n.ChapterLanguageTooltip)}>
                                <label className={classes.legendLabel}>
                                    [KAPITEL_SPRACHE]
                                </label>
                            </Tooltip>
                            <Tooltip title={t(i18n.ChapterRevisionTooltip)}>
                                <label className={classes.legendLabel}>
                                    [KAPITEL_JAHR_REVISION]
                                </label>
                            </Tooltip>
                        </div>
                    </div>


                </Grid>

            </Grid>
        </BaseDialog>
    );
};

EmailTemplateDetailsPage.propTypes = {
    t: PropTypes.any,
    open: PropTypes.bool,
    selected: PropTypes.bool,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
}

export default withTranslation('i18n')(withRouter(EmailTemplateDetailsPage));
