import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
  badge: {
    cursor: 'pointer'
  },
  expander: {
    cursor: 'pointer'
  }
}));

const CommentBadge = ({ count, className, onClick, hasReplies, ...other }) => {
  const content = count > 0 ? count : 0;
  const color = !hasReplies ? "primary" : "secondary";
  
  const classes = useStyles();

  return (
    <Badge
      {...other}
      className={[classes.badge, className]}
      badgeContent={content}
      color={color}
      onClick={onClick}>

      <CommentIcon
        className={classes.expander}
        color="primary"
      />
    </Badge>
  );
}

CommentBadge.propTypes = {
  count: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { CommentBadge };
