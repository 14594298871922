import axios from 'axios';
import { ApiEndPoints } from '../settings/ApiSettings';
import ObjectExt from '../utils/ObjectExt';
import StorageService from './StorageService';
import React from "react";
import ToastService from './ToastService';
import { withRouter } from "react-router-dom";
import AuthService from './AuthService';

export let ApiService = {
  baseUrl: "",
  axiosInstance: null,
  apiToken: "",
  get: (url, onResponse, onError) => { throw "Not initialized exception! "; },
  post: (url, data, onResponse, onError, config = null) => { throw "Not initialized exception! "; },
  postForId: (url, onResponse, onError) => { throw "Not initialized exception! "; },
  put: (url, data, onResponse, onError) => { throw "Not initialized exception! "; },
  delete: (url, onResponse, onError) => { throw "Not initialized exception! "; },
};


const ApiServiceComponent = (props) => {

  ApiService.axiosInstance = axios.create({
    baseURL: document.getElementsByTagName('base')[0].baseURI
  });

  ApiService.axiosInstance.interceptors.request.use((request) => {
    // console.log("Intercept api request here!");
    const userData = StorageService.GetUserData();
    // console.log("UserToken: ", userData.usertoken);
    // console.log("Request Url: ",  request.url);
    request.headers.Authorization = `Bearer ${userData.usertoken}`;
    return Promise.resolve(request);
  }, (error) => {
    // console.log("Intercep api request error here!");
    return Promise.reject(error);
  });

  ApiService.axiosInstance.interceptors.response.use(
    (response) => {
      // console.log("Intercept api response here!");
      return Promise.resolve(response);
    }, (error) => {
      // console.log("Intercept api response error here!");
      if (error.response.status === 401) {
        ToastService.ShowToast(`Action not authorized!`);
        AuthService.logout((response) => {
          window.location.reload(true);
        });
      }

      if (error.response.status === 404) {
        ToastService.ShowToast("Url Not Found!. Loading login page!");
        AuthService.logout((response) => {
          window.location.reload(true);
        });
      }

      if (error.response.status == 400) {
        if (error.response.data && error.response.data.message) {
          ToastService.ShowToast(error.response.data.message);
        }
      }
      if (error.response.status >= 500) {
        ToastService.ShowToast("Oops something happend!");
      }
      return Promise.reject(error);
    });

  ApiService.get = (url, onResponse, onError) => {
    ApiService.axiosInstance.get(url)
      .then((response) => {
        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;
        onResponse(response);
      }, (error) => {
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError(error);
      });
  };
  ApiService.post = (url, data, onResponse, onError, config) => {
    ApiService.axiosInstance.post(url, data, config)
      .then((response) => {
        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;
        onResponse(response);
      }, (error) => {
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError(error);
      });
  };
  ApiService.postForId = (url, onResponse, onError) => {
    ApiService.axiosInstance.post(url)
      .then((response) => {
        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;
        onResponse(response);
      }, (error) => {
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError(error);
      });
  };
  ApiService.put = (url, data, onResponse, onError) => {
    ApiService.axiosInstance.put(url, data)
      .then((response) => {
        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;
        onResponse(response);
      }, (error) => {
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError(error);
      });
  };
  ApiService.delete = (url, onResponse, onError) => {
    ApiService.axiosInstance.delete(url)
      .then((response) => {
        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;
        onResponse(response);
      }, (error) => {
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError(error);
      });
  };

  return (null);
};
export default ApiServiceComponent;
