import { ApiEndPoints } from "../../settings/ApiSettings";
import { ApiService } from '../../services/ApiService';

const EmailTemplateDataService = {
  getAllEmailTemplates: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.EMAIL_TEMPLATE, response => onResponse(response), error => onError(error));
  },
  getEmailTemplate: (id, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.EMAIL_TEMPLATE}/${id}`, response => onResponse(response), error => onError(error));
  },
  addEmailTemplate: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.EMAIL_TEMPLATE, data, response => onResponse(response), error => onError(error));
  },
  editEmailTemplate: (data, onResponse, onError) => {
    ApiService.put(`${ApiEndPoints.EMAIL_TEMPLATE}/${data.id}`, data, response => onResponse(response), error => onError(error));
  },
  deleteEmailTemplate: (ids, onResponse, onError) => {
    ApiService.post(`${ApiEndPoints.EMAIL_TEMPLATE_DELETE}`,ids, response => onResponse(response), error => onError(error));
  },
};
export default EmailTemplateDataService
