import AuthService from '../../services/AuthService';
import { ApiEndPoints } from "../../settings/ApiSettings";
import { ApiService } from '../../services/ApiService';


const CommentDataService = {
  getCommentsByPosition: (positionId, userId, audit, onResponse, onError) => {
    //debugger;
    if (!positionId || !audit) return;
    const user = AuthService.getAuthenticatedUserData();
    if (!user || !user.id ) return;
    ApiService.get(`${ApiEndPoints.COMMENT_POSITION}/${positionId}/user/${user.id}/audit/${audit.id}/revision/${audit.selectedRevisionId}`,
      response => onResponse(response),
      error => onError(error));
  },
  addComment: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.COMMENT, data, response => onResponse(response), error => onError(error));
  },
  addAttachment: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.COMMENT_ATTACHMENT, data, response => onResponse(response), error => onError(error));
  },
  editComment: (data, onResponse, onError) => {
    ApiService.put(`${ApiEndPoints.COMMENT}/${data.id}`, data, response => onResponse(response), error => onError(error));
  },
    deleteComment:(data, onResponse, onError) => {
        ApiService.post(ApiEndPoints.COMMENT_DELETE,data, response => onResponse(response), error => onError(error));
    },
  changeCommentPrivacy: (data, onResponse, onError) => {
    ApiService.post(`${ApiEndPoints.COMMENT}/privacy`, data, response => onResponse(response), error => onError(error));
  },
  addReplyAttachment: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.COMMENT_ADD_REPLY_ATTACHMENT, data, response => onResponse(response), error => onError(error));
  },
    deleteCommentAttachment:(data, onResponse, onError) => {
     ApiService.post(ApiEndPoints.COMMENT_ATTACHMENT_DELETE,data, response => onResponse(response), error => onError(error));
    },
    deleteReplyAttachment:(data, onResponse, onError) => {
     ApiService.post(ApiEndPoints.COMMENT_ATTACHMENT_REPLY_DELETE, data,response => onResponse(response), error => onError(error));
    },
  addReply: (data, onResponse, onError) => {
    ApiService.post(`${ApiEndPoints.COMMENT_ADD_REPLY}`, data, response => onResponse(response), error => onError(error));
  },
  editReply: (data, onResponse, onError) => {
    ApiService.post(`${ApiEndPoints.COMMENT_EDIT_REPLY}`, data, response => onResponse(response), error => onError(error));
  },
   deleteReply:(data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.COMMENT_DELETE_REPLY,data, response => onResponse(response), error => onError(error));
    }
};
export default CommentDataService;
