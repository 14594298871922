export const HostUrl = 'http://localhost:61275';

export const ApiEndPoints = {
    ADMINISTRATOR: 'api/user/getadministrators',
    ATTACHMENT: 'api/attachment',
    ATTACHMENT_DELETE: 'api/attachment/deleteattachments',
    AUDIT: 'api/audit',
    AUDIT_ADD_CHAPTER: 'api/audit/addchaptertoaudit',
    AUDIT_ADD_CONTRIBUTOR: 'api/audit/addcontributortoaudit',
    AUDIT_CONTRIBUTORS_AVIALABLE: 'api/audit/contributorsavialable',
    AUDIT_DELETE: 'api/audit/deleteaudits',
    AUDIT_EXPORT_COMMENTS: 'api/audit/export',
    AUDIT_ADD_REVISION:'api/audit/addrevision',
    AUDIT_EDIT_REVISION: 'api/audit/editrevision',
    AUDIT_REMOVE_CONTRIBUTOR: 'api/audit/removecontributorfromaudit',
    AUDIT_TOGGLE_CONTRIBUTOR_AUTHOR_FLAG: 'api/audit/togglecontributorauthorflag',
    AUDIT_SEND_EMAIL: 'api/audit/sendemail',
    AUDIT_SEND_TEST_EMAIL: 'api/audit/sendtestemail',
    AUDITS_COUNT:'api/audit/count',
    AUTH_LOGIN: 'api/auth/login',
    AUTH_REGISTER_ADMINISTRATOR: 'api/auth/registeradministrator',
    AUTH_REGISTER_CONTRIBUTOR: 'api/auth/registercontributor',
    AUTH_IS_CONTRIBUTOR_ALLOWED: 'api/auth/iscontributorallowed',
    AUTH_GET_CONTRIBUTOR_TOKEN: 'api/auth/contributortoken',
    COMMENT: 'api/comment',
    COMMENT_ADD_REPLY_ATTACHMENT: 'api/comment/reply/attachment',
    COMMENT_ADD_REPLY: 'api/comment/addreply',
    COMMENT_EDIT_REPLY: 'api/comment/editreply',
    COMMENT_DELETE:'api/comment/delete',
    COMMENT_DELETE_REPLY: 'api/comment/deletereply',
    COMMENT_ATTACHMENT: 'api/comment/attachment',
    COMMENT_ATTACHMENT_DELETE: 'api/comment/commentattachmentdelete',
    COMMENT_ATTACHMENT_REPLY_DELETE: 'api/comment/replyattachmentdelete',
    COMMENT_POSITION: 'api/comment/position',
    CONTRIBUTOR: 'api/user/getcontributors',
    CONTRIBUTOR_ADD_CHAPTER_RANGE: 'api/user/addcontributorchapterrange',
    CONTRIBUTOR_ADD_FROM_EXEL: 'api/user/addfromexcel',
    CONTRIBUTOR_DELETE_CHAPTER_RANGE: 'api/user/deletechapterrange',
    CONTRIBUTOR_EDIT_CHAPTER_RANGE: 'api/user/editchapterrange',
    CHAPTER: 'api/chapter',
    CHAPTER_DELETE: 'api/chapter/deletechapters',
    CHAPTER_CONTENT: 'api/chapter/content',
    CHAPTERS_COUNT: 'api/chapter/count',
    CHAPTER_ATTACHMENT: 'api/chapter/attachment',
    CHAPTER_ATTACHMENT_EDIT: 'api/attachment/editattachment',
    CHAPTER_IMAGES: 'api/chapter/images',
    CHAPTER_IMAGES_REPORT: 'api/chapter/images/report',
    EMAIL_TEMPLATE: 'api/emailtemplate',
    EMAIL_TEMPLATE_DELETE: 'api/emailtemplate/deletetemplates',
    EMAIL_TEMPLATE_IMAGE: 'api/emailtemplate/uploadtemplateimage',
    IMAGE: `${HostUrl}/images`,
    USER: 'api/user',
    USER_DELETE: 'api/user/deleteusers',
    USER_EDIT_ADMIN: 'api/user/editadmin'
}
