import 'date-fns';

import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';

import AdministratorListPage from './components/Administrator/AdministratorPage';
import AuditPage from './components/Audit/AuditPage';
import AuditReviewPage from './components/Audit/AuditViewPage';
import ChapterPage from "./components/Chapter/ChapterPage";
import ContributorPage from "./components/Contributor/ContributorPage";
import DashboardPage from './components/Dashboard/DashboardPage';
import EmailTemplatePage from "./components/EmailTemplate/EmailTemplatePage";
import LoginPage from './components/Login/LoginPage';
import AdminMasterPage from './components/MasterPage/AdminMasterPage';
import ContributorMasterPage from './components/MasterPage/ContributorMasterPage';
import AuthService from './services/AuthService';
import defaultTheme from './styles/defaultTheme';
import { i18nCheck } from './translations/i18n';
import AppStyles from './AppStyles';

const App = (props) => {

  i18nCheck();

  const classes = AppStyles();

  const { location, authData, match } = props;

  const queryString = location.search;

  if (_.includes(queryString, '?token=')) {
    const params = new URLSearchParams(queryString);
    const guid = params.get('token');
    // const auditId = params.get('audit');
    const revisionId = params.get('revision');

    const contributorToAllow = {
      contributorGuid: guid,
      revisionId
    };

    return (
      <ContributorMasterPage className={classes.appContributorLayout}>
        <AuditReviewPage className={classes.appContributorLayout} contributorToAllow={contributorToAllow} />
      </ContributorMasterPage>
    );
  }

  if (!AuthService.isAuthenticated()) {
    return (
      <LoginPage />
    );
  }

  return (

    <AdminMasterPage className={classes.appContributorLayout}>
      <Switch>
        <Route exact path="/" component={DashboardPage} />
        <Route exact path="/admins" component={AdministratorListPage} />
        <Route exact path="/contributors" component={ContributorPage} />
        <Route exact path="/emailTemplates" component={EmailTemplatePage} />
        <Route exact path="/chapters" component={ChapterPage} />
        <Route exact path="/audits" component={AuditPage} />
        <Route exact path="/audits/view" component={AuditReviewPage} />
      </Switch>
    </AdminMasterPage>

  );
}

export default withRouter(App);
