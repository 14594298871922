import _ from "lodash";

const ObjectExt = {
  IsNullOrUndefined: (object) => {
    return object === undefined || object === null;
  },
  IsString: (object) => {
    return typeof object === 'string';
  },
  IsNotStringOrEmpty: (object) => {
    return typeof object !== 'string' || object === '';
  },
  IsFunc: (object) => {
    return typeof object === 'function';
  },
  IsDefinedFunc: (object) => {
    return !ObjectExt.IsNullOrUndefined(object) && typeof object === 'function';
  },
  IsArray: (object) => {
    return Array.isArray(object);
  },
  IsNotArrayOrEmpty: (object) => {
    return ObjectExt.IsNullOrUndefined(object) || !Array.isArray(object) || object.length === 0;
  },
  IsStringEmpty: (str) => {
    return (typeof str === 'string' || str instanceof String) && str.trim() === '';
  },
  IsDateValid: (date) => {
    return date instanceof Date && !isNaN(Date.parse(date));
  },
  ContainsById: (arrayObj, id) => {
    if (ObjectExt.IsNullOrUndefined(arrayObj) || ObjectExt.IsNotArrayOrEmpty(arrayObj)) {
      return false;
    }
    const index = _.findIndex(arrayObj, obj => {
      return obj.id === id
    });
    return index !== -1;
  },
  RemoveById: (arrayObj, id) => {
    if (ObjectExt.IsNullOrUndefined(arrayObj) || ObjectExt.IsNotArrayOrEmpty(arrayObj)) {
      return arrayObj;
    }
    const newArrayObj = arrayObj.slice();
    _.remove(newArrayObj, q => q.id === id);
    return newArrayObj;
  },
  HtmlToText: (html) => {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;
  },
  SubString: (str, max) => {
    if (ObjectExt.IsNullOrUndefined(str)) return '';
    if(str.length <= max) return str;
    return str.slice(0, max);
  }

};

export default ObjectExt;
