import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ChapterDataService from "./ChapterDataService";
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import ObjectExt from "../../utils/ObjectExt";
import BaseTable from "../Commons/BaseTable";
import { ConfirmDialog, ConfirmDialogService } from '../Commons/ConfirmDialog';
import FileUpload from "../FileUpload/FileUpload";
import ChapterDetailsPage from "./ChapterDetailsPage";
import { ConfirmSaveDialog, ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";


class ChapterPage extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    history: PropTypes.object
  };

  uploadRef = null;
  initialState = {
    isLoaded: false,
    selectedRow: {
      id: 0,
      name: '',
      attachments: []
    },
    selectedRows: [],
    chapters: [],
    openToast: false,
    messageToast: '',
    openDialog: false
  };
  state = this.initialState;

  componentDidMount() {
    ChapterDataService.getAllChapters(response => {
      this.setState({
        isLoaded: true,
        chapters: response.data.result
      })
    }, error => {
      ToastService.ShowToast(error);
    });

    ToastService.OnToastClose(() => {
    });

    this.uploadRef = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { chapters, selectedRows, selectedRow, isLoaded, openDialog } = this.state;

    const headers = [
      t(i18n.ChapterNumber),
      t(i18n.Title),
      t(i18n.Language),
      t(i18n.Version),
      t(i18n.RevisionYear),
    ];

    return (
      <>
        <ChapterDetailsPage
          selected={selectedRow}
          onClose={this.onClose}
          onUpdate={this.onUpdate}
          open={openDialog}
          onDelete={this.onDeleteAttachment}
        />
        <ConfirmDialog
          title={t(i18n.ConfirmDialogTitle)}
          elements={selectedRows.length} />

        <ConfirmSaveDialog
          title={t(i18n.ConfirmDialogTitle)} />

        <div style={{ padding: '5px' }}>
          <BaseTable
            title={t(i18n.AvailableChapters)}
            subTitle={t(i18n.AvailableChaptersMore)}
            rowsData={chapters}
            renderHeaders={headers}
            selectedRows={selectedRows}
            onSelectRow={this.onSelectedRow}
            renderRow={this.renderRowData}
            onAddRow={() => {
              const fileUploadEL = this.uploadRef.current;
              if (!fileUploadEL) return;
              fileUploadEL.click();
            }}
            onEditRow={this.onEditRow}
            onDeleteRow={this.onDeleteRow}
            onSelectAllRows={this.onSelectAllRows}
            filterProps={this.filterProps}
            showLoader={!isLoaded}>
          </BaseTable>

          <Grid container spacing={20} direction="row" style={{ paddingTop: "10px" }}>
            <FileUpload
              hidden
              inputRef={this.uploadRef}
              text={t(i18n.UploadChapter)}
              allowType={'xml'}
              onUpload={file => {
                this.onUpload(file)
              }}
            />
            {/* <Button variant="outlined"
              style={{ margin: "5px" }}
              onClick={this.onDeleteButton}>
              {t(i18n.DeleteSelectedChapters)}
            </Button> */}
          </Grid>
        </div>
      </>
    )
  };

  onEditRow = (row) => {
    this.setState({
      selectedRow: row,
      openDialog: true
    });
  };

  onSelectAllRows = () => {
    if (this.state.selectedRows.length === this.state.chapters.length) {
      this.setState({ selectedRows: [] });
    } else {
      this.setState({ selectedRows: this.state.chapters });
    }
  };

  onSelectedRow = (row) => {
    const { selectedRows } = this.state;

    if (ObjectExt.ContainsById(selectedRows, row.id)) {
      const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id)
      this.setState({
        selectedRows: newSelectedRows
      })
    }
    else {
      const newSelectedRows = selectedRows.slice();
      newSelectedRows.push(row);
      this.setState({
        selectedRows: newSelectedRows
      })
    }
  };

  renderRowData = (data) => {
    return [
      data.number,
      data.title,
      data.language,
      data.version,
      data.releaseYear
    ];
  };
  filterProps = [
    'number',
    'title',
    'language',
    'version',
    'releaseYear'
  ];

  onUpload = chapterXml => {
    ConfirmSaveDialogService.open();
    ConfirmSaveDialogService.onAccept = () => {

      this.setState({ isLoaded: false });

      const data = new FormData();
      data.append('chapterXml', chapterXml);
      data.append('fileLastModified', chapterXml.lastModified);
      data.append('fileLastModifiedDate', chapterXml.lastModifiedDate);

      ChapterDataService.addChapter(data, response => {
        this.setState({
          chapters: [...this.state.chapters, response.data.result],
          isLoaded: true
        })
      }, error => {
        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
        this.setState({ isLoaded: true });
      })
    };
    ConfirmSaveDialogService.onClose = () => {

    }
  };

  onUpdate = (updatedChapter, attachmentAdded) => {
    
    const { selectedRow } = this.state;

    const updatedRow = Object.assign({}, selectedRow);

    if (ObjectExt.IsNullOrUndefined(updatedRow.attachments)) {
      updatedRow.attachments = [];
    }

    const newChapters = this.state.chapters.slice();
    const index = _.findIndex(newChapters, (chapter) => {
      return chapter.id == updatedRow.id;
    });

    if (!ObjectExt.IsNullOrUndefined(attachmentAdded)) {

      const attachmentIndex = _.findIndex(updatedRow.attachments, (attachment) => {
        return attachment.id == attachmentAdded.id;
      });

      if (attachmentIndex !== -1) {
        updatedRow.attachments[attachmentIndex] = attachmentAdded;
      }
      else {
        updatedRow.attachments = [...updatedRow.attachments, attachmentAdded];
      }
    }

    updatedRow.title = updatedChapter.title;

    newChapters[index] = updatedRow;
    this.setState({ chapters: newChapters });
    this.setState({ selectedRow: updatedRow });
  };

  onDeleteAttachment = (attachmentDeleted) => {
    const { selectedRow } = this.state;
    const updatedRow = Object.assign({}, selectedRow);
    _.forEach(attachmentDeleted, attachment => {
      _.remove(updatedRow.attachments, q => q.id === attachment.id);
    });

    const newChapters = this.state.chapters.slice();
    const index = _.findIndex(newChapters, (chapter) => {
      return chapter.id === updatedRow.id;
    });
    newChapters[index] = updatedRow;
    this.setState({ chapters: newChapters });
    this.setState({ selectedRow: updatedRow });
  };


  onDeleteRow = (tableRow) => {
    const ids = [];
    if (ObjectExt.IsNullOrUndefined(tableRow)) {
      ConfirmDialogService.open();
      this.state.selectedRows.map(row => {
        ids.push(row.id);
      });
    }

    else {
      this.setState({
        selectedRows: [tableRow]
      }, () => {
        ids.push(tableRow.id);
        ConfirmDialogService.open();
      });
    }


    ConfirmDialogService.onAccept = () => {

      ChapterDataService.deleteChapters(ids, (response) => {
        ToastService.ShowToast(response.data.message);
        const newChapters = this.state.chapters.slice();
        const chaptersDeleted = response.data.result;
        _.forEach(chaptersDeleted, chapter => {
          _.remove(newChapters, q => q.id === chapter.id);
        });

        this.setState({
          chapters: newChapters,
          selectedRows: this.initialState.selectedRows
        });

      }, error => {
        ToastService.ShowToast(this.props.t(i18n.ServerFailedToProcessCurrentRequest));
      });

    };

    ConfirmDialogService.onClose = () => {
      this.setState({
        selectedRows: this.initialState.selectedRows
      });
    }
  };

  onClose = () => {
    this.setState({ openDialog: false });
    this.setState({ openToast: false });
  };

  showToast = (message) => {
    this.setState({ openToast: true });
    this.setState({ messageToast: message });
  }
}
export default withTranslation("i18n")(
  withRouter(ChapterPage)
);



