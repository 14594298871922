import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DialogScrollType, DialogSize, DialogMaxHeight } from "../Commons/BaseDialog";
import BaseDialog from "../Commons/BaseDialog";
import BaseTable from "../Commons/BaseTable";
import ChapterDataService from "./ChapterDataService";
import FileUpload from "../FileUpload/FileUpload";
import { ConfirmDialog, ConfirmDialogService } from '../Commons/ConfirmDialog';
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import ChapterStyles from './ChapterStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { BaseTab, BaseTabPanel, BaseTabs, BaseTabsProps } from '../Commons/BaseTabs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStateWithCallback from "use-state-with-callback";
import ObjectExt from "../../utils/ObjectExt";
import ChapterAttachmentDetailsPage from "./ChapterAttachmentDetailsPage";
import _ from "lodash";
import { ConfirmSaveDialog, ConfirmSaveDialogService } from "../Commons/ConfimSaveDialog";
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PermMediaIcon from '@material-ui/icons/PermMedia';

const Tabs = {
    Details: 0,
    Attachments: 1,
    Drawings: 2
}

const ChapterDetailsPage = (props) => {

    const initialState = {
        isLoaded: false,
        attachments: [],
        tab: 0,
        drawingReport: null,
        loading: false,
        showConfirmDialog: false,
        selectedRows: [],
        selectedRow: {
            id: 0,
            name: '',
            fileName: ''
        },
        openAttachmentDetailDialog: false
    };

    const { t, selected, onClose, onUpdate, onDelete, open } = props;
    const [isLoaded, setIsLoaded] = React.useState(initialState.isLoaded);
    const [attachments, setAttachments] = useState(initialState.attachments);
    const [tab, setTab] = useState(initialState.tab);
    const [drawingReport, setDrawingReport] = useState(initialState.drawingReport);
    const [loading, setLoading] = useState(initialState.loading);
    const [selectedRows, setSelectedRows] = useState(initialState.selectedRows);
    const [selectedRow, setSelectedRow] = useState(initialState.selectedRow);
    const [openAttachmentDetailDialog, setOpenAttachmentDetailDialog] = useState(initialState.openAttachmentDetailDialog);
    const [showConfirmDialog, setShowConfirmDialog] = useStateWithCallback(initialState.showConfirmDialog, () => {
        if (showConfirmDialog) {
            ConfirmDialogService.open();
            setShowConfirmDialog(initialState.showConfirmDialog);
        }
    });
    const [chapterTitle, setChapterTitle] = useState("");

    const classes = ChapterStyles();
    const filterProps = [
        'name',
    ];

    useEffect(() => {
        setAttachments(selected.attachments);
        setIsLoaded(true);
        setChapterTitle(selected.title)
    }, [selected]);


    const onCloseAttachmentDialog = () => {
        setOpenAttachmentDetailDialog(initialState.openAttachmentDetailDialog)
    };

    const onAttachmentUpdate = attachment => {
        setOpenAttachmentDetailDialog(false);
        ChapterDataService.editAttachment(attachment, response => {
            if (!response.data.isSuccess) {
                ToastService.ShowToast(response.data.message);
                return;
            }
            onUpdate(selected, attachment);
        }, error => {
            ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
        })
    };

    const onTabChange = (event, newTab) => {

        setTab(newTab);

        if (newTab === Tabs.Drawings) {
            setLoading(true);
            const model = new FormData();
            model.append('chapterId', selected.id);
            ChapterDataService.getImagesReport(model, response => {
                setDrawingReport(response.data.result);
                setLoading(false);
            }, error => {
                ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
            })
        }
    };

    const renderRowData = (data) => {
        return [
            <a href={data.url} target="_blank">
                {data.name}
            </a>,
            ""
        ];
    };

    const onSelectedRow = (row) => {
        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
        }
    };

    const onEditRow = (row) => {
        setSelectedRow(row);
        setOpenAttachmentDetailDialog(true);
    };

    const onDeleteRow = (tableRow) => {

        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            ConfirmDialogService.open();
            selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            setSelectedRows([tableRow]);
            ids.push(tableRow.id);
            setShowConfirmDialog(true);
        }

        ConfirmDialogService.onAccept = () => {
            const chapterAttachmentsDelete = {
                chapterCode: selected.code,
                ids
            };
            ChapterDataService.deleteAttachments(chapterAttachmentsDelete, response => {
                if (!response.data.isSuccess) {
                    ToastService.ShowToast(response.data.message);
                    return;
                }
                setSelectedRows(initialState.selectedRows);
                onDelete(response.data.result);
            }, error => {
                ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
            });
        };
        ConfirmDialogService.onClose = () => {
            setSelectedRows(initialState.selectedRows);
            setShowConfirmDialog(initialState.showConfirmDialog);
        }
    };

    const onSelectAllRows = () => {
        if (selectedRows.length === attachments.length) {
            setSelectedRows(initialState.selectedRows);
        } else {
            setSelectedRows(attachments);
        }
    };

    const uploadAttachment = chapterAttachment => {
        setIsLoaded(false);
        ConfirmSaveDialogService.open();
        ConfirmSaveDialogService.onAccept = () => {
            const model = new FormData();
            model.append('file', chapterAttachment.file);
            model.append('chapterId', chapterAttachment.chapterId);

            ChapterDataService.addAttachment(model, response => {
                onUpdate(selected, response.data.result);
                setIsLoaded(true);
            }, error => {
                setIsLoaded(true);
                ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
            })
        };
        ConfirmSaveDialogService.onClose = () => {
            setIsLoaded(true);
        }
    };

    const uploadImages = chapterImages => {
        ConfirmSaveDialogService.open();
        ConfirmSaveDialogService.onAccept = () => {
            setLoading(true);
            const model = new FormData();
            model.append('file', chapterImages.file);
            model.append('chapterId', chapterImages.chapterId);

            ChapterDataService.addImages(model, response => {
                setDrawingReport(response.data.result);
                setLoading(false);
            }, error => {
                ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
            })
        };
        ConfirmSaveDialogService.onClose = () => {
        }

    };

    const headers = [
        t(i18n.Name),
        ""
    ];

    const fileUploadRef = React.createRef();
    const imagesUploadRef = React.createRef();

    const attachmentUploadClick = () => {
        const fileUploadEL = fileUploadRef.current;
        if (!fileUploadEL) return;
        fileUploadEL.click();
    };
    const drawingsUploadClick = () => {
        const fileUploadEL = imagesUploadRef.current;
        if (!fileUploadEL) return;
        fileUploadEL.click();
    };

    const onSaveClick = () => {
        
        const updatedChapter = Object.assign({}, selected);
        updatedChapter.title = chapterTitle;

        ConfirmSaveDialogService.open();
        ConfirmSaveDialogService.onAccept = () => {
            setLoading(true);
            ChapterDataService.editChapter(updatedChapter, response => {
                setLoading(false);
                onUpdate(updatedChapter);
            }, error => {
                ToastService.ShowToast(t(i18n.ServerFailedToProcessCurrentRequest));
                setLoading(false);
            })
        }
        ConfirmSaveDialogService.onClose = () => {
        }
    }
    const dialogButtons = [
        {
            hidden: tab !== Tabs.Details,
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    onClick={onSaveClick}
                    color="primary">
                    {t(i18n.Save)}
                    <SaveIcon size="small" className={classes.buttonEndIcon} />
                </Button>)
            }
        },
        {
            hidden: tab !== Tabs.Attachments,
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    onClick={attachmentUploadClick}
                    color="primary">
                    {t(i18n.UploadAttachment)}
                    <AttachFileIcon size="small" className={classes.buttonEndIcon} />
                </Button>)
            }
        },
        {
            hidden: tab !== Tabs.Drawings,
            render: () => {
                return (<Button variant="contained"
                    className={classes.button}
                    onClick={drawingsUploadClick}
                    color="primary">
                    {t(i18n.UploadDrawings)}
                    <PermMediaIcon size="small" className={classes.buttonEndIcon} />
                </Button>)
            }
        }
    ];

    const extraButtons = [
        {
            title: "",
            render: () => {
                return (
                    <FileUpload
                        hidden
                        inputRef={fileUploadRef}
                        text={t(i18n.uploadAttachment)}
                        allowType='all'
                        onUpload={file => {
                            uploadAttachment({
                                file,
                                chapterId: selected.id
                            })
                        }}
                    />
                )
            }
        },
        {
            title: "",
            render: () => {
                return (
                    <FileUpload
                        hidden
                        inputRef={imagesUploadRef}
                        text={t(i18n.uploadAttachment)}
                        allowType='zip'
                        onUpload={file => {
                            uploadImages({
                                file,
                                chapterId: selected.id
                            })
                        }}
                    />
                )
            }
        }
    ];

    const renderDetailsTab = () => {

        if (loading) {
            return (
                <Grid container justify="center" alignContent="center" xs={12} style={{ minHeight: "150px" }}>
                    <CircularProgress size={50} thickness={4} variant='indeterminate' className={classes.progress} />
                </Grid>
            )
        }

        return (<>
            <Grid item container justify="flex-start" alignItems="center" xs={12}>
                <Grid item xs={4}>
                    <TextField
                        id="standard-name"
                        name="chapterTitle"
                        label={t(i18n.Title)}
                        className={classes.textField}
                        value={chapterTitle}
                        onChange={(ev) => {
                            setChapterTitle(ev.target.value);
                        }}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="standard-name"
                        name="chapterNumber"
                        disabled
                        label={t(i18n.ChapterNumber)}
                        className={classes.textField}
                        value={selected.number}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>

            </Grid>

            <Grid item container justify="flex-start" alignItems="center" xs={12}>
                <Grid item xs={4}>
                    <TextField
                        id="standard-name"
                        name="chapterLanguage"
                        label={t(i18n.Language)}
                        disabled
                        className={classes.textField}
                        value={selected.language}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="standard-name"
                        name="chapterVersion"
                        disabled
                        label={t(i18n.Version)}
                        className={classes.textField}
                        value={selected.version}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="standard-name"
                        name="chapterReleaseYear"
                        disabled
                        label={t(i18n.RevisionYear)}
                        className={classes.textField}
                        value={selected.releaseYear}
                        margin="normal"
                        InputLabelProps={{ className: classes.inputLabel }}
                    />
                </Grid>

            </Grid>

        </>)
    }
    const renderAttackmentsTab = () => {
        return (<>
            <BaseTable
                title={t(i18n.ChapterAttachments)}
                rowsData={attachments}
                renderHeaders={headers}
                selectedRows={selectedRows}
                onSelectRow={onSelectedRow}
                onSelectAllRows={onSelectAllRows}
                renderRow={renderRowData}
                onEditRow={onEditRow}
                onAddRow={() => {
                    const fileUploadEL = fileUploadRef.current;
                    if (!fileUploadEL) return;
                    fileUploadEL.click();
                }}
                onDeleteRow={onDeleteRow}
                onSelectAllRows={onSelectAllRows}
                filterProps={filterProps}
                showLoader={!isLoaded}
            />
        </>)
    }
    const renderDrawingTab = () => {

        if (loading) {
            return (
                <Grid container justify="center" alignContent="center" xs={12} style={{ minHeight: "150px" }}>
                    <CircularProgress size={50} thickness={4} variant='indeterminate' className={classes.progress} />
                </Grid>
            )
        }

        return (
            <Grid container xs={12}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{t(i18n.DrawingsOnChapter)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{
                            ObjectExt.IsNullOrUndefined(drawingReport) ? "" : drawingReport.imagesOnChapter
                        }</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{t(i18n.DrawingsToUpload)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{
                            ObjectExt.IsNullOrUndefined(drawingReport) ? "" : drawingReport.imagesToUpload
                        }</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{t(i18n.DrawingsUploaded)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom>{
                            ObjectExt.IsNullOrUndefined(drawingReport) ? "" : drawingReport.imagesUploaded
                        }</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    return (
        <>
            <ChapterAttachmentDetailsPage
                open={openAttachmentDetailDialog}
                attachment={selectedRow}
                onClose={onCloseAttachmentDialog}
                onUpdate={onAttachmentUpdate}
            />

            <BaseDialog
                size={DialogSize.md}
                maxHeight={DialogMaxHeight.md}
                onClose={onClose}
                fullWidth={true}
                open={open}
                scroll={DialogScrollType.body}
                title={t(i18n.ChapterDetail)}
                buttons={dialogButtons}
                extraButtons={extraButtons}
            >
                <BaseTabs value={tab} onChange={onTabChange} aria-label="tab chapter details">
                    <BaseTab label={t(i18n.Details)} {...BaseTabsProps(Tabs.Details)} />
                    <BaseTab label={t(i18n.ChapterAttachments)} {...BaseTabsProps(Tabs.Attachments)} />
                    <BaseTab label={t(i18n.ChapterDrawings)} {...BaseTabsProps(Tabs.Drawings)} />
                </BaseTabs>
                <BaseTabPanel value={tab} index={Tabs.Details}>
                    {
                        renderDetailsTab()
                    }
                </BaseTabPanel>
                <BaseTabPanel value={tab} index={Tabs.Attachments}>
                    {
                        renderAttackmentsTab()
                    }
                </BaseTabPanel>
                <BaseTabPanel value={tab} index={Tabs.Drawings}>
                    {
                        renderDrawingTab()
                    }
                </BaseTabPanel>

                <ConfirmDialog
                    title={t(i18n.ConfirmDialogTitle)}
                    elements={selectedRows.length} />

                <ConfirmSaveDialog
                    title={t(i18n.ConfirmDialogTitle)} />
            </BaseDialog>
        </>
    )
};

export default withTranslation("i18n")(ChapterDetailsPage);
