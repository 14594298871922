import { ApiEndPoints } from "../settings/ApiSettings";
import AuthService from '../services/AuthService';
import ApiService from '../services/ApiService';


class CustomUploadAdapter {

  constructor(loader) {
    // CKEditor 5's FileLoader instance.
    this.loader = loader;
    // URL where to send files.
    this.url = ApiEndPoints.EMAIL_TEMPLATE_IMAGE;
    var userData = AuthService.getAuthenticatedUserData();
    this.token = userData ? userData.usertoken : "";
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = 'Couldn\'t upload the image:';

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {

      const response = xhr.response;

      if (!response || !response.isSuccess) {
        return reject(response && response.message ? response.message : genericErrorText);
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: `${response.result}`
      });
    });

    if (xhr.upload) {

      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();
    this.loader.file.then(file => {
      data.append('file', file);
      this.xhr.send(data);
    }, error => {

    })
  }
}

export default CustomUploadAdapter;
