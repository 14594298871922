import React from "react";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import i18n from "../../translations/i18n";
import ObjectExt from "../../utils/ObjectExt";
import TeastService from "../../services/ToastService";
import CommentDataService from "../Comment/CommentDataService";
import PubSubService from "../../services/PubSubService";
import { CommentBadge } from "./CommentBadge";
import { formatNpkContent } from "../../utils/NpkUtils";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  variable: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    padding: 0,
    borderBottom: 0
  },
  variableHeader: {
    flex: "0 0 auto",
    width: theme.spacing(10)
  },
  variableNumber: {
    color: "#0068a3",
    flex: "0 0 auto",
    width: theme.spacing(10)
  },
  variableText: {
    flex: "1 1 auto"
  }
}));

const CatalogVariable = ({
  t,
  section,
  variable,
  userId,
  audit,
  onBadgeClick,
}) => {
  const [commentNumber, setCommentNumber] = React.useState(null);
  const [hasReplies, setHasReplies] = React.useState(false);
  const [varPositionId, setVarPositionId] = React.useState(null);

  const classes = useStyles();

  const updateCommentsNumber = positionId => {
    CommentDataService.getCommentsByPosition(
      positionId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          TeastService.ShowToast(response.data.message);
          return;
        }
        if (response.data.result.length > 0) {
          _.forEach(response.data.result, (r) => {
            setHasReplies(!ObjectExt.IsNullOrUndefined(r.replies) && r.replies.length > 0);
          });
        }
        setCommentNumber(response.data.result.length);
      },
      error => {
        TeastService.ShowToast(error);
      }
    );
  };

  React.useEffect(() => {
    const positionId =
      variable &&
        variable.variablePositionID &&
        variable.variablePositionID !== ""
        ? variable.variablePositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    setVarPositionId(positionId);
    PubSubService.SubscribeTo(
      PubSubService.KeyCommentAddedMessage,
      (name, positionId) => {
        if (positionId !== variable.variablePositionID) return;
        updateCommentsNumber(variable.variablePositionID);
      }
    );
  }, []);

  React.useEffect(() => {
    const positionId =
      variable &&
        variable.variablePositionID &&
        variable.variablePositionID !== ""
        ? variable.variablePositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    updateCommentsNumber(positionId);
  }, [section]);

  const contextToSource = () => {
    return {
      id: varPositionId,
      typeLabel: t(i18n.CommentTypeVariable),
      label: formatNpkContent(section, "FullText"),
      itemNumber: section.number,
      variableNumber: variable.number,
      variableLabel: variable.description
    };
  };

  return (
    <TableCell className={classes.variable}>
      <div className={classes.variableHeader}>
        <CommentBadge
          hasReplies={hasReplies}
          count={commentNumber}
          onClick={() => onBadgeClick(contextToSource())}
        />
      </div>
      <div className={classes.variableNumber}>{variable.number}</div>
      <div className={classes.variableText}>{variable.description}</div>
    </TableCell>
  );
};

CatalogVariable.propTypes = {
  t: PropTypes.object,
  section: PropTypes.object,
  variable: PropTypes.object,
  audit: PropTypes.object,
  userId: PropTypes.string,
  onBadgeClick: PropTypes.func,
};

export default withTranslation("i18n")(CatalogVariable);
