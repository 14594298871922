import {makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import i18n from "../../translations/i18n";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import BaseTable from "../Commons/BaseTable";
import ObjectExt from "../../utils/ObjectExt";
import ContributorDataService from "./ContributorDataService";
import ContributorChapterRangeDetails from "./ContributorChapterRangeDetails";
import PubSubService from "../../services/PubSubService";
import {ConfirmDialog, ConfirmDialogService} from "../Commons/ConfirmDialog";
import ToastService from "../../services/ToastService";
import useStateWithCallback from 'use-state-with-callback';
import {ConfirmSaveDialog, ConfirmSaveDialogService} from "../Commons/ConfimSaveDialog";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        border: '0px'
    },
    inputLabel: {
        zIndex: 1,
    },
}));

const ContributorChapterRange = (props) => {

    const initialState = {
        selectedRows: [],
        selectedRow: {
            id: 0,
            from: '',
            to: ''
        },
        openDialog: false,
        showConfirmDialog: false
    };

    const {t, contributorId,onAddRange,onRangesUpdate,onDeleteRange} = props;
    const [ranges, setRanges] = useState(props.chapterRanges);
    const [selectedRows, setSelectedRows] = useState(initialState.selectedRows);
    const [openDialog, setOpenDialog] = useState(initialState.openDialog);
    const [selectedRow, setSelectedRow] = useState(initialState.selectedRow);

    //This was needed because y need show confirm dialog right after selectedRows
    // state was updated when delete through table row
    const [showConfirmDialog, setShowConfirmDialog] = useStateWithCallback(initialState.showConfirmDialog, () => {
        if (showConfirmDialog) {
            ConfirmDialogService.open();
            setShowConfirmDialog(initialState.showConfirmDialog);
        }
    });

    useEffect(() => {
        setRanges(props.chapterRanges)
    }, [props.chapterRanges]);

    const classes = useStyles();

    const headers = [
        t("FromChapter"),
        t("ToChapter"),
    ];

    const renderRowData = (data) => {
        return [
            data.from,
            data.to
        ];
    };

   const filterProps = [
        'from',
        'to'
    ];

    const onSelectedRow = (row) => {

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
        }

    };

    const onSelectAllRows = () => {
        if (selectedRows.length === ranges.length) {
            setSelectedRows(initialState.selectedRows);
        } else {
            setSelectedRows(ranges);
        }
    };

    const onEditRow = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    };

    const onDialogClose = () => {
        setOpenDialog(false);
    };

    const onAddRow = () => {
        setSelectedRow(initialState.selectedRow);
        setOpenDialog(true);
    };

    const onDetailsSave = (row) => {

        if (row.id == 0) {
                const contributorChapterRange = {
                    ApplicationUserId: contributorId,
                    from: row.from,
                    to: row.to,
                };
            onAddRange(contributorChapterRange);
            setOpenDialog(false);
        } else {
               onRangesUpdate(row);
                setOpenDialog(false);
        }
        setSelectedRows(initialState.selectedRows);
    };


    const onDeleteRow = (tableRow) => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            setSelectedRows([tableRow]);
            ids.push(tableRow.id);
        }
        onDeleteRange(ids);
    };
    return (
        <>
            <ContributorChapterRangeDetails
                onClose={onDialogClose}
                selected={selectedRow}
                open={openDialog}
                onUpdate={onDetailsSave}
            />

            <BaseTable
                rowsData={ranges}
                renderHeaders={headers}
                selectedRows={selectedRows}
                onAddRow={onAddRow}
                onSelectRow={onSelectedRow}
                renderRow={renderRowData}
                onEditRow={onEditRow}
                onDeleteRow={onDeleteRow}
                onSelectAllRows={onSelectAllRows}
                filterProps={filterProps}
            />
        </>
    );
};
export default withTranslation("i18n")(
    withRouter(ContributorChapterRange)
);
