import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ObjectExt from "../../utils/ObjectExt";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
}));

const BaseLoader = (props) => {
    const classes = useStyles();
    const {show} = {props};


    return (
        ObjectExt.IsNullOrUndefined(show) || !show ? null:
        <div>
            <CircularProgress className={classes.progress} />
        </div>
    );
};

export default BaseLoader;
