import  StorageService from './StorageService';
import moment from "moment";

const Italian = "it";
const Deutch = "de";

const i18nService = {
    setItalianLanguage: (i18n) => {
        i18n.changeLanguage(Italian);
        moment.locale(Italian);
        StorageService.SetLanguage(Italian)
      },
      setDeutchLanguage: (i18n) => {
        i18n.changeLanguage(Deutch);
        moment.locale(Deutch);
        StorageService.SetLanguage(Deutch)
      }
}
export default i18nService;