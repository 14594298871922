import Paper from "@material-ui/core/Paper";
import React from "react";
import { withTranslation } from "react-i18next";
import ChapterDataService from "../Chapter/ChapterDataService";
import ToastService from "../../services/ToastService";
import ChapterAttachmentChip from "./ChapterAttachmentChip";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import i18n from "../../translations/i18n";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    backgroundColor: "transparent"
  },
  chip: {
    margin: theme.spacing(1)
  },
  badge: {
    cursor: "pointer"
  },
  label: {
    marginRight: "5px",
    fontWeight: "bold"
  }
}));

const ChapterAttachmentViewer = props => {
  const classes = useStyles();

  const { onBadgeClick, userId, audit, t } = props;

  const [
    chapterAttachmentchipData,
    setChapterAttachmentchipData
  ] = React.useState([]);

  React.useEffect(() => {
    ChapterDataService.getChapter(
      props.chapterId,
      response => {
        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.data.message);
          return;
        }
        setChapterAttachmentchipData(response.data.result.attachments);
      },
      error => {
        ToastService.ShowToast(error);
      }
    );
  }, [props.chapterId]);

  const contextToSource = attachment => {
    return {
      id: attachment.id,
      typeLabel: t(i18n.CommentTypeAttachment),
      attachmentLabel: attachment.name,
      attachmentUrl: attachment.url
    };
  };

  return (
    <Paper elevation={0} className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography className={classes.label} variant="subtitle1">
          {`${t(i18n.ChapterAttachments)}: `}
        </Typography>
        {chapterAttachmentchipData.map(data => {
          return (
            <Grid item>
              <ChapterAttachmentChip
                userId={userId}
                audit={audit}
                chip={data}
                onChipClick={() => onBadgeClick(contextToSource(data))}
               />
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default withTranslation("i18n")(ChapterAttachmentViewer);
