import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ObjectExt from '../../utils/ObjectExt';
import _ from "lodash";
import BasePaginationActions from "./BasePaginationActions";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { withTranslation } from "react-i18next";
import i18n from "../../translations/i18n";
import BaseIncludeToolbar from "./BaseIncludeToolbar";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    iconButton: {
        cursor: 'pointer'
    },
    addButton: {
        cursor: 'pointer',
        marginLeft: "5px"
    },
}));

export const Actions = {
    hideAddRow: false,
    hideAddToolbar: false,
    hideRemoveRow: false,
    hideRemoveToolbar: false,
    showContributorFilter:false
};

const BaseIncludeTable = (props) => {

    const classes = useStyles();
    const {
        rowsData, renderHeaders, renderRow, selectedRows, onSelectRow, onSelectAllRows,contributorFilter,t,
        onRemoveRow, filterProps, onAddRow, onAddToolbar, isNotMultiSelect,onContributorFilterChange, actions
    } = props;

    const [rowsDataFiltered, setRowsDataFiltered] = useState(rowsData);
    const [page, setPage] = useState(0);
    const [temporalPage, setTemporalPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    React.useEffect(() => {
        setRowsDataFiltered(rowsData)
    }, [rowsData]);

    React.useEffect(() => {
    }, [selectedRows]);


    const rowCount = ObjectExt.IsNullOrUndefined(rowsDataFiltered) || ObjectExt.IsNotArrayOrEmpty(rowsDataFiltered) ? 0 : rowsDataFiltered.length;
    const numSelected = ObjectExt.IsNullOrUndefined(selectedRows) || ObjectExt.IsNotArrayOrEmpty(selectedRows) ? 0 : selectedRows.length;

    //if (!rowsData || rowsData.length === 0 || !renderHeaders) return null;

    const isSelected = (id) => {
        const index = _.findIndex(selectedRows, row => {
            return row.id === id
        });
        return index !== -1
    };

    const handleClick = (ev, row) => {
        onSelectRow(row)
    };

    const onSearch = (searchQuery, objProp) => {
        if (searchQuery === '') {
            setRowsDataFiltered(rowsData);
            return
        }
        const filteredData = rowsData.filter(rowData => rowData[objProp.value].toLowerCase().includes(searchQuery));
        setRowsDataFiltered(filteredData);
        setTemporalPage(page);
        setPage(0);
    };

    const onCleanSearch = ()=>{
        setPage(temporalPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  const labelDisplayedRows =({ from, to, count })=>{
    return `${from}-${to} ${t(i18n.Of)} ${count}`
  };

    const renderHead = () => {
        if (!renderHeaders) return null;
        return (
            <TableHead>
                <TableRow>
                    {
                        isNotMultiSelect ? null : <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={numSelected === rowCount && rowCount > 0}
                                onChange={() => {
                                    if (ObjectExt.IsNullOrUndefined(onSelectAllRows) || !ObjectExt.IsFunc(onSelectAllRows))
                                        return;
                                    onSelectAllRows();
                                }}
                                inputProps={{ 'aria-label': 'select all rows' }}
                            />
                        </TableCell>
                    }

                    {
                        renderHeaders.map(header => {
                            return (
                                <TableCell>{header}</TableCell>
                            )
                        })
                    }
                    {
                        renderHeaderActions()
                    }

                </TableRow>
            </TableHead>
        );
    };
    const renderHeaderActions = () => {

        const { actions, t } = props;

        const hideAdd = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideAddRow) ? false : actions.hideAddRow;
        const hideRemove = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideRemoveRow) ? false : actions.hideRemoveRow;

        if (hideAdd && hideRemove) {
            return null;
        }
        return (
            <>
                <TableCell>{t(i18n.Actions)}</TableCell>
            </>
        );
    };
    const renderBodyActions = (rowData) => {

        const { actions } = props;

        const hideAdd = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideAddRow) ? false : actions.hideAddRow;
        const hideRemove = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideRemoveRow) ? false : actions.hideRemoveRow;

        if (hideAdd && hideRemove) return null;

        return (
            <>
                <TableCell>
                    {
                        hideAdd ? null : <IconButton aria-label="edit" className={classes.iconButton}
                            onClick={() => {
                                if (ObjectExt.IsNullOrUndefined(onAddRow) || !ObjectExt.IsFunc(onAddRow))
                                    return;
                                onAddRow(rowData);
                            }}>
                            <AddIcon fontSize="default" />
                        </IconButton>
                    }
                    {
                        hideRemove ? null : <IconButton aria-label="delete" className={classes.iconButton}
                            onClick={() => {
                                if (ObjectExt.IsNullOrUndefined(onRemoveRow) || !ObjectExt.IsFunc(onRemoveRow))
                                    return;
                                onRemoveRow(rowData);
                            }}>
                            <DeleteIcon fontSize="default" />
                        </IconButton>
                    }

                </TableCell>
            </>
        );
    };
    const renderBody = () => {
        return (
            <TableBody>
                {rowsDataFiltered ? rowsDataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(rowData => {
                        const isItemSelected = isSelected(rowData.id);
                        return (
                            <TableRow
                                key={rowData.id}
                                hover
                                aria-checked={isItemSelected}
                                selected={isItemSelected}>
                                {isNotMultiSelect ? null : <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={isSelected(rowData.id)}
                                        onChange={() => {
                                            if (ObjectExt.IsNullOrUndefined(onSelectRow) || !ObjectExt.IsFunc(onSelectRow))
                                                return;
                                            onSelectRow(rowData);
                                        }}
                                    />
                                </TableCell>
                                }
                                {
                                    renderRow(rowData).map(rowCell => {
                                        return (
                                            <TableCell>{rowCell}</TableCell>
                                        )
                                    })
                                }
                                {
                                    renderBodyActions(rowData)
                                }
                            </TableRow>
                        )
                    }) : null
                }
            </TableBody>
        );
    };

    const renderFooter = () => {
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={ObjectExt.IsNotArrayOrEmpty(rowsDataFiltered) ? 0 : rowsDataFiltered.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={t(i18n.RowsPerPage)}
                        labelDisplayedRows={labelDisplayedRows}
                        SelectProps={{
                          inputProps: { 'aria-label': t(i18n.RowsPerPage)},
                          native: false,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={BasePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        )
    };

    return (
        <Paper elevation={0} className={classes.root}>
            <BaseIncludeToolbar
                numSelected={selectedRows.length}
                data={rowsData ? rowsData[0] : null}
                onSearch={onSearch}
                title={props.title}
                subTitle={props.subTitle}
                onCleanSearch={onCleanSearch}
                contributorFilter={contributorFilter}
                filterProps={filterProps}
                onAddToolbar={() => {
                    if (ObjectExt.IsNullOrUndefined(onAddToolbar) || !ObjectExt.IsFunc(onAddToolbar))
                        return;
                    onAddToolbar();
                }}
                onRemove={() => {
                    if (ObjectExt.IsNullOrUndefined(onRemoveRow) || !ObjectExt.IsFunc(onRemoveRow))
                        return;
                    onRemoveRow();
                }}
                onContributorFilterChange={()=>{
                    if (ObjectExt.IsNullOrUndefined(onContributorFilterChange) || !ObjectExt.IsFunc(onContributorFilterChange))
                        return;
                    onContributorFilterChange();
                }}
                actions={actions}>

            </BaseIncludeToolbar>
            <Table className={classes.table}>
                {
                    renderHead()
                }
                {
                    renderBody()
                }
                {
                    renderFooter()
                }
            </Table>
        </Paper>
    );
};

BaseIncludeTable.propsTypes = {
    rows: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    renderHeaders: PropTypes.func,
    onSelectAllRows: PropTypes.func,
    onSelectRow: PropTypes.func,
};
export default withTranslation("i18n")(BaseIncludeTable);
