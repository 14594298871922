import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ObjectExt from '../../utils/ObjectExt';
import AddIcon from '@material-ui/icons/Add';
import BaseTableToolbar from './BaseTableToolbar';
import _ from "lodash";
import BasePaginationActions from "./BasePaginationActions";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { withTranslation } from "react-i18next";
import i18n from "../../translations/i18n";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BaseLoader from "./BaseLoader";
import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing(3),
    // overflowX: 'auto'
    height: '100%',
    width: '100%',
    marginTop: theme.spacing
  },
  table: {
    minWidth: 650
  },
  iconButton: {
    cursor: 'pointer'
  },
  addButton: {
    cursor: 'pointer',
    marginLeft: "5px"
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: "#f00",
    },
  },
  loader: {
    position: 'fixed',
    left: '50%',
    top: '50%'
  },
  tableCell: {
    // padding: '5px'
  },
  tableCellCheckbox: {
    //width: '5%'
  },
  actionCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center'
  }
}));

export const Actions = {
  hideAdd: false,
  hideEdit: false,
  hideDelete: false,
  showSend: false,
  showExcelImport: false
};
const StyledLoader = styled(CircularProgress)`
  position: fixed;
  left: 50%;
  top:50%;
`;

const BaseTable = (props) => {

  const classes = useStyles();
  const {
    rowsData, renderHeaders, filterProps, renderRow, selectedRows, onSelectRow, onExcelImport, onSelectAllRows, t,
    onSendEmail, onEditRow, isAuditTable, onDeleteRow, onAddRow, onNavigateToChapterView, isNotMultiSelect, showLoader, actions
  } = props;

  const [rowsDataFiltered, setRowsDataFiltered] = useState(rowsData);
  const [page, setPage] = useState(0);
  const [temporalPage, setTemporalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  React.useEffect(() => {
    setRowsDataFiltered(rowsData)
  }, [rowsData]);

  React.useEffect(() => {
  }, [selectedRows]);

  const rowCount = ObjectExt.IsNullOrUndefined(rowsDataFiltered) || ObjectExt.IsNotArrayOrEmpty(rowsDataFiltered) ? 0 : rowsDataFiltered.length;
  const numSelected = ObjectExt.IsNullOrUndefined(selectedRows) || ObjectExt.IsNotArrayOrEmpty(selectedRows) ? 0 : selectedRows.length;

  const isSelected = (id) => {
    const index = _.findIndex(selectedRows, row => {
      return row.id === id
    });
    return index !== -1
  };

  const handleClick = (ev, row) => {
    onSelectRow(row)
  };

  const onSearch = (searchQuery, objProp) => {
    if (searchQuery === '') {
      setRowsDataFiltered(rowsData);
      return
    }
    const filteredData = rowsData.filter(rowData => rowData[objProp.value].toLowerCase().includes(searchQuery));
    setRowsDataFiltered(filteredData);
    setTemporalPage(page);
    setPage(0);
  };

  const onCleanSearch = () => {
    setPage(temporalPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} ${t(i18n.Of)} ${count}`
  };
  const renderHead = () => {
    if (!renderHeaders) return null;
    return (
      <TableHead>
        <TableRow>
          {
            isNotMultiSelect ? <TableCell size="small" align="left" padding="default" className={classes.tableCell} /> :
              <TableCell size="small" align="left" padding="checkbox" className={classes.tableCellCheckbox}>
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount && rowCount > 0}
                  onChange={() => {
                    if (ObjectExt.IsNullOrUndefined(onSelectAllRows) || !ObjectExt.IsFunc(onSelectAllRows))
                      return;
                    onSelectAllRows();
                  }}
                  inputProps={{ 'aria-label': 'select all rows' }}
                />
              </TableCell>
          }

          {
            renderHeaders.map(header => {
              return (
                <TableCell size="small" align="left" padding="default" className={classes.tableCell}>{header}</TableCell>
              )
            })
          }
          {
            renderHeaderActions()
          }

        </TableRow>
      </TableHead>
    );
  };
  const renderHeaderActions = () => {

    const { actions, t } = props;

    const hideAdd = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideAdd) ? false : actions.hideAdd;
    const hideDelete = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideDelete) ? false : actions.hideDelete;
    const hideEdit = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideEdit) ? false : actions.hideEdit;

    if (!hideAdd || !hideDelete || !hideEdit) {
      //{ t(i18n.Actions) }
      return (
        <>
          <TableCell size="small" align="center" padding="default" className={classes.tableCell}></TableCell>
        </>
      );
    }
    return null;
  };
  const renderBodyActions = (rowData, index) => {

    const { actions } = props;

    const hideAdd = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideAdd) ? false : actions.hideAdd;
    const hideDelete = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideDelete) ? false : actions.hideDelete;
    const hideEdit = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.hideEdit) ? false : actions.hideEdit;
    const navigateToChapterView = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.navigateToChapterView) ? false : actions.navigateToChapterView;
    const showExcelImport = ObjectExt.IsNullOrUndefined(actions) || ObjectExt.IsNullOrUndefined(actions.showExcelImport) ? false : actions.showExcelImport;

    if (hideAdd && hideDelete && hideEdit) return null;

    return (
      <>
        <TableCell size="small" align="left" padding="default" className={classes.tableCell}>
          <span>
            <div className={classes.actionCell}>
              {
                navigateToChapterView && rowData.chapter !== null ?
                  <IconButton aria-label="edit" className={classes.iconButton}
                    onClick={() => {
                      if (ObjectExt.IsNullOrUndefined(onNavigateToChapterView) || !ObjectExt.IsFunc(onNavigateToChapterView))
                        return;
                      onNavigateToChapterView(rowData);
                    }}>
                    <LibraryBooksIcon color="primary" fontSize="default" />
                  </IconButton> : null
              }
              {
                hideEdit ? null : <IconButton aria-label="edit" className={classes.iconButton}
                  onClick={() => {
                    if (ObjectExt.IsNullOrUndefined(onEditRow) || !ObjectExt.IsFunc(onEditRow))
                      return;
                    rowData.index = index;
                    onEditRow(rowData);
                  }}>
                  <EditIcon color="primary" fontSize="default" />
                </IconButton>
              }

              {
                ObjectExt.IsNullOrUndefined(isAuditTable) ?
                  hideDelete ? null : <IconButton aria-label="delete" className={classes.iconButton}
                    onClick={() => {
                      if (ObjectExt.IsNullOrUndefined(onDeleteRow) || !ObjectExt.IsFunc(onDeleteRow))
                        return;
                      onDeleteRow(rowData);
                    }}>
                    <DeleteIcon color="primary" fontSize="default" />
                  </IconButton> :
                  // CaseAuditTable
                  rowData.isDefaultRevision ? <IconButton aria-label="delete" className={classes.iconButton}
                    onClick={() => {
                      if (ObjectExt.IsNullOrUndefined(onDeleteRow) || !ObjectExt.IsFunc(onDeleteRow))
                        return;
                      onDeleteRow(rowData);
                    }}>
                    <DeleteIcon color="primary" fontSize="default" />
                  </IconButton> : null
              }
            </div>
          </span>

        </TableCell>
      </>
    );
  };
  const renderBody = () => {
    return (
      <TableBody style={{ overflow: 'auto', height: '100%' }}>
        {rowsDataFiltered ? rowsDataFiltered
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((rowData, index) => {
            const isItemSelected = isSelected(rowData.id);
            return (
              <TableRow
                key={rowData.id}
                hover
                aria-checked={isItemSelected}
                selected={isItemSelected}>
                {
                  ObjectExt.IsNullOrUndefined(isAuditTable) ?
                    <TableCell size="small" align="left" padding="checkbox" className={classes.tableCellCheckbox}>
                      <Checkbox
                        color="primary"
                        checked={isSelected(rowData.id)}
                        onChange={() => {
                          if (ObjectExt.IsNullOrUndefined(onSelectRow) || !ObjectExt.IsFunc(onSelectRow))
                            return;
                          onSelectRow(rowData);
                        }}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                      />
                    </TableCell> : rowData.isDefaultRevision ?
                      <TableCell size="small" align="left" padding="checkbox" className={classes.tableCellCheckbox}>
                        <Checkbox
                          color="primary"
                          checked={isSelected(rowData.id)}
                          onChange={() => {
                            if (ObjectExt.IsNullOrUndefined(onSelectRow) || !ObjectExt.IsFunc(onSelectRow))
                              return;
                            onSelectRow(rowData);
                          }}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell> : <TableCell />

                }

                {
                  renderRow(rowData).map(rowCell => {
                    return (
                      <TableCell size="small" align="left" padding="default" className={classes.tableCell}>{rowCell}</TableCell>
                    )
                  })
                }
                {
                  renderBodyActions(rowData, index)
                }
              </TableRow>
            )
          }) : null
        }
      </TableBody>
    );
  };

  const renderFooter = () => {
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={ObjectExt.IsArray(rowsDataFiltered) ? rowsDataFiltered.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelDisplayedRows={labelDisplayedRows}
            labelRowsPerPage={t(i18n.RowsPerPage)}
            SelectProps={{
              inputProps: { 'aria-label': t(i18n.RowsPerPage) },
              native: false,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={BasePaginationActions}
          />
        </TableRow>
      </TableFooter>
    )
  };

  return (
    ObjectExt.IsNullOrUndefined(showLoader) || !showLoader ? (
      <Paper elevation={0} className={classes.root}>
        <BaseTableToolbar
          numSelected={selectedRows.length}
          data={rowsData ? rowsData[0] : null}
          onSearch={onSearch}
          title={props.title}
          subTitle={props.subTitle}
          filterProps={filterProps}
          onCleanSearch={onCleanSearch}
          onAdd={() => {
            if (ObjectExt.IsNullOrUndefined(onAddRow) || !ObjectExt.IsFunc(onAddRow))
              return;
            onAddRow();
          }}
          onEdit={() => {
            if (ObjectExt.IsNullOrUndefined(onEditRow) || !ObjectExt.IsFunc(onEditRow))
              return;
            onEditRow();
          }}
          onDelete={() => {
            if (ObjectExt.IsNullOrUndefined(onDeleteRow) || !ObjectExt.IsFunc(onDeleteRow))
              return;
            onDeleteRow();
          }}
          onSendEmail={() => {
            if (ObjectExt.IsNullOrUndefined(onSendEmail) || !ObjectExt.IsFunc(onSendEmail))
              return;
            onSendEmail();
          }}
          onExcelImport={() => {
            if (ObjectExt.IsNullOrUndefined(onExcelImport) || !ObjectExt.IsFunc(onExcelImport))
              return;
            onExcelImport();
          }}
          actions={actions}>

        </BaseTableToolbar>
        <Table className={classes.table}>
          {
            renderHead()
          }
          {
            renderBody()
          }

          <Grid container direction="row" justify="flex-start" alignItems="center">
            {renderFooter()}
          </Grid>
        </Table>
      </Paper>
    ) : <StyledLoader>
        <BaseLoader show={showLoader} />
      </StyledLoader>


  );
};

BaseTable.propsTypes = {
  rows: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  renderHeaders: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  onSelectRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func
};
export default withTranslation("i18n")(BaseTable);
