import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import PubSubService from "../../services/PubSubService";
import TeastService from "../../services/ToastService";
import i18n from '../../translations/i18n';
import { formatNpkContent } from "../../utils/NpkUtils";
import ObjectExt from "../../utils/ObjectExt";
import CommentDataService from "../Comment/CommentDataService";
import { CommentBadge } from "./CommentBadge";
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  badge: {
    cursor: "pointer"
  },
  expander: {
    cursor: "pointer"
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  hintcontentBody: {
    flex: '1 1 auto',
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(2)
  }
}));

export const CatalogHintColumn = props => {
  const { t, section, onBadgeClick, userId, audit } = props;

  const [commentNumber, setCommentNumber] = React.useState(null);
  const [hasReplies, setHasReplies] = React.useState(false);

  const [hintPositionId, setHintPositionId] = React.useState(null);

  const classes = useStyles();

  const updateCommentsNumber = positionId => {
    CommentDataService.getCommentsByPosition(
      positionId,
      userId,
      audit,
      response => {
        if (!response.data.isSuccess) {
          TeastService.ShowToast(response.data.message);
          return;
        }
        if (response.data.result.length > 0) {
          _.forEach(response.data.result, (r) => {
            setHasReplies(!ObjectExt.IsNullOrUndefined(r.replies) && r.replies.length > 0);
          });
        }
        setCommentNumber(response.data.result.length);
      },
      error => {
        TeastService.ShowToast(error);
      }
    );
  };

  React.useEffect(() => {
    const positionId =
      section && section.hintPositionID && section.hintPositionID !== ""
        ? section.hintPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    setHintPositionId(positionId);
    PubSubService.SubscribeTo(
      PubSubService.KeyCommentAddedMessage,
      (name, positionId) => {
        if (positionId !== section.hintPositionID) return;
        updateCommentsNumber(section.hintPositionID);
      }
    );
  }, []);

  React.useEffect(() => {
    const positionId =
      section && section.hintPositionID && section.hintPositionID !== ""
        ? section.hintPositionID
        : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    updateCommentsNumber(positionId);
  }, [props.section]);


  const contextToSource = () => {
    return {
      id: hintPositionId,
      typeLabel: t(i18n.CommentTypeHint),
      label: `<i style="color:#0068a3;">${section.hint}</i>`,
      itemNumber: section.number
    };
  };

  return (
    <>
      {section.hint ? (
        <>
          <CommentBadge
            style={{ flex: "0 0 auto" }}
            hasReplies={hasReplies}
            count={commentNumber}
            onClick={() => onBadgeClick(contextToSource())}
          />
          <div
            className={classes.hintcontentBody}
            dangerouslySetInnerHTML={{ __html: `<i style="color:#0068a3;">${section.hint}</i>` }}
          />
        </>
      ) : null}
    </>
  );
};

export default withTranslation("i18n")(CatalogHintColumn);
