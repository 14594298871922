import { ApiEndPoints } from "../../settings/ApiSettings";
import { ApiService } from '../../services/ApiService';


const ChapterService = {
  getAllChapters: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.CHAPTER, response => onResponse(response), error => onError(error));
  },
  getChapterContent: (id, userId, audit, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.CHAPTER_CONTENT}/${id}/user/${userId}/audit/${audit.id}/revision/${audit.selectedRevisionId}`,
      response => onResponse(response), error => onError(error));
  },
  getChaptersCount: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.CHAPTERS_COUNT, response => onResponse(response), error => onError(error));
  },
  getChapter: (id, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.CHAPTER}/${id}`, response => onResponse(response), error => onError(error));
  },
  addChapter: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER, data, response => onResponse(response), error => onError(error));
  },
  editChapter: (data, onResponse, onError) => {
    ApiService.put(`${ApiEndPoints.CHAPTER}/${data.id}`, data, response => onResponse(response), error => onError(error));
  },
  addAttachment: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER_ATTACHMENT, data, response => onResponse(response), error => onError(error));
  },
  editAttachment: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER_ATTACHMENT_EDIT, data, response => onResponse(response), error => onError(error));
  },
  addImages: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER_IMAGES, data, response => onResponse(response), error => onError(error));
  },
  getImagesReport: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER_IMAGES_REPORT, data, response => onResponse(response), error => onError(error));
  },
  deleteChapters: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CHAPTER_DELETE, ids, response => onResponse(response), error => onError(error));
  },
  deleteAttachments: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.ATTACHMENT_DELETE, ids, response => onResponse(response), error => onError(error));
  }
}
export default ChapterService;
