import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AuthService from '../../services/AuthService';
import ToastService from '../../services/ToastService';
import i18n from '../../translations/i18n';
import ObjectExt from '../../utils/ObjectExt';
import ChapterView from '../ChapterViewer/ChapterView';
import ChapterAttachmentViewer from '../ChapterAttachmentViewer/ChapterAttachmentViewer';
import CommentView from '../Comment/CommentView';
import MQService from '../../services/MQService';
import AuditDataService from './AuditDataService';

const useStyles = makeStyles(theme => ({
  root: {
    height: '82%'
  },
  rootGrid: {
    height: '100%',
    padding: '10px'
  },
  fab: {
    margin: theme.spacing(1),
    float: 'right'
  },
  progress: {
    margin: theme.spacing(2),
  },
  labels: {
    marginRight: '5px',
    fontWeight: 'bold'
  }
}));

const AuditReviewPage = props => {

  const { t, isContributor } = props;
  const [audit, setAudit] = React.useState(null);

  const [comments, setComments] = React.useState([]);
  const [chapterId, setChapterId] = React.useState(null);
  const [selectedNode, setSelectedNode] = React.useState(null);
  const [chapterAttachments, setChapterAttachments] = React.useState([]);
  const [renderAllowed, setRenderAllowed] = React.useState(false);
  const [loggedUser, setLoggedUser] = React.useState(null);
  const [openStepperDialog, setOpenStepperDialog] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [renderComments, setRenderComments] = React.useState(false);
  const [commentSource, setCommentSource] = React.useState({});

  const classes = useStyles();

  React.useEffect(() => {

    const { contributorToAllow } = props;

    if (!contributorToAllow) {
      if (ObjectExt.IsNullOrUndefined(props.location.state)) return;
      setRenderAllowed(true);
      let currentUser = { ...AuthService.getAuthenticatedUserData() };
      currentUser.isAuthor = false;
      currentUser.isAdmin = true;
      setLoggedUser(currentUser);
      const { audit } = props.location.state;
      if (ObjectExt.IsNullOrUndefined(audit)) return;
      setAudit(audit);
      audit.chapterId = audit.chapter ? audit.chapter.id : 0;
      setChapterId(audit.chapterId);

    } else {

      if (!ObjectExt.IsNullOrUndefined(audit)) return;

      AuthService.isContributorAllowToCollaborate(
        contributorToAllow,
        response => {
          if (response.data.isSuccess) {
            const { audit, user } = response.data.result;
            setLoggedUser(user);
            setAudit(audit);
            audit.chapterId = audit.chapter ? audit.chapter.id : 0;
            setChapterId(audit.chapterId);
            setRenderAllowed(response.data.isSuccess);
          }
        },
        error => {
          const message = error.response.data && error.response.data.message ? error.response.data.message : "Diese Vernhemlassung ist derzeit nicht verfügbar";
          // ToastService.ShowToast(message);
        }
      );
    }
  }, []);

  const onSelectRevisionChange = ev => {

    const revisionId = ev.target.value;
    if (revisionId !== audit.selectedRevisionId) {

      setRenderComments(false);
      setCommentSource({});

      AuditDataService.getAuditByRevision(audit.id, revisionId, response => {

        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.message);
          return;
        }
        if (ObjectExt.IsNullOrUndefined(response.data.result)) return;
        let audit = response.data.result;
        audit.selectedRevisionId = revisionId;
        setAudit(audit);
        audit.chapterId = audit.chapter ? audit.chapter.id : 0;
        setChapterId(audit.chapterId);
      },
        error => {
          ToastService.ShowToast("Diese Vernhemlassung ist derzeit nicht verfügbar.");
        })
    }
  };

  if (!renderAllowed) return <>Diese Revision ist derzeit nicht verfügbar!</>;

  const chapterViewParentRef = React.createRef();
  const headerContainer = React.createRef();

  return (
    <Paper elevation={0} className={classes.root}>

      <Grid container xs={12} className={classes.rootGrid}>

        <Grid ref={headerContainer} item container xs={12} style={{ height: 'fit-content' }}>

          <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.labels} variant="subtitle1">
              {`${t(i18n.Audit)}: `}
            </Typography>
            <Typography variant="subtitle1">
              {audit ? audit.name : ''}
            </Typography>
          </Grid>

          <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.labels} variant="subtitle1">
              {`${t(i18n.ChapterNumber)}: `}
            </Typography>
            <Typography variant="subtitle1">
              {audit && audit.chapter ? audit.chapter.number : ''}
            </Typography>
          </Grid>

          <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.labels} variant="subtitle1">
              {`${t(i18n.ChapterVersion)}: `}
            </Typography>
            <Typography variant="subtitle1">
              {audit && audit.chapter ? audit.chapter.version : ''}
            </Typography>
          </Grid>

          <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.labels} variant="subtitle1">
              {`${t(i18n.ChapterEdition)}: `}
            </Typography>
            <Typography variant="subtitle1">
              {audit && audit.chapter ? audit.chapter.releaseYear : ''}
            </Typography>
          </Grid>

          <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
            <Typography className={classes.labels} variant="subtitle1">
              {`${t(i18n.ChapterLanguage)}: `}
            </Typography>
            <Typography variant="subtitle1">
              {audit && audit.chapter ? audit.chapter.language : ''}
            </Typography>
          </Grid>

          {audit && audit.auditRevisions && audit.auditRevisions.length > 1 ?
            <Grid item xs={2} container direction="row" justify="flex-start" alignItems="center">
              <Typography className={classes.labels} variant="subtitle1" gutterBottom>
                {`${t(i18n.AuditViewRevisionSelectTitle)}:`}
              </Typography>
              <Select
                value={audit.selectedRevisionId}
                onChange={onSelectRevisionChange}
                name="age"
                displayEmpty
                className={classes.revisionSelect}>
                <MenuItem value="" disabled> {t(i18n.RevisionSelectPlaceholder)} </MenuItem>
                {
                  audit.auditRevisions.map((prop, index) => (
                    index === 0 ?
                      (<MenuItem key={prop.index} value={prop.id}>
                        {`${t(i18n.FirstRevision)}`}
                      </MenuItem>) :

                      (<MenuItem key={prop.index} value={prop.id}>
                        {`${t(i18n.NotFirstRevision)}`}
                        {/* TODO: SE DECIDONO DI CREARE PIU DI UNA REVISIONE  {`${t(i18n.NotFirstRevision)} ${index}`} */}
                      </MenuItem>)
                  ))
                }
              </Select>
            </Grid> : null}

          <Grid item container xs={12} style={{ height: 'fit-content' }}>

            <ChapterAttachmentViewer
              userId={loggedUser ? loggedUser.id : 0}
              audit={audit}
              chapterId={chapterId}
              onBadgeClick={source => {
                setCommentSource(source);
                setRenderComments(true);
              }}
            />
          </Grid>

        </Grid>

        <Grid container item xs={12} style={{ borderTop: '2px black solid', height: '100%' }}>
          <Grid
            container
            item
            ref={chapterViewParentRef}
            xs={renderComments ? 8 : 12}
            style={{ height: '100%', borderRight: '1px solid #e0e0e0' }}>
            {
              <ChapterView
                key={audit.selectedRevisionId}
                style={{ height: '100%' }}
                parentRef={chapterViewParentRef}
                userId={loggedUser ? loggedUser.id : 0}
                audit={audit}
                onNodeSelection={(source) => {
                  setCommentSource(source);
                  setRenderComments(true);
                }}
                chapterId={chapterId}
              />
            }
          </Grid>
          {renderComments ? (
            <Grid container item xs={4} style={{ height: '100%' }}>
              <CommentView
                style={{ height: '100%' }}
                user={loggedUser}
                source={commentSource}
                parentRef={chapterViewParentRef}
                userId={loggedUser ? loggedUser.id : 0}
                audit={audit}
                auditId={audit ? audit.id : 0}
                onHide={() => setRenderComments(false)}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Paper >
  );
};

export default withTranslation('i18n')(withRouter(AuditReviewPage));
