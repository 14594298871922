import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import ObjectExt from "../../utils/ObjectExt";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import moment from "moment";
import Switch from '@material-ui/core/Switch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FileUpload from "../FileUpload/FileUpload";
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarBorder from '@material-ui/icons/StarBorder';
import {Grid} from "@material-ui/core";
import {borderRadius} from "@material-ui/system";
import i18n from "../../translations/i18n";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CommentDataService from "./CommentDataService";
import DeleteIcon from '@material-ui/icons/Delete';
import ToastService from "../../services/ToastService";
import {ConfirmDialog, ConfirmDialogService} from "../Commons/ConfirmDialog";

const useStyles = makeStyles(theme => ({
  commentListItem: {
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    marginTop: '5px',
    backgroundColor: '#00D4E4',
    fontSize: '10px'
  },
  replyListItem: {
    border: 'solid 1px',
    borderColor: theme.palette.secondary.main,
    borderRadius: '5px',
    marginTop: '5px',
    backgroundColor: '#F5F9FF',
    fontSize: '10px'
  },
  commentContent: {
    width: '90%',
    fontSize: '10px',
    display: 'block',
    wordWrap: 'break-word',
    overflowWrap: 'break-word'
  },
  inline: {
    display: 'inline',
  },
}));

const Comment = (props) => {

  const {comment, onEdit, onReply, onTogglePrivacyFlag, onDelete, onAttachmentUpdate, t, loggedUser} = props;

  const [showFullContent, setShowFullContent] = React.useState(false);
  const [showReplies, setShowReplies] = React.useState(true);

  const [disabledPrivacy, setDisabledPrivacy] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(false);

  const isReply = ObjectExt.IsNullOrUndefined(onReply);

  const classes = useStyles();

  const user = comment.user ? comment.user : {name: 'user name', lastname: 'user lastname'};

  const hasReplies = comment && comment.replies && comment.replies.length > 0;
  const isAuthor = loggedUser && loggedUser.isAuthor ? loggedUser.isAuthor : false;
  const isAdmin = loggedUser && loggedUser.isAdmin ? loggedUser.isAdmin : false;

  const summaryContent = ObjectExt.SubString(ObjectExt.HtmlToText(comment.content), 60);
  const commentFileUploadRef = React.createRef();

  React.useEffect(() => {
    const isPublicValue = props.comment && props.comment.isPublic ? props.comment.isPublic : false;
    setIsPublic(isPublicValue);
}, [props.comment]);


  const onAttachmentDelete = (deleteAttachmentObj) => {
    ConfirmDialogService.open();

    if (isReply) {
      ConfirmDialogService.onAccept = () => {
        CommentDataService.deleteReplyAttachment(deleteAttachmentObj, response => {
          if (!response.data.isSuccess) {
            ToastService.ShowToast(response.data.message);
            return;
          }
          onAttachmentUpdate()
        }, error => {
          ToastService.ShowToast(error);
        });
      }
    } else {
      ConfirmDialogService.onAccept = () => {
        CommentDataService.deleteCommentAttachment(deleteAttachmentObj, response => {
          if (!response.data.isSuccess) {
            ToastService.ShowToast(response.data.message);
            return;
          }
          onAttachmentUpdate()
        }, error => {
          ToastService.ShowToast(error);
        });
      }
    }
    ConfirmDialogService.onClose = () =>{

    }
  };


  const renderAttachments = (comment) => {
    if (!comment || !comment.attachments || comment.attachments.length === 0) return null;
    return (
      <Grid item container direction="column" justify="center" alignItems="flex-start">
        {
          comment.attachments.map(attchment => {
            return (
              <Grid item container direction="row" justify="flex-start" alignItems="center">
                <a href={attchment.url} target="_blank">{attchment.name}</a>
                <IconButton aria-label="settings"
                            onClick={() => {
                              onAttachmentDelete({
                                id: attchment.id,
                                userId: comment.user ? comment.user.id : 0
                              })
                            }}>
                  <DeleteIcon size="small" color="primary"/>
                </IconButton>
              </Grid>
            );
          })
        }
      </Grid>
    );
  };

  const uploadAttachmentClick = () => {
    const fileUploadEL = commentFileUploadRef.current;
    if (!fileUploadEL) return;
    fileUploadEL.click();
  };

  const onUploadAttachment = (file) => {
    const model = new FormData();
    model.append('file', file);
    if (isReply) {
      model.append('replyId', comment.id);
      CommentDataService.addReplyAttachment(model, response => {
        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.data.message);
          return;
        }
        onAttachmentUpdate()
      }, error => {
        ToastService.ShowToast(error);
      });
    } else {
      model.append('commentId', comment.id);
      CommentDataService.addAttachment(model, response => {
        if (!response.data.isSuccess) {
          ToastService.ShowToast(response.data.message);
          return;
        }
        onAttachmentUpdate(response.data.result)
      }, error => {
        ToastService.ShowToast(error);
      });
    }
  };


  const RenderPrivacyCheckboxButton = (comment, isReply, isAuthor, isAdmin) => {

    if (isReply) return null;

    if ((isAdmin || isAuthor)) {

      return (<Tooltip title={isPublic ? t(i18n.CommentPublicTooltip) : t(i18n.CommentPrivateTooltip)}>
        <Checkbox
          edge="end"
          size="small"
          disabled={disabledPrivacy}
          checked={isPublic}
          onChange={() => {
            setDisabledPrivacy(true);
            onTogglePrivacyFlag(comment, () => {
              setDisabledPrivacy(false);
              setIsPublic(!isPublic);
            }, () => {
              setDisabledPrivacy(false);
            });
          }}
          value={comment}
          color="primary"
        />
      </Tooltip>)
    }

    return null;
  };
  const RenderDeleteButton = (comment, isReply, isAuthor, isAdmin) => {

    if (hasReplies) return null;

    const commentOwnerId = comment.user ? comment.user.id : 0;
    const loggedUserId = loggedUser && loggedUser.id ? parseInt(loggedUser.id) : 0;

    if (commentOwnerId === 0 || loggedUserId === 0) return null;
    if (commentOwnerId !== loggedUserId) return null;

    const deleteComment = () => {
      const deleteComment = {
        id: comment.id,
        userId: commentOwnerId
      };
      ConfirmDialogService.open();
      ConfirmDialogService.onAccept = () => {
        CommentDataService.deleteComment(deleteComment, response => {
          if (!response.data.isSuccess) {
            ToastService.ShowToast(response.data.message);
          }
          onDelete();

        }, error => {
          ToastService.ShowToast("Error trying to delete comment.")
        });
      };
      ConfirmDialogService.onClose = () => {
      }
    };
    const deleteCommentReply = () => {
      const deleteReply = {
        id: comment.id,
        userId: commentOwnerId
      };
      ConfirmDialogService.open();
      ConfirmDialogService.onAccept = () => {
        CommentDataService.deleteReply(deleteReply, response => {
          if (!response.data.isSuccess) {
            ToastService.ShowToast(response.data.message);
          }
          onDelete();
        }, error => {
          ToastService.ShowToast("Error trying to delete reply.")
        });
      };
      ConfirmDialogService.onClose = () => {
      }
    };
    return (
      <Tooltip title={t(i18n.CommentEditTooltip)}>
        <IconButton onClick={isReply ? deleteCommentReply : deleteComment}
                    edge="end"
                    aria-label="comments Delete">
          <DeleteIcon size="small" color="primary"/>
        </IconButton>
      </Tooltip>
    )

  }
  const RenderEditButton = (comment, isReply, isAuthor, isAdmin) => {

    if (!hasReplies) { //&& (isAdmin || isAuthor)
      return (
        <Tooltip title={t(i18n.CommentEditTooltip)}>
          <IconButton aria-label="settings"
                      onClick={() => {
                        onEdit(comment, isReply);
                      }}>
            <EditIcon size="small" color="primary"/>
          </IconButton>
        </Tooltip>)

    } else if (isReply && (isAdmin || isAuthor)) {

      return (<Tooltip title={t(i18n.CommentEditTooltip)}>
        <IconButton aria-label="settings"
                    onClick={() => {
                      onEdit(comment, isReply);
                    }}>
          <EditIcon size="small" color="primary"/>
        </IconButton>
      </Tooltip>)

    }
  };


  const RenderAttachmentButton = (comment, isReply, isAuthor, isAdmin) => {
    if (isAdmin || isAuthor) {
      return (!hasReplies) ?
        <Tooltip title={t(i18n.CommentAttachmentTooltip)}>
          <span>
            <FileUpload
              hidden
              inputRef={commentFileUploadRef}
              text={'attachment'}
              allowType='all'
              onUpload={(file) => {
                onUploadAttachment(file);
              }}
            />
            <IconButton onClick={uploadAttachmentClick} edge="end" aria-label="comments AttachFileIcon">
              <AttachFileIcon color="primary"/>
            </IconButton>
          </span>
        </Tooltip> : null
    } else {
      return !isReply ?
        <Tooltip title={t(i18n.CommentAttachmentTooltip)}>
          <span>
            <FileUpload
              hidden
              inputRef={commentFileUploadRef}
              text={'attachment'}
              allowType='all'
              onUpload={(file) => {
                onUploadAttachment(file);
              }}
            />
            <IconButton onClick={uploadAttachmentClick} edge="end" aria-label="comments AttachFileIcon">
              <AttachFileIcon color="primary"/>
            </IconButton>
          </span>
        </Tooltip> : null
    }
  };


  const RenderReplyButton = (comment, isReply, isAuthor, isAdmin) => {

    if (isReply || (!isAuthor && !isAdmin)) return null;

    return (
      <Tooltip title={t(i18n.CommentReplyTooltip)}>
        <IconButton aria-label="settings"
                    onClick={() => {
                      onReply(comment);
                    }}>
          <ReplyIcon size="small" color="primary"/>
        </IconButton>
      </Tooltip>
    )
  };

  const RenderContentExpander = (showFullContent) => {

    if (showFullContent) {
      return (<Tooltip title={props.t(i18n.ExpandLess)}>
        <IconButton aria-label="settings"
                    onClick={() => {
                      setShowFullContent(false);
                    }}>
          <ExpandLess size="small" color="primary"/>
        </IconButton>
      </Tooltip>)
    }

    return (
      <Tooltip title={props.t(i18n.ExpandMore)}>
        <IconButton aria-label="settings"
                    onClick={() => {
                      setShowFullContent(true);
                    }}>
          <ExpandMore size="small" color="primary"/>
        </IconButton>
      </Tooltip>)
  }

  return (
    <>
      <ConfirmDialog title={t(i18n.ConfirmDialogTitle)} elements={1}/>
      <ListItem className={isReply ? classes.replyListItem : classes.commentListItem} dense alignItems="center" button
                onClick={() => {
                }}>
        <Grid container direction="column">
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>{`${!isReply ? user.name : ""} ${moment(comment.creationData).format('LL')}`}</Grid>
            <Grid item>
              <Grid item container direction="row" justify="flex-end" alignItems="center">
                {
                  RenderPrivacyCheckboxButton(comment, isReply, isAuthor, isAdmin)
                }
                {
                  RenderEditButton(comment, isReply, isAuthor, isAdmin)
                }
                {
                  RenderAttachmentButton(comment, isReply, isAuthor, isAdmin)
                }
                {
                  RenderDeleteButton(comment, isReply, isAuthor, isAdmin)
                }
                {
                  RenderReplyButton(comment, isReply, isAuthor, isAdmin)
                }
                {
                  RenderContentExpander(showFullContent)
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item container wrap="wrap" direction="row" justify="flex-start" alignItems="center">
            {
              showFullContent ? null : <Typography className={classes.commentContent}>{summaryContent}</Typography>
            }
            <Collapse in={showFullContent} timeout="auto" unmountOnExit>
              <div className={classes.commentContent} dangerouslySetInnerHTML={{__html: comment.content}}/>
              {
                renderAttachments(comment)
              }
            </Collapse>
          </Grid>
        </Grid>
      </ListItem>
      <Collapse in={showReplies} timeout="auto" unmountOnExit>
        <List component="div" style={{marginLeft: '15px'}}>
          {
            comment.replies && comment.replies.length > 0 ?
              comment.replies.map(reply => {
                return (
                  <Comment
                    loggedUser={loggedUser}
                    t={props.t}
                    comment={reply}
                    onTogglePrivacyFlag={onTogglePrivacyFlag}
                    onEdit={onEdit}
                    onAttachmentUpdate={onAttachmentUpdate}
                    onDelete={onDelete}
                  />
                );
              }) : null
          }
        </List>
      </Collapse>
    </>
  );
}
Comment.propTypes = {
  comment: PropTypes.object,
  onEdit: PropTypes.func,
  onReply: PropTypes.func,
  onTogglePrivacyFlag: PropTypes.func,
  t: PropTypes.func
}
export default withTranslation("i18n")(Comment);
