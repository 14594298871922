import { ApiEndPoints } from '../../settings/ApiSettings';
import { ApiService } from '../../services/ApiService';

const AdministratorService = {
  getAllAdministrators: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.ADMINISTRATOR, response => onResponse(response), error => onError(error));
  },
  getAdministrator: (id, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.USER}/${id}`, response => onResponse(response), error => onError(error));
  },
  addAdministrator: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUTH_REGISTER_ADMINISTRATOR, data, response => onResponse(response), error => onError(error));
  },
  editAdministrator: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.USER_EDIT_ADMIN, data, response => onResponse(response), error => onError(error));
  },
  deleteAdminstrator: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.USER_DELETE, ids, response => onResponse(response), error => onError(error));
  }
}
export default AdministratorService;
