import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import AuditDataService from "./AuditDataService";
import ObjectExt from "../../utils/ObjectExt";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import PubSubService from "../../services/PubSubService";
import i18n from "../../translations/i18n";
import BaseIncludeTable from "../Commons/BaseIncludeTable";
import ToastService from "../../services/ToastService";

const AuditDetailsContributorsAddTable = (props) => {

    const propTypes = {
        show: PropTypes.bool,
        onClose: PropTypes.func,
        auditId: PropTypes.number
    };

    const initialState = {
        selectedRows: []
    };

    const { auditId, auditRevisionId, contributorsAvailable, chapter, open, onClose, onAdd, t } = props;
    const [contributors, setContributors] = useState(contributorsAvailable);
    const [contributorsFiltered, setContributorsFiltered] = useState(contributorsAvailable);
    const [selected, SetSelected] = useState();
    const [filter, setFilter] = useState(false);
    const [selectedRows, setSelectedRows] = useState(initialState.selectedRows);

    const actions = {
        hideRemoveRow: true,
        hideRemoveToolbar: true,
        showContributorFilter:true
    };


    useEffect(() => {
        setContributors(contributorsAvailable);
        setContributorsFiltered(contributorsAvailable);
    }, [contributorsAvailable]);


    const onSelectedRow = (row) => {

        if (ObjectExt.ContainsById(selectedRows, row.id)) {
            const newSelectedRows = ObjectExt.RemoveById(selectedRows, row.id);
            setSelectedRows(newSelectedRows);
        } else {
            const newSelectedRows = selectedRows.slice();
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
        }
    };

    const onSelectAllRows = () => {
        if (selectedRows.length === contributors.length) {
            setSelectedRows(initialState.selectedRows);
        } else {
            setSelectedRows(contributors);
        }
    };

    const onAddRow = tableRow => {
        const ids = [];
        if (ObjectExt.IsNullOrUndefined(tableRow)) {
            selectedRows.map(row => {
                ids.push(row.id);
            });
        } else {
            ids.push(tableRow.id);
        }
        const newContributors = contributors.slice();
        const addedAuditContributors = [];
        _.forEach(ids, id => {
            const index = _.findIndex(newContributors, contributor => {
                return contributor.id == id;
            });
            addedAuditContributors.push(newContributors[index]);
            _.remove(newContributors, q => q.id === id);
        });
        setContributors(newContributors);
        setContributorsFiltered(newContributors);
        onAdd(addedAuditContributors);
        setSelectedRows(initialState.selectedRows);
        onClose();
    };

    const headers = [
        'Id',
        t("Name"),
        t("Email"),
        t("Domain"),
        t("Company"),
        t("Phone"),
        t("Address"),
    ];

    const renderRowData = (data) => {
        return [
            data.id,
            `${data.name} ${data.lastname}`,
            data.email,
            data.domain,
            data.company,
            data.phone,
            data.address
        ];
    };

    const filterProps = [
        'name',
        'email',
        'domain',
        'company',
        'phone',
        'address',
    ];


    const DialogScrollType = {
        paper: "paper",
        body: "body"
    };
    const DialogSize = {
        xs: 'xs',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl'
    };

    const filterChange = () => {
        if (!filter) {
            const newContributors = contributorsFiltered.slice();
            let isValidRange = false;
            _.forEach(contributorsFiltered, contributor => {
                _.forEach(contributor.chapterRanges, range => {
                    if (range.from <= chapter.number && range.to >= chapter.number) {
                        isValidRange = true;
                    }
                });

                if (isValidRange === false) {
                    _.remove(newContributors, cont =>
                        cont.id === contributor.id);

                }
                isValidRange = false;
            });
            setContributorsFiltered(newContributors);
            setFilter(!filter);
        } else {
            setContributorsFiltered(contributors);
            setFilter(!filter);
        }
    };

    return (

        <Dialog
            fullWidth={false}
            maxWidth={DialogSize.lg}
            open={open}
            onClose={onClose}
            scroll={DialogScrollType.paper}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{t(i18n.AvailableContributors)}</DialogTitle>
            <DialogContent>
                <BaseIncludeTable
                    rowsData={contributorsFiltered}
                    renderHeaders={headers}
                    renderRow={renderRowData}
                    onAddRow={onAddRow}
                    onAddToolbar={onAddRow}
                    selectedRows={selectedRows}
                    onSelectRow={onSelectedRow}
                    onSelectAllRows={onSelectAllRows}
                    onContributorFilterChange={filterChange}
                    contributorFilter={filter}
                    filterProps={filterProps}
                    actions={actions}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {
                        t("Close")
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
};
export default withTranslation("i18n")(AuditDetailsContributorsAddTable);
