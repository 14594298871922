import PropTypes from 'prop-types';
import { TableCell } from "@material-ui/core";
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MailIcon from '@material-ui/icons/Mail';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import cx from "classnames";
import _ from "lodash";
import numeral from "numeral";
import React from "react";
import { withTranslation } from "react-i18next";

import i18n from '../../translations/i18n';
import PubSubService from '../../services/PubSubService';
import TeastService from '../../services/ToastService';
import { Comments } from "../../utils/Mock";
import ObjectExt from '../../utils/ObjectExt';
import CommentDataService from '../Comment/CommentDataService';
import { CatalogCompareIcon, CatalogInfoIcon, CatalogInfoPictureIcon, CatalogLeafIcon } from "./CatalogInteractors";
import CatalogVariable from './CatalogVariable';
import { CommentBadge } from './CommentBadge';
import CatalogHintColumn from "./CatalogHintColumn";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(2),
  },
  badge: {
    cursor: 'pointer'
  },
  expander: {
    cursor: 'pointer'
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
  variableGridItem: {
    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
    // '&:last-child': {
    //   borderBottom: 'none'
    // },

    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5)
  },
  contentCell: {
    verticalAlign: "top",
    width: "100%",
    padding: 0
  },
  contentGridItem: {
    paddingLeft: theme.spacing(1.25)
  },
  contentBody: {
    flex: '1 1 auto',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5)
  }
}));

const CatalogContentColumn = ({
  t,
  section,
  content,
  expandable,
  toggleHandler,
  onBadgeClick,
  userId,
  audit
}) => {
  const [commentNumber, setCommentNumber] = React.useState(null);
  const [hasReplies, setHasReplies] = React.useState(false);

  const classes = useStyles();

  const updateCommentsNumber = (positionId) => {
    CommentDataService.getCommentsByPosition(positionId, userId, audit, (response) => {
      if (!response.data.isSuccess) {
        TeastService.ShowToast(response.data.message);
        return;
      }
      if (response.data.result.length > 0) {
        _.forEach(response.data.result, (r) => {
          setHasReplies(!ObjectExt.IsNullOrUndefined(r.replies) && r.replies.length > 0);
        });
      }
      setCommentNumber(response.data.result.length);
    }, (error) => {
      TeastService.ShowToast(error);
    })
  }
  React.useEffect(() => {
    PubSubService.SubscribeTo(PubSubService.KeyCommentAddedMessage, (name, positionId) => {
      if (positionId !== section.positionID) return;
      updateCommentsNumber(section.positionID);
    })
  }, []);

  React.useEffect(() => {
    const positionId = section ? section.positionID : null;
    if (ObjectExt.IsNullOrUndefined(positionId)) return;
    updateCommentsNumber(positionId);
  }, [section]);

  //TODO: Important! Sanitize Html inyected content. XSS (Cross Site Scripting)
  // className={className}

  const isOpen = section.variablesModels && section.variablesModels.length > 0 && !content;

  const contextToSource = () => {
    return {
      id: section.positionID,
      typeLabel: t(i18n.CommentTypeItem),
      label: content,
      itemNumber: section.number,
      variableNumber: null,
    };
  };

  const contextToSourceHint = () => {
    return {
      id: section.hintPositionID,
      typeLabel: t(i18n.CommentTypeHint),
      label: `<i>${section.hint}</i>`,
      itemNumber: section.number
    };
  };

  return (
    <TableCell align="left" className={classes.contentCell} style={{ width: '60%' }}>
      <Grid content direction="column" justify="flex-start" alignItems="center">
        {!isOpen &&
          <>
            <Grid item className={classes.contentGridItem} style={{ display: "flex", flexDirection: "row" }}>
              <CommentBadge
                hasReplies={hasReplies}
                count={commentNumber}
                style={{ flex: "0 0 auto", marginTop: "10px", marginRight: "20px" }}
                onClick={() => onBadgeClick(contextToSource())}
              />
              <div
                className={classes.contentBody}
                dangerouslySetInnerHTML={{ __html: content }}
                onClick={expandable ? toggleHandler : null}
              />
            </Grid>
          </>
        }
        {section.hint &&
          <Grid item className={classes.contentGridItem} style={{ display: "flex", flexDirection: "row" }}>
            <CatalogHintColumn
              section={section}
              userId={userId}
              audit={audit}
              onBadgeClick={onBadgeClick}
            />
          </Grid>
        }

        {section.variablesModels && section.variablesModels.length > 0 &&
          <Grid item className={classes.contentGridItem}>
            <Grid content justify="flex-start" alignItems="center">
              {section.variablesModels.map((variable, i) => {
                return (
                  <Grid className={classes.variableGridItem} item xs={12}>
                    <CatalogVariable
                      userId={userId}
                      audit={audit}
                      section={section}
                      variable={variable}
                      onBadgeClick={onBadgeClick} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>}
      </Grid>
    </TableCell>
  );
};

CatalogContentColumn.propTypes = {
  t: PropTypes.object,
  section: PropTypes.object,
  content: PropTypes.string,
  expandable: PropTypes.bool,
  toggleHandler: PropTypes.func,
  onBadgeClick: PropTypes.func,
  userId: PropTypes.number,
  audit: PropTypes.object
};

export default withTranslation("i18n")(CatalogContentColumn);
