import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

const NavUserMenu = {
    id: "user-menu-appbar",
    render: (anchorEl, handleClose, open) => {
        return (
            <Menu
                id={NavUserMenu.id}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={NavUserMenu.onProfile}>Profile</MenuItem>
                <MenuItem onClick={NavUserMenu.onLogout}>Logout</MenuItem>
            </Menu>);
    },
    onProfile: () => {},
    onLogout: () => {}
}
export default NavUserMenu;