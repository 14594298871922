import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import i18n from '../../translations/i18n';
import BaseDialog, { DialogScrollType, DialogSize } from '../Commons/BaseDialog';
import { BaseTab, BaseTabPanel, BaseTabs, BaseTabsProps } from '../Commons/BaseTabs';
import ContributorChapterRange from './ContributorChapterRange';
import ObjectExt from "../../utils/ObjectExt";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    border: '0px',
  },
  inputLabel: {
    zIndex: 1,
  },
}));

const ContributorDetailPage = props => {
  const { t, selected, onUpdate, onClose, open } = props;
  const [data, setData] = React.useState(selected);
  const [tab, setTab] = React.useState(0);

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const classes = useStyles();

  React.useEffect(() => {
    setData(selected);
  }, [selected]);

  const dialogButtons = [
    {
      title: props.t(i18n.Save),
      onClick: () => {
        onUpdate(data);
      },
    },
  ];

  const onRangesUpdate = (rangeUpdated) => {
      const newData = Object.assign({},data);
      const newChapterRanges = newData.chapterRanges.slice();
     //Get the rangeUpdated.index
      if (!ObjectExt.IsNullOrUndefined(rangeUpdated.index)) {
          newChapterRanges[rangeUpdated.index]=rangeUpdated;
          newData.chapterRanges = newChapterRanges;
          setData(newData);
      }
  };

  const onAddChapterRange = rangueAdded =>{
      const newData = Object.assign({},data);
              if (ObjectExt.IsNullOrUndefined(newData.chapterRanges)){
                  newData.chapterRanges=[];
              }
              newData.chapterRanges = [...newData.chapterRanges,rangueAdded];
              setData(newData);
  };

  const onTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const onDeleteChapterRange = ids =>{
      const newData = Object.assign({},data);
      const newChapterRanges = newData.chapterRanges.slice();
      _.forEach(ids,id=>{
          _.remove(newChapterRanges,rangue=>rangue.id === id);
      });
      newData.chapterRanges = newChapterRanges;
      setData(newData);
  };

  const renderOverviewTab = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            name="name"
            label={t(i18n.Name)}
            className={classes.textField}
            value={data.name}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            name="lastname"
            label={t(i18n.Surname)}
            className={classes.textField}
            value={data.lastname}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            name="email"
            required
            label={t(i18n.Email)}
            className={classes.textField}
            value={data.email}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            name="domain"
            label={t(i18n.Domain)}
            className={classes.textField}
            value={data.domain}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            label={t(i18n.Company)}
            name="company"
            className={classes.textField}
            value={data.company}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            label={t(i18n.Phone)}
            name="phone"
            className={classes.textField}
            value={data.phone}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-name"
            name="address"
            label={t(i18n.Address)}
            className={classes.textField}
            value={data.address}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ className: classes.inputLabel }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderChapterRanges = () => {
    return (
      <>
        <ContributorChapterRange
          chapterRanges={data.chapterRanges}
          contributorId={data.id}
          onRangesUpdate={onRangesUpdate}
          onAddRange={onAddChapterRange}
          onDeleteRange={onDeleteChapterRange}
        />
      </>
    );
  };

  return (
    <BaseDialog
      size={DialogSize.md}
      onClose={()=>{
          setData(selected);
          onClose()
      }}
      fullWidth={false}
      open={open}
      scroll={DialogScrollType.body}
      title={t(i18n.ContributorDetail)}
      contentText={t(i18n.ContributorDetailMore)}
      buttons={dialogButtons}
    >
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <BaseTabs value={tab} onChange={onTabChange} aria-label="tab details">
            <BaseTab label={t(i18n.ContributorDetail)} {...BaseTabsProps(0)} />
            <BaseTab label={t(i18n.ContributorChapterRanges)} {...BaseTabsProps(1)} />
          </BaseTabs>
          <BaseTabPanel value={tab} index={0}>
            {renderOverviewTab()}
          </BaseTabPanel>
          <BaseTabPanel value={tab} index={1}>
            {renderChapterRanges()}
          </BaseTabPanel>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </BaseDialog>
  );
};
export default withTranslation('i18n')(withRouter(ContributorDetailPage));
