import { ApiEndPoints } from "../settings/ApiSettings";
import ObjectExt from "../utils/ObjectExt";
import StorageService from "./StorageService";
import { ApiService } from "./ApiService";
import axios from "axios";

const AuthService = {
  login: (username, password, onResponse, onError) => {
    ApiService.post(
      ApiEndPoints.AUTH_LOGIN,
      { userName: username, password: password },
      (response) => {
        //console.log("login response");
        StorageService.SetUserData(response.data.token, response.data.result);

        if (ObjectExt.IsNullOrUndefined(onResponse)) return;
        if (!ObjectExt.IsFunc(onResponse)) return;

        onResponse(response);
      },
      (error) => {
        //console.log("login error");
        if (ObjectExt.IsNullOrUndefined(onError)) return;
        if (!ObjectExt.IsFunc(onError)) return;
        onError();
      }
    );
  },
  logout: (onResponse, onError) => {
    StorageService.RemoveUserData();
    window.location.reload(true);
    if (ObjectExt.IsNullOrUndefined(onResponse)) return;
    if (!ObjectExt.IsFunc(onResponse)) return;
    //TODO: Call backend
    onResponse(null);
  },
  isAuthenticated: () => {
    const authData = StorageService.GetUserData();
    return (
      !ObjectExt.IsNullOrUndefined(authData.usertoken) &&
      !ObjectExt.IsNullOrUndefined(authData.username) &&
      authData.username !== "undefined"
    );
    // ApiService.post(`/api/auth/validate/`, { token: authData.usertoken }, (response) => {
    //   if (ObjectExt.IsNullOrUndefined(response)) return false;
    //   if (ObjectExt.IsNullOrUndefined(response.data)) return false;
    //   return response.data.isSuccess;
    // }, (error) => {
    //   console.log(error);
    //   return false;
    // });
  },
  isContributorAllowToCollaborate: (data, onResponse, onError) => {
    axios.post(ApiEndPoints.AUTH_IS_CONTRIBUTOR_ALLOWED, data).then(
      (response) => {
        StorageService.SetUserData(
          response.data.result.userToken,
          response.data.result.user
        );
        onResponse(response);
      },
      (error) => onError(error)
    );
  },

  getContributorToken: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUTH_GET_CONTRIBUTOR_TOKEN, data).then(
      (response) => {
        onResponse(response);
      },
      (error) => onError(error)
    );
  },
  getAuthenticatedUserData: () => {
    return StorageService.GetUserData();
  },
};
export default AuthService;
