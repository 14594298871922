import moment from "moment";
import { Position, CommentStatus, CommentType, AuditEventType } from "./Enums";

export const ChapterList = [
  {
    chapter: "Cap. 102",
    title: "Besondere Bestimmungen",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2015"
  },
  {
    chapter: "Cap. 103",
    title: "Kostengrundlagen",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2015"
  },
  {
    chapter: "Cap. 211",
    title: "Baugruben und Erdbau",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2019"
  },
  {
    chapter: "Cap. 314",
    title: "Maurerarbeiten",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2013"
  },
  {
    chapter: "Cap. 426",
    title: "Sanit�ranlagen: Versorgungsleitungen",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "1994"
  },
  {
    chapter: "Cap. 554",
    title: "Ueberwachung, Alarmierung und dgl. (T)",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2006"
  },
  {
    chapter: "Cap. 663",
    title: "Bel�ge aus Linoleum, Kunststoffen, Textilien und dgl.",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2005"
  },
  {
    chapter: "Cap. 771",
    title: "Vorh�nge und Innendekorationsarbeiten",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "1989"
  }
];

export const SelectedChapters = [
  {
    chapter: "Cap. 554",
    title: "Ueberwachung, Alarmierung und dgl. (T)",
    Version: "2019",
    Language: "Tedesco",
    EditionYear: "2006"
  }
];

export const SelectableChapters = [
  {
    chapter: 103,
    title: "Kostengrundlagen",
    language: "de",
    revisionYear: "2020"
  },
  {
    chapter: 211,
    title: "Baugruben und Erdbau",
    language: "de",
    revisionYear: "2020"
  },
  {
    chapter: 314,
    title: "Maurerarbeiten",
    language: "de",
    revisionYear: "2020"
  },
  {
    chapter: 426,
    title: "Sanit�ranlagen: Versorgungsleitungen",
    language: "de",
    revisionYear: "2020"
  },
  {
    chapter: 663,
    title: "Bel�ge aus Linoleum, Kunststoffen, Textilien und dgl.",
    language: "de",
    revisionYear: "2020"
  }
];

export const Collaborators = [
  {
    id: 1,
    name: "Markus",
    lastname: "Huber",
    email: "markus.huber@whatever.ch",
    company: "Mediluc Sagl",
    phone: "+41(1895)812-01-48",
    chaptersRanges: [{ from: 300, to: 399 }, { from: 600, to: 799 }],
    domain: "Bauherren allgemein"
  },
  {
    id: 2,
    name: "Martin",
    lastname: "Brunner",
    email: "martin.brunner@whatever.ch",
    phone: "+41(007)643-34-67",
    chaptersRanges: [{ from: 300, to: 399 }, { from: 600, to: 799 }],
    domain: "Bauherren Speztiefbau"
  },
  {
    id: 3,
    name: "Claudia",
    lastname: "Meyer",
    email: "claudia.meyer@whatever.ch",
    company: "Shiptec",
    phone: "+41(979)866-75-23",
    address: "Werftestrasse 5, 6005 Luzern",
    chaptersRanges: [{ from: 500, to: 599 }],
    domain: "CRB Comitato"
  },
  {
    id: 4,
    name: "Daniela",
    lastname: "Moser",
    email: "daniela.moser@whatever.ch",
    phone: "+41(354)922-93-90",
    chaptersRanges: [{ from: 300, to: 399 }, { from: 600, to: 799 }],
    domain: "Planer allgemein"
  },
  {
    id: 5,
    name: "Karin",
    lastname: "Gerber",
    email: "karin.gerber@whatever.ch",
    company: "Mediluc Sagl",
    phone: "+41(4438)030-82-57",
    chaptersRanges: [{ from: 100, to: 799 }],
    domain: "FUG 7.1"
  },
  {
    id: 6,
    name: "Urs",
    lastname: "Steiner",
    email: "urs.steiner@whatever.ch",
    company: "Lombardi SA",
    phone: "+41(1544)873-23-35",
    chaptersRanges: [{ from: 300, to: 499 }, { from: 600, to: 699 }],
    domain: "Planer Speztiefbau"
  },
  {
    id: 7,
    name: "Ruth",
    lastname: "Keller",
    email: "ruth.keller@whatever.ch",
    company: "MTFBS",
    phone: "+41(395)898-18-82",
    chaptersRanges: [{ from: 200, to: 399 }, { from: 700, to: 799 }],
    domain: "Anmeldung"
  },
  {
    id: 8,
    name: "Marco",
    lastname: "Baumann",
    email: "marco.baumann@whatever.ch",
    company: "MTFBS",
    phone: "+41(91)482-64-70",
    chaptersRanges: [{ from: 100, to: 799 }],
    domain: "Autoren"
  }
];

export const Commenters = [
  {
    name: "Markus",
    lastname: "Huber"
  },
  {
    name: "Martin",
    lastname: "Brunner"
  },
  {
    name: "Claudia",
    lastname: "Meyer"
  },
  {
    name: "Daniela",
    lastname: "Moser"
  }
];

export const CrbUsers = [
  {
    name: "Hans",
    lastname: "Keller"
  },
  {
    name: "Erika ",
    lastname: "Schneider"
  },
  {
    name: "Brigitte ",
    lastname: "B�hler"
  },
  {
    name: "Daniel ",
    lastname: "Frey"
  }
];

export const Administrators = [
  {
    id: 1,
    icon:
      "https://media.licdn.com/dms/image/C4D03AQF26nZHlEX2YQ/profile-displayphoto-shrink_200_200/0?e=1567036800&v=beta&t=VI837b29RB6CmsoHSo2ZcP7OwlTcv8mZtYkTva2VSPs",
    email: "dario.zilocchi@mtfbs.com",
    firstName: "Dario",
    lastName: "Zilocchi"
  },
  {
    id: 2,
    icon:
      "https://media.licdn.com/dms/image/C5603AQHWjvb8wPJh6w/profile-displayphoto-shrink_200_200/0?e=1567036800&v=beta&t=9FZv7xwmiWU7CS2kIbc_frVjIHoHMk8ZfnRzr1KmSds",
    email: "yariel.rodriguez@mtfbs.com",
    firstName: "Yariel",
    lastName: "Rodriguez"
  },
  {
    id: 3,
    icon:
      "https://media.licdn.com/dms/image/C4E03AQEEFTEz2MAYWg/profile-displayphoto-shrink_200_200/0?e=1564012800&v=beta&t=Tn4iUGapbQESGKvl_UCMQHbbN4EYrTokFcWoYVeCyRw",
    email: "massimo.menzio@mtfbs.com",
    firstName: "Massimo",
    lastName: "Menzio"
  },
  {
    id: 4,
    icon:
      "https://pbs.twimg.com/profile_images/767051758308827138/WEjIrvY8_400x400.jpg",
    email: "pp@crb.ch",
    firstName: "Pasquale",
    lastName: "Petillo"
  },
  {
    id: 5,
    icon:
      "http://www.crb.ch/crbOnline/data/contacts/Produktion/28/photo/28.jpg",
    email: "kg@crb.ch",
    firstName: "Kirsten",
    lastName: "Grossmann"
  }
];

export const EmailTemplates = [
  {
    id: 1,
    name: "Überprüfung der E-Mail-Erstellung",
    subject:
      "[CRB] Du wurdest eingeladen, an einer neuen Bewertung teilzunehmen.",
    html: `
      <p>
        Guten Morgen[USER_NAME],<br/>
        <br/>
        Sie wurden eingeladen, am Prozess der Überprüfung eines neuen Kapitels auf der CRB Audit-Plattform teilzunehmen.
      </p>
      <p>
        Die Informationen zum Kapitel sind:<br/>
        <br/>
        - Nummer: <strong>[KAPITEL_NUMMER]</strong><br/>
        - Sprache: <strong>[KAPITEL_SPRACHE]</strong><br/>
        - Jahresrückblick: <strong>[KAPITEL_JAHR_REVISION]</strong><br/>
        <br/>
        Um auf die Bewertung zuzugreifen, klicken Sie auf den folgenden Link: <a href="http://audit.bau4x.mtfbs.com/?token=secret">Login</a>.
      </p>
      <p>
        Seid gegrüßt,<br/>
        CRB
      </p>
    `
  }
];

export const Comments = [
  {
    id: 1,
    itemNumber: Position.ZERO,
    title: "",
    user: "Markus Huber",
    comment:
      "Sehr kollegial und respektvoll. Kennzeichen (unter anderem): Die gute Stimmung bei den Mitarbeiteranlässen (Umbaufäscht). Man ist gerne dabei (nicht nur beim Festen!).",
    date: "05/10/19",
    status: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    unread: false
  },
  {
    id: 2,
    itemNumber: Position.ZERO,
    title: "",
    user: "Markus Huber",
    comment:
      "Sehr kollegial und respektvoll. Kennzeichen (unter anderem): Die gute Stimmung bei den Mitarbeiteranlässen (Umbaufäscht). Man ist gerne dabei (nicht nur beim Festen!).",
    date: "07/10/19",
    status: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    unread: false
  },
  {
    id: 3,
    itemNumber: Position.ZERO,
    title: "",
    user: "Karin Gerber",
    comment:
      "Sehr kollegial und respektvoll. Kennzeichen (unter anderem): Die gute Stimmung bei den Mitarbeiteranlässen (Umbaufäscht). Man ist gerne dabei (nicht nur beim Festen!).",
    date: "08/10/19",
    status: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    unread: true
  },
  {
    id: 4,
    itemNumber: Position.ZERO,
    title: "",
    user: "Hans Keller",
    comment:
      "Vieklen Dank für den sehr positiven Feedback. Es scheint, dass wir vieles richtig machen. Solche Rückmedlungen motivieren aber auch dranzubleiben und (noch) weiter zu kommen.",
    date: "20/11/19",
    status: CommentStatus.UNREAD,
    type: CommentType.ANSWER,
    unread: true
  },
  {
    id: 5,
    itemNumber: Position.DUECENTO11_110,
    title: "",
    user: "Karin Gerber",
    comment:
      "Vieklen Dank für den sehr positiven Feedback. Es scheint, dass wir vieles richtig machen. Solche Rückmedlungen motivieren aber auch dranzubleiben und (noch) weiter zu kommen.",
    date: "20/11/19",
    image: "image211",
    status: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    unread: false
  },
  {
    id: 6,
    itemNumber: Position.DUECENTO11_111,
    title: "",
    user: "Markus Huber",
    comment:
      "Vieklen Dank für den sehr positiven Feedback. Es scheint, dass wir vieles richtig machen. Solche Rückmedlungen motivieren aber auch dranzubleiben und (noch) weiter zu kommen.",
    date: "20/11/19",
    status: CommentStatus.READ,
    type: CommentType.COMMENT,
    unread: false
  },
  {
    id: 7,
    itemNumber: Position.DUECENTO11_112,
    title: "",
    user: "Hans Keller",
    comment:
      "Vieklen Dank für den sehr positiven Feedback. Es scheint, dass wir vieles richtig machen. Solche Rückmedlungen motivieren aber auch dranzubleiben und (noch) weiter zu kommen.",
    date: "20/11/19",
    status: CommentStatus.UNREAD,
    type: CommentType.COMMENT,
    unread: true
  }
];

export const ChapterModelPositions = [
  {
    number: Position.ZERO,
    title: "Bedingungen"
  },
  {
    number: Position.ZERO080,
    title: "Oekologisches Bauen"
  },
  {
    number: Position.CENTO,
    title: "Mauerwerk aus künstlichen Steinen"
  },
  {
    number: Position.CENTO120,
    title: "Mauerwerk aus Leicht-Backstein MBL und MBLD"
  },
  {
    number: Position.DUECENTO,
    title: "Mehrleistungen und Nebenarbeiten zu Mauerwerk"
  },
  {
    number: Position.DUECENTO11,
    title:
      "Mehrleistungen für Mauerköpfe, Leibungen und Ecken zu Mauerwerk MB, MBD, MBL und MBLD."
  },
  {
    number: Position.TRECENTO,
    title: "Sichtmauerwerk aus künstlichen Steinen"
  },
  {
    number: Position.QUATROCENTO,
    title:
      "Mehrleistungen und Nebenarbeiten zu Sichtmauerwerk sowie vorgefertigte Bauteile"
  },
  {
    number: Position.CINQUECENTO,
    title: "Zusatzarbeiten zu Mauerwerk und Sichtmauerwerk"
  },
  {
    number: Position.SEICENTO,
    title: "Wärme- und Schalldämmschichten"
  },
  {
    number: Position.SETECENTO,
    title: "Zusatzarbeiten im Rohbau"
  },
  {
    number: Position.OTTOCENTO,
    title: "Zusatzarbeiten im Ausbau"
  },
  {
    number: Position.NOVECECENTO,
    title: "Provisorien"
  }
];

export const Audits = [
  {
    id: 1,
    name: "Kapitel 562 - 07/2019",
    status: "Preparing",
    chapter: 562,
    language: "de",
    revisionYear: 2019,
    commentsCount: null,
    contributorsCount: null
  },
  {
    id: 2,
    name: "Kapitel 431 - 06/2019",
    status: "Active",
    chapter: 431,
    language: "de",
    revisionYear: 2019,
    fromDate: "2019-06-20",
    toDate: "2019-07-20",
    commentsCount: 23,
    contributorsCount: 235
  },
  {
    id: 3,
    name: "Kapitel 677 - 05/2019",
    status: "Active",
    chapter: 677,
    language: "de",
    revisionYear: 2019,
    fromDate: "2019-06-10",
    toDate: "2019-07-10",
    commentsCount: 45,
    contributorsCount: 316
  },
  {
    id: 4,
    name: "Kapitel 233 - 05/2019",
    status: "Editing",
    chapter: 233,
    language: "de",
    revisionYear: 2019,
    commentsCount: 76,
    contributorsCount: 297
  },
  {
    id: 5,
    name: "Kapitel 113 - 04/2019",
    status: "Revisioning",
    chapter: 113,
    language: "de",
    revisionYear: 2019,
    commentsCount: 35,
    contributorsCount: 365
  },
  {
    id: 6,
    name: "Kapitel 487 - 02/2019",
    status: "Closed",
    chapter: 487,
    language: "de",
    revisionYear: 2019,
    commentsCount: 132,
    contributorsCount: 411
  }
];

export const AuditEvents = [
  {
    id: 1,
    type: AuditEventType.JOINED,
    subject: Collaborators[0],
    timestamp: moment().add(-1, "days")
  },
  {
    id: 2,
    type: AuditEventType.COMMENTED,
    subject: Collaborators[3],
    object: {
      type: "Item",
      itemNumber: 0
    },
    timestamp: moment().add(-2, "days")
  },
  {
    id: 3,
    type: AuditEventType.COMMENTED,
    subject: Collaborators[4],
    object: {
      type: "Variable",
      itemNumber: 254162,
      variableNumber: 4
    },
    timestamp: moment().add(-3, "days")
  },
  {
    id: 4,
    type: AuditEventType.JOINED,
    subject: Collaborators[4],
    object: {
      type: "Item",
      itemNumber: 0
    },
    timestamp: moment().add(-3, "days")
  }
];
