import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import NavAppBar from '../NavAppBar/NavAppBar';
import { Container } from '@material-ui/core';
import AdminMasterPageStyles from './AdminMasterPageStyles';
import MQService from '../../services/MQService';

const ContributorMasterPage = (props) => {

  const { children } = props;
  const margin = 16;
  return (
    <div style={{ height: `${MQService.contentPageHeightOnContributorMaster() - margin}px` }}>
      {children}
    </div>
  );
}
export default withRouter(props => <ContributorMasterPage {...props} />);
