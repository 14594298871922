import { ApiEndPoints } from '../../settings/ApiSettings';
import { ApiService } from '../../services/ApiService';

const ContributorDataService = {
  getAllContributors: (onResponse, onError) => {
    ApiService.get(ApiEndPoints.CONTRIBUTOR, response => onResponse(response), error => onError(error));
  },
  getContributor: (id, onResponse, onError) => {
    ApiService.get(`${ApiEndPoints.CONTRIBUTOR}/${id}`, response => onResponse(response), error => onError(error));
  },
  addContributor: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.AUTH_REGISTER_CONTRIBUTOR, data, response => onResponse(response), error => onError(error));
  },
  addContributorFromExel: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CONTRIBUTOR_ADD_FROM_EXEL, data,
        response => onResponse(response), error => onError(error),{
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  },
  editContributor: (id, data, onResponse, onError) => {
    ApiService.put(`${ApiEndPoints.USER}/${id}`, data, response => onResponse(response), error => onError(error));
  },
  deleteContributor: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.USER_DELETE,ids, response => onResponse(response), error => onError(error));
  },
  addChpterRangeToContributor: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CONTRIBUTOR_ADD_CHAPTER_RANGE, data, response => onResponse(response), error => onError(error));
  },
  editContributorChapterRange: (data, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CONTRIBUTOR_EDIT_CHAPTER_RANGE, data, response => onResponse(response), error => onError(error));
  },
  deleteContributorChapterRange: (ids, onResponse, onError) => {
    ApiService.post(ApiEndPoints.CONTRIBUTOR_DELETE_CHAPTER_RANGE,ids, response => onResponse(response), error => onError(error));
  },
};
export default ContributorDataService;
